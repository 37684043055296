import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { CoderComponent } from './coder.component';
import { PythonComponent } from './python/python.component';
import { MysqlComponent } from './mysql/mysql.component';

export const coderRoutes: Routes = [
    {
        path: "coder", component: CoderComponent, children: [
            { path: '',   redirectTo: 'python', pathMatch: 'full' },
            { path: 'python', component: PythonComponent },
            { path: 'mysql', component: MysqlComponent, canActivate: [AuthGuard] },
        ] 
    },
];
