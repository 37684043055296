import { NgModule } from "@angular/core";
import { Routes, RouterModule, CanActivate } from "@angular/router";
import { PageComponent } from "./page/page.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthGuardService as AuthGuard } from "@core/services/guards/auth-guard.service";
import { UserCheckGuardService } from "@core/services/guards/user-check.service";

import { courseRoutes } from "@modules/course/routes";
import { profileRoutes } from "@modules/profile/routes";
import { scheduleRoutes } from "@modules/schedule/routes";
import { aiEditorRoutes } from "@modules/ai-editor/routes";
import { quizRoutes } from "@modules/quiz/routes";
import { noteRoutes } from "@modules/notes/routes";
import { communityRoutes } from "@modules/community/routes";

import { batchRoutes } from "@modules/batches/routes";
import { taskRoutes } from "@modules/tasks/routes";

import { coderRoutes } from "./coder/routes";

import { NotFoundComponent } from "../../src/app/not-found/not-found.component";
import { PathResolveService } from "@core/services/path-resolve.service";

import { FeedbackComponent } from "./feedback/feedback.component";
import { ContactComponent } from "./contact/contact.component";
import { PresentationsComponent } from "./presentations/presentations.component";
import { resourceRoutes } from "@modules/resources/routes";
import { PerformanceComponent } from "./performance/performance.component";

const routes: Routes = [
  {
    path: "feedback",
    component: FeedbackComponent,
  },

  {
    path: "",
    component: PageComponent,
    canActivate: [UserCheckGuardService],
    children: [
      // {
      //   path: "", component: DashboardComponent,
      // },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      {
        path: "trainer-presentations/:slug",
        component: PresentationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "batch-performance",
        component: PerformanceComponent,
        canActivate: [AuthGuard],
      },
      ...courseRoutes,
      ...profileRoutes,
      ...scheduleRoutes,
      ...aiEditorRoutes,
      ...coderRoutes,
      ...quizRoutes,
      ...noteRoutes,
      ...communityRoutes,
      ...batchRoutes,
      ...taskRoutes,
      ...resourceRoutes,
      {
        path: "**",
        resolve: {
          path: PathResolveService,
        },
        component: NotFoundComponent,
      },
      {
        path: "contact",
        component: ContactComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RoutesModule {}
