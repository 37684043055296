import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { TaskComponent } from './task/task.component';
import { DetailsComponent } from './details/details.component';

export const taskRoutes: Routes = [
    {
        path: 'tasks', component: TaskComponent, canActivate: [AuthGuard], children: [
            { path: ':id', component: DetailsComponent }
        ]
    }
];


