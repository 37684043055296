import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../core/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environment/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common'

@Component({
  selector: 'im-preparation',
  templateUrl: './preparation.component.html',
  styleUrls: ['./preparation.component.scss']
})
export class PreparationComponent implements OnInit {

  courseSlug: string;
  course: any;
  processing: boolean = false;

  navLinks = [];

  activeTab: any;

  loginUser: any;

  assessmentsData: any;

  activeItem: any;

  activeQuestion: any;
  activeQuestionIndex = -1;
  constructor(private location: Location,private  sanitizer: DomSanitizer, private auth: AuthService, private route: ActivatedRoute, private router: Router, private http: HttpService) {
    this.route.params.subscribe(params => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      }
    })
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
  }

  back(): void {
    //this.location.back();
    this.router.navigate(['course', this.courseSlug]);
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get('na/course/' + this.courseSlug).subscribe((res) => {
      this.course = res.data;
      if (!this.course.user_course && this.loginUser.user_type == 'student') {
        this.router.navigate(['/course/' + this.courseSlug]);
      } else {
        this.http.getExternal(environment.storageUrl + 'preparations/' + this.courseSlug + '.json?t='+new Date().getTime()).subscribe((res) => {
          this.assessmentsData = res;
          this.navLinks = res.categories;
          this.getActiveData(this.navLinks[0]);
        })
      }
      //this.processing = false;
    }, (err) => {
      this.processing = false;
    })
  }

  getActiveData(link:any) {
    this.activeQuestionIndex = -1;
    this.activeTab = link;
    this.activeItem = this.assessmentsData[link.key];
    this.setActiveQuestion();
  }

  setActiveQuestion() {
    this.activeQuestionIndex++;
    this.activeQuestion = { ... this.activeItem.items[this.activeQuestionIndex] };
    this.activeQuestion.description = this.sanitizer.bypassSecurityTrustHtml(this.activeQuestion.description);
  }

  backQuestion() {
    this.activeQuestionIndex--;
    this.activeQuestion = { ... this.activeItem.items[this.activeQuestionIndex] };
    this.activeQuestion.description = this.sanitizer.bypassSecurityTrustHtml(this.activeQuestion.description);

  }

  nextQuestion() {
    this.activeQuestionIndex++;
    this.activeQuestion = { ... this.activeItem.items[this.activeQuestionIndex] };
    this.activeQuestion.description = this.sanitizer.bypassSecurityTrustHtml(this.activeQuestion.description);

  }

}
