import { Routes } from "@angular/router";
import { ProfileComponent } from "./profile.component";
import { AuthGuardService as AuthGuard } from "@core/services/guards/auth-guard.service";
import { AboutComponent } from "./about/about.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { TransactionsComponent } from "./transactions/transactions.component";
import { IntegrationsComponent } from "./integrations/integrations.component";
import { ProfilePageComponent } from "./profile-page/profile-page.component";
import { TimelineComponent } from "./profile-page/timeline/timeline.component";
import { SummaryComponent } from "./profile-page/summary/summary.component";
import { JobReadinessComponent } from "./job-readiness/job-readiness.component";

export const profileRoutes: Routes = [
  {
    path: "profile",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ProfilePageComponent,
        children: [
          { path: "", component: SummaryComponent },
          { path: "feed", component: TimelineComponent },
        ],
      },
      {
        path: "edit",
        component: ProfileComponent,
        children: [
          { path: "", component: AboutComponent },
          { path: "change-password", component: ChangePasswordComponent },
          { path: "transactions", component: TransactionsComponent },
          { path: "integrations", component: IntegrationsComponent },
          { path: "job-readiness", component: JobReadinessComponent },
        ],
      },
      { path: ":slug", component: ProfilePageComponent },
    ],
  },
];
