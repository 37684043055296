<div class="block">
  <div class="block__cover block__cover--top"></div>
  <div class="block__side block__side--a">
    <div class="block__hole"></div>
    <div class="block__hole block__hole--right"></div>
    <div class="block__hole block__hole--bottom"></div>
    <div class="block__hole block__hole--right block__hole--bottom"></div>
    <div class="qm">
      <div class="qm__top qm-top">
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-right"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="cube qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
    <div class="qm qm--shadow">
      <div class="qm__top qm-top">
        <div
          class="qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-left">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
  </div>
  <div class="block__side block__side--b">
    <div class="block__hole"></div>
    <div class="block__hole block__hole--right"></div>
    <div class="block__hole block__hole--bottom"></div>
    <div class="block__hole block__hole--right block__hole--bottom"></div>
    <div class="qm">
      <div class="qm__top qm-top">
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-right"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="cube qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
    <div class="qm qm--shadow">
      <div class="qm__top qm-top">
        <div
          class="qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-left">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
  </div>
  <div class="block__side block__side--c">
    <div class="block__hole"></div>
    <div class="block__hole block__hole--right"></div>
    <div class="block__hole block__hole--bottom"></div>
    <div class="block__hole block__hole--right block__hole--bottom"></div>
    <div class="qm">
      <div class="qm__top qm-top">
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-right"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="cube qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
    <div class="qm qm--shadow">
      <div class="qm__top qm-top">
        <div
          class="qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-left">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
  </div>
  <div class="block__side block__side--d">
    <div class="block__hole"></div>
    <div class="block__hole block__hole--right"></div>
    <div class="block__hole block__hole--bottom"></div>
    <div class="block__hole block__hole--right block__hole--bottom"></div>
    <div class="qm">
      <div class="qm__top qm-top">
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-right"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div
          class="cube qm-top__part qm-top__corner qm-top__corner--bottom-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="cube qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="cube qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
    <div class="qm qm--shadow">
      <div class="qm__top qm-top">
        <div
          class="qm-top__part qm-top__corner qm-top__corner--left qm-top__corner--top-left"
        >
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--top-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-right">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__part qm-top__corner qm-top__corner--bottom-left">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-left qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__large-top qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__top-right qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__middle-hor qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
        <div class="qm-top__center-bottom qm-top__part">
          <div class="cube__side cube__side--top"></div>
          <div class="cube__side cube__side--bottom"></div>
          <div class="cube__cover cube__cover--front"></div>
          <div class="cube__cover cube__cover--back"></div>
          <div class="cube__side cube__side--left"></div>
          <div class="cube__side cube__side--right"></div>
        </div>
      </div>
      <div class="qm__dot">
        <div class="cube__side cube__side--top"></div>
        <div class="cube__side cube__side--bottom"></div>
        <div class="cube__cover cube__cover--front"></div>
        <div class="cube__cover cube__cover--back"></div>
        <div class="cube__side cube__side--left"></div>
        <div class="cube__side cube__side--right"></div>
      </div>
    </div>
  </div>
  <div class="block__cover block__cover--bottom"></div>
  <!-- <div class="block__shadow"></div> -->
</div>
<div class="font-medium font-base mt-6 text-center">
  Please select a video to watch the <br />recording session
</div>
