import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../core/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { environment } from "@environment/environment";
import { Location } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { FormControl } from "@angular/forms";

@Component({
  selector: "im-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  ResourcesData: any;

  activeItem: any;

  navLinks = [];

  activeTab: any;

  activeCategory: any;

  activeBook: any;

  subscriptionExist: boolean = false;

  loginUser: any;

  courses: any = [];

  selectedCourse = new FormControl();

  hideCloseButton = false;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private location: Location,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    protected sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    this.checkSubscriptionExist();
    this.getUserCourses();
    const that = this;
    window.addEventListener("message", function (e) {
      if (e.data) {
        let data = JSON.parse(e.data);
        if ("event" in data && data.event == "sidebar-opened") {
          that.hideCloseButton = true;
        } else if ("event" in data && data.event == "sidebar-closed") {
          that.hideCloseButton = false;
        }
      }
      console.log(e);
    });
  }

  getUserCourses() {
    if (this.loginUser.user_type == "trainer") {
      this.http
        .get(
          "course?fields=id,name,icon,properties,short_description,slug,status,cover_image,group_course"
        )
        .subscribe((res) => {
          this.courses = res.data;
          if (this.courses.length > 0) {
            this.selectedCourse.setValue(this.courses[0].slug);
            this.getResources();
          }
        });
    } else {
      this.http.get("user/my/user_courses").subscribe((res) => {
        this.courses = res.data;
        if (this.courses.length > 0) {
          this.selectedCourse.setValue(this.courses[0].course.slug);
          this.getResources();
        } else {
          this.selectedCourse.setValue("data-analytics");
          this.getResources();
        }
      });
    }
  }

  courseChange($event: any) {
    console.log($event.value);
    this.ResourcesData = null;
    //this.selectedCourse = $event.value;
    this.getResources();
  }

  getResources() {
    this.http
      .getExternal(
        environment.storageUrl +
          "resources/" +
          this.selectedCourse.value +
          ".json?t=" +
          new Date().getTime()
      )
      .subscribe((res) => {
        this.ResourcesData = res;
        this.navLinks = res.categories;
        if (this.route.snapshot.queryParamMap.get("m")) {
          let tempFM = this.navLinks.find(
            (it) => it.key == this.route.snapshot.queryParamMap.get("m")
          );
          if (tempFM) {
            this.getActiveData(tempFM);
          } else {
            this.getActiveData(this.navLinks[0]);
          }
        } else {
          this.getActiveData(this.navLinks[0]);
        }
      });
  }

  getActiveData(link: any) {
    this.activeTab = link;
    this.activeCategory = this.ResourcesData[link.key];
    console.log(this.activeCategory);
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: this.activeTab.key },
    });
  }

  openBook(book, drawer) {
    this.activeBook = JSON.parse(JSON.stringify(book));
    this.activeBook.link = this.sanitizer.bypassSecurityTrustResourceUrl(
      book.link
    );
    drawer.open();
  }

  checkSubscriptionExist() {
    this.http.post("checkexist/user_course", {}).subscribe((res) => {
      this.subscriptionExist = res.data.exist > 0 ? true : false;
    });
  }

  downloadFile(item: any) {
    if (!this.subscriptionExist) {
      this.openLockStatus();
      return false;
    }
    let url = item.link;
    let filename = "";
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }

  openLockStatus(): void {
    const bottomSheetRef = this._bottomSheet.open(CourseLockStatusSheet, {
      panelClass: "im-sheet",
    });
  }

  exploreCourses(drawer) {
    drawer.close();
    this.router.navigate(["/courses"]);
  }
}

@Component({
  selector: "im-course-lock",
  templateUrl: "course.lock.html",
})
export class CourseLockStatusSheet {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<CourseLockStatusSheet>,
    private router: Router
  ) {}

  explore(): void {
    this._bottomSheetRef.dismiss();
    this.router.navigate(["/courses"]);
  }

  cancel() {
    this._bottomSheetRef.dismiss();
  }
}
