<mat-drawer-container class="" autosize>
  <mat-drawer #drawer class="" mode="over" position="end">
    <ng-container *ngIf="projectDetails">
      <im-project-detail [data]="projectDetails"></im-project-detail>
    </ng-container>
  </mat-drawer>
  <div class="profile-page" style="background-color: #fff">
    <div class="container">
      <div class="main">
        <div class="main-container">
          <div class="profile">
            <div class="profile-avatar">
              <img [src]="loginUser.avatar" alt="" class="profile-img" />
            </div>
            <div class="profile-name">{{ loginUser.name }}</div>
            <img src="assets/images/Cover1.png" alt="" class="profile-cover" />
            <div class="profile-menu">
              <a
                class="profile-menu-link"
                routerLink="/profile"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >About</a
              >
              <a
                class="profile-menu-link"
                routerLink="feed"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Timeline</a
              >
              <!-- <a class="profile-menu-link" routerLink="edit" >Edit Profile</a> -->
            </div>
          </div>
          <div class="timeline mr-8">
            <div class="timeline-right">
              <router-outlet></router-outlet>
            </div>
            <div class="timeline-left">
              <div class="intro box shadow-1">
                <div class="intro-title">Introduction</div>
                <div class="info">
                  <div class="mb-4 color-secondary">
                    <ng-container *ngIf="loginUser.about">
                      {{ loginUser.about }}
                    </ng-container>
                    <ng-container *ngIf="!loginUser.about">
                      <div class="font-xs">
                        Write about yourself. This is your personal space to
                        express your thoughts and plans. You could even share
                        your profile with your peers.
                        <button
                          mat-button
                          routerLink="edit"
                          style="padding: 0; line-height: 0; color: #d50000"
                        >
                          Edit Now
                        </button>
                      </div>
                    </ng-container>
                  </div>
                  <div class="info-item" *ngIf="loginUser.school">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"
                      />
                    </svg>
                    College at
                    <a href="javascript:void(0)">{{ loginUser.school }}</a>
                  </div>
                  <div class="info-item mt-2" *ngIf="loginUser.grade">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 503.889 503.889"
                      fill="currentColor"
                    >
                      <path
                        d="M453.727 114.266H345.151V70.515c0-20.832-16.948-37.779-37.78-37.779H196.517c-20.832 0-37.78 16.947-37.78 37.779v43.751H50.162C22.502 114.266 0 136.769 0 164.428v256.563c0 27.659 22.502 50.161 50.162 50.161h403.565c27.659 0 50.162-22.502 50.162-50.161V164.428c0-27.659-22.503-50.162-50.162-50.162zm-262.99-43.751a5.786 5.786 0 015.78-5.779h110.854a5.786 5.786 0 015.78 5.779v43.751H190.737zM32 164.428c0-10.015 8.147-18.162 18.162-18.162h403.565c10.014 0 18.162 8.147 18.162 18.162v23.681c0 22.212-14.894 42.061-36.22 48.27l-167.345 48.723a58.482 58.482 0 01-32.76 0L68.22 236.378C46.894 230.169 32 210.321 32 188.109zm421.727 274.725H50.162c-10.014 0-18.162-8.147-18.162-18.161V253.258c8.063 6.232 17.254 10.927 27.274 13.845 184.859 53.822 175.358 52.341 192.67 52.341 17.541 0 7.595 1.544 192.67-52.341 10.021-2.918 19.212-7.613 27.274-13.845v167.733c.001 10.014-8.147 18.162-18.161 18.162z"
                      />
                    </svg>
                    Occupation
                    <a href="javascript:void(0)">{{ loginUser.grade }}</a>
                  </div>
                </div>
              </div>
              <!-- <im-leaderboard></im-leaderboard> -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="overlay"
        (click)="rightSide = false; leftSide = false"
        [ngClass]="{ active: rightSide || leftSide }"
      ></div>
    </div>
  </div>
</mat-drawer-container>
