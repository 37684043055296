import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "@core/services/auth.service";
import { HttpService } from "@core/services/http.service";
import moment from "moment";

@Component({
  selector: "im-community",
  templateUrl: "./community.component.html",
  styleUrls: ["./community.component.scss"],
})
export class CommunityComponent implements OnInit {
  loginUser: any;

  workshopsProcessing: boolean = true;

  workshops: any = [];

  videos: any = [];

  moment = moment;

  constructor(
    private authService: AuthService,
    private http: HttpService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    //this.getWorkshops();
    this.getYoutubeData();
  }

  getWorkshops() {
    this.http
      .get(
        "workshops?notExists=1&filter=event_start_date ge '" +
          moment().format("YYYY-MM-DD") +
          "'"
      )
      .subscribe((res) => {
        this.workshopsProcessing = false;
        this.workshops = res.data;
      });
  }

  getYoutubeData() {
    this.http.post("extra/youtube/getLiveStreams", {}).subscribe((res: any) => {
      console.log(res);
      this.videos = res.data.items;
    });
  }

  getYoutubeUrl(videoId) {
    let url = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/" + videoId
    );
    return url;
  }
}
