<div class="ml-8 h-full summary-profile-section" *ngIf="!processing">
  <div class="shadow-1 flex border-radius-lg mb-8 flex-column">
    <h4 class="p-4">My Courses</h4>
    <div
      class="courses-list-section flex ml-6 mr-2 flex-wrap mb-4"
      *ngIf="courses.length > 0"
    >
      <ng-container *ngFor="let course of courses">
        <div class="item">
          <mat-card
            class="relative p-3 mat-card shadow-2 border-radius-lg course{{
              course.course.id
            }}-text course-border mb-4 cursor-pointer"
            routerLink="/course/{{ course.course.slug }}"
          >
            <mat-card-title class="font-xl mb-1 font-normal">
              <div
                class="flex justify-between course-card-title course-card-title{{
                  course.course.properties.level
                }}"
                [attr.data-level]="course.course.properties.level"
                [ngClass]="{
                  'course-card-title-group': course.course.group_course
                }"
              >
                <a
                  routerLink="/course/{{ course.course.slug }}"
                  class="flex items-center font-sm"
                  ><img
                    [src]="course.course.icon_p"
                    width="32"
                    class="mr-1 course-icon"
                  />
                  {{ course.course.name }}</a
                >
              </div>
            </mat-card-title>
            <mat-card-actions
              class="flex justify-between mat-card-actions flex-column"
            >
              <div class="w-full mt-2">
                <span class="package_info opacity-background font-xs">{{
                  course.package_properties?.name
                }}</span>
              </div>
            </mat-card-actions>
          </mat-card>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="courses.length == 0">
      <div
        class="empty-state flex items-center px-6 flex-column justify-center"
        style="height: 150px"
      >
        <p class="text-center mt-2 font-xs color-secondary">
          You did not enroll for any courses yet. It need not be like that.
          <br />Please explore the available course and pick one.
        </p>
        <button
          mat-raised-button
          color="primary"
          class="mt-2"
          routerLink="/dashboard"
        >
          Buy Now
        </button>
      </div>
    </ng-container>
  </div>

  <!-- <div class="shadow-1 flex border-radius-lg mb-8 p-4 flex-column">
    <h4>Upcoming Schedules</h4>
    <div
      class="empty-state flex items-center px-6 flex-column justify-center"
      style="height: 150px"
    >
      <p class="text-center mt-2 font-xs color-secondary">
        Your upcoming schedules would appear here. As you have not enrolled for
        any course you don’t have any schedule as of today. Please commit to a
        course.
      </p>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        routerLink="/dashboard"
      >
        Buy Now
      </button>
    </div>
  </div> -->

  <div class="shadow-1 flex border-radius-lg mb-8 p-4 flex-column">
    <h4>My Projects</h4>
    <ng-container *ngIf="user_projects.length > 0 && courses.length > 0">
      <ng-container *ngFor="let user_project of user_projects">
        <div class="item mt-6">
          <mat-card
            class="relative p-3 mat-card shadow-2 border-radius-lg course-border mb-4 cursor-pointer"
            (click)="openProjectDetails(user_project)"
          >
            <mat-card-title class="font-xl mb-1 font-normal">
              <div class="flex justify-between items-center course-card-title">
                <span class="flex font-base"
                  ><mat-icon class="mr-2">folder_special</mat-icon>
                  {{ user_project?.user_project.title }}</span
                >
              </div>
            </mat-card-title>
          </mat-card>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="courses.length == 0">
      <div
        class="empty-state flex items-center px-6 flex-column justify-center"
        style="height: 150px"
      >
        <p class="text-center mt-2 font-xs color-secondary">
          Your assignments and commitments related to your course would appear
          here. As you have not enrolled for any course you don’t have any
          tasks. Please explore our course options to decide on one.
        </p>
        <button
          mat-raised-button
          color="primary"
          class="mt-2"
          routerLink="/dashboard"
        >
          Buy Now
        </button>
      </div>
    </ng-container>
  </div>
</div>
