<ng-container *ngIf="!process">
  <mat-sidenav-container autosize>
    <mat-sidenav-content>
      <div
        class="md:grid has-12-columns has-1-rows justify-items-stretch h-screen sm:overflow-y-auto mobile-h-screen overflow-x-hidden"
      >
        <div
          class="main dashboard column-start-1 row-span-1 column-end-9 pb-12 md:column-end-12 xl:column-end-9"
        >
          <div class="px-4 xs:px-3">
            <div
              class="flex justify-between items-center my-4 p-4"
              style="background: #f3e5ff; border-radius: 8px"
            >
              <div class="flex items-center">
                <a class="profile-image mr-3">
                  <img
                    [src]="loginUser.avatar"
                    width="40"
                    height="40"
                    alt="User Avatar Image"
                /></a>
                <div class="flex flex-column">
                  <h2>
                    <!-- <span class="font-normal">👋 Hello,</span> -->
                    {{ loginUser?.name }}
                  </h2>
                  <span *ngIf="loginUser?.about">
                    {{ loginUser?.about }}
                  </span>
                  <div
                    *ngIf="!loginUser?.about"
                    class="mat-error flex flex-column"
                  >
                    Looks like your profile is not updated
                    <button
                      mat-raised-button
                      class="w-1/3 mt-2"
                      routerLink="/profile/edit"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>

              <a routerLink="/profile/edit" class="flex">
                <img
                  src="assets/images/12.png"
                  style="width: 58px; object-fit: contain"
                  width="58"
                  alt="Cookie"
                />
              </a>
            </div>
            <ng-container
              *ngIf="
                !subscriptionExist &&
                !freeTrailRequested &&
                loginUser.user_type == 'student'
              "
            >
              <im-trial></im-trial>
            </ng-container>
            <!-- <ng-container *ngIf="(subscriptionExist || freeTrailRequested) && loginUser.user_type == 'student'">
              <div class="mb-4">
                <div class="banner-1"></div>
              </div>
            </ng-container> -->

            <div *ngIf="loginUser.user_type == 'trainer'" class="flex">
              <mat-card class="w-1/3 mb-4 mr-4 h-1/2">
                <h3 class="mat-color-primary">Presentations</h3>
                <ul class="my-4 p-0 ml-8">
                  <li>
                    <button
                      mat-button
                      routerLink="/trainer-presentations/python"
                    >
                      Python
                    </button>
                  </li>
                  <li>
                    <button
                      mat-button
                      routerLink="/trainer-presentations/power-bi"
                    >
                      Power BI
                    </button>
                  </li>
                </ul>
              </mat-card>
              <mat-card class="w-1/3 mb-4 mr-4">
                <h3 class="mat-color-primary">Notes</h3>
              </mat-card>

              <div class="flex w-1/3 flex-column mb-4">
                <div
                  class="border border-radius-lg px-4 w-full align-items-center cursor-pointer py-4"
                  style="border-color: rgba(0, 0, 0, 0.08); background: #fff3e4"
                  routerLink="/resources"
                >
                  <div class="flex flex-column">
                    <h2 class="mb-3">Resources</h2>
                    <div class="flex justify-between mb-6">
                      <img
                        src="https://app.cedlearn.com/static/python-cheat-sheet-book-cover.jpg"
                        width="42"
                      />
                      <img
                        src="https://app.cedlearn.com/static/Excel_Cheat_Sheet_Cover.png"
                        width="42"
                      />
                      <img
                        src="https://app.cedlearn.com/static/Bussiness_Stats_Cheat_Sheet_Cover.jpg"
                        width="42"
                      />
                      <img
                        src="https://app.cedlearn.com/static/Bussiness_Stats_Inferential_Cheat_Sheet_cover.jpg"
                        width="42"
                      />
                      <img
                        src="https://app.cedlearn.com/static/pandas-documentation-cover.jpg"
                        width="42"
                      />
                      <img
                        src="https://app.cedlearn.com/static/numpy-documentation-cover.jpg"
                        width="42"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="grid has-4-columns column-gap-20 mb-6"
              *ngIf="loginUser.user_type == 'trainer'"
            >
              <mat-card
                class="user-stats blue mat-elevation-z0 cursor-pointer"
                routerLink="/batches"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="my-2"></span>
                  <span class="font-5xl number">{{ batches.length }}</span>
                  <span>Active Batches</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats red mat-elevation-z0 cursor-pointer"
                routerLink="/batches"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="my-2"></span>
                  <span class="font-5xl number">0</span>
                  <span>Completed Batches</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats green mat-elevation-z0 cursor-pointer"
                routerLink="/recorded-classes"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="my-2"></span>
                  <span class="font-5xl number">{{ courses.length }}</span>
                  <span>Recorded Sessions</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats yellow mat-elevation-z0 cursor-pointer"
                routerLink="/notes"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="my-2"></span>
                  <span class="font-5xl number">0</span>
                  <span>Built-In Notes</span>
                </div>
              </mat-card>

              <mat-card
                class="user-stats yellow mat-elevation-z0 mt-5 cursor-pointer"
                routerLink="/tasks"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="my-2"></span>
                  <span class="font-5xl number">0</span>
                  <span>Student Tasks</span>
                </div>
              </mat-card>

              <mat-card
                class="user-stats yellow mat-elevation-z0 cursor-pointer mt-5"
                routerLink="/batch-performance"
                style="background-color: #161612; color: aliceblue"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon style="color: aliceblue">east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="my-2"></span>

                  <span class="number font-5xl"
                    ><mat-icon style="color: aliceblue" class="font-5xl"
                      >batch_prediction</mat-icon
                    ></span
                  >
                  <span>Batch Performance</span>
                </div>
              </mat-card>
            </div>

            <div
              class="grid has-4-columns column-gap-20 mb-6 student-user-stats"
              *ngIf="loginUser.user_type == 'student'"
            >
              <mat-card
                class="user-stats blue mat-elevation-z0 cursor-pointer"
                routerLink="/courses/completed"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="font-3xl number">0</span>
                  <span>Courses Completed</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats red mat-elevation-z0 cursor-pointer"
                routerLink="/courses/certificates"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="font-3xl number">0</span>
                  <span>Certificate earned</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats green mat-elevation-z0 cursor-pointer"
                routerLink="/courses/my"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon>east</mat-icon>
                      </a>
                    </span>
                  </div>
                  <span class="font-3xl number">{{ onGoingCourses }}</span>
                  <span>Courses in progress</span>
                </div>
              </mat-card>
              <mat-card
                class="user-stats yellow mat-elevation-z0 cursor-pointer"
                routerLink="/batch-performance"
                style="background-color: #161612; color: aliceblue"
              >
                <div class="flex flex-column">
                  <div>
                    <span class="float-right">
                      <a>
                        <mat-icon style="color: aliceblue">east</mat-icon>
                      </a>
                    </span>
                  </div>

                  <span class="font-3xl number" *ngIf="!batchName"
                    ><mat-icon style="color: aliceblue" class="font-3xl"
                      >batch_prediction</mat-icon
                    ></span
                  >
                  <span
                    class="font-3xl number"
                    style="text-transform: uppercase"
                    *ngIf="batchName"
                    >{{ batchName }}</span
                  >
                  <span>Batch Performance</span>
                </div>
              </mat-card>
            </div>

            <div
              class="flex flex-column mb-4"
              *ngIf="loginUser.user_type == 'student'"
            >
              <div class="flex flex-column justify-between">
                <div
                  class="pr-4 w-full flex justify-between align-items-center cursor-pointer xs:flex-column xs:py-8"
                >
                  <div class="flex flex-column w-3/4" routerLink="/resources">
                    <h2 class="mb-4">Cheat Sheets</h2>
                    <div
                      class="resources-cheat-thub border border-radius-lg px-1 py-1"
                      style="
                        border-color: rgba(0, 0, 0, 0.08);
                        background: #fff3e4;
                      "
                    >
                      <img
                        src="https://app.cedlearn.com/static/python-cheat-sheet-book-cover.jpg"
                        class="border-radius-md"
                      />
                      <img
                        src="https://app.cedlearn.com/static/Excel_Cheat_Sheet_Cover.png"
                        class="border-radius-md"
                      />
                      <img
                        src="https://app.cedlearn.com/static/Bussiness_Stats_Cheat_Sheet_Cover.jpg"
                        class="border-radius-md"
                      />
                      <img
                        src="https://app.cedlearn.com/static/Bussiness_Stats_Inferential_Cheat_Sheet_cover.jpg"
                        class="border-radius-md"
                      />
                      <img
                        src="https://app.cedlearn.com/static/pandas-documentation-cover.jpg"
                        class="border-radius-md"
                      />
                      <img
                        src="https://app.cedlearn.com/static/numpy-documentation-cover.jpg"
                        class="border-radius-md"
                      />
                      <img
                        src="https://app.cedlearn.com/static/matplotlib-documentation-cover.jpg"
                        class="border-radius-md"
                      />
                      <img
                        src="https://app.cedlearn.com/static/sql-cheat-sheet-cover-image.jpg"
                        class="border-radius-md"
                      />
                    </div>
                  </div>
                  <div
                    class="w-1/4 ml-2"
                    routerLink="/resources"
                    [queryParams]="{ m: 'data-sets' }"
                  >
                    <h2 class="mb-4">Data Sets</h2>
                    <div
                      class="border border-radius-lg p-6 flex items-center flex-column"
                      style="border-color: #3db2f9"
                    >
                      <div class="flex w-1/2 mb-4">
                        <img
                          src="https://app.cedlearn.com/static/resources/icons/excel.svg"
                          width="42"
                        />
                        <img
                          src="https://app.cedlearn.com/static/resources/icons/zip.svg"
                          width="42"
                        />
                      </div>

                      <button
                        mat-raised-button
                        color="primary"
                        class="letter-spacing-15 text-uppercase font-xss"
                        style="color: #f1f2f6"
                      >
                        Explore NOW
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-row justify-between items-center mt-4">
                <h2>Popular courses</h2>
                <button
                  mat-button
                  color="primary"
                  routerLink="/courses"
                  style="letter-spacing: 1.5px; font-weight: 400"
                >
                  VIEW ALL
                </button>
              </div>
            </div>
            <!-- <h2 class="pb-2">Courses</h2> -->
            <ng-container
              *ngIf="processing && loginUser.user_type == 'student'"
            >
              <div
                class="flex flex-column items-center justify-center relative w-full mb-4"
                style="min-height: 175px"
              >
                <mat-spinner strokeWidth="3"></mat-spinner>
                <img
                  src="assets/images/icon.png"
                  class="absolute"
                  width="64"
                  alt="Processing"
                />
              </div>
            </ng-container>
            <div
              class="grid has-4-columns column-gap-20 im-courses"
              *ngIf="loginUser.user_type == 'student'"
            >
              <ng-container *ngFor="let course of courses">
                <mat-card
                  class="relative p-3 mat-card shadow-1 border-radius-lg cursor-pointer course{{
                    course.id
                  }}-text course-border mb-3 xxl:mb-4 da-course-card"
                  [ngStyle]="{
                    'background-color': course.user_course ? '#f6fcff' : '#fff'
                  }"
                  routerLink="/course/{{ course.slug }}"
                  style="border-color: rgba(0, 0, 0, 0.08); min-width: 25%"
                >
                  <mat-card-title class="font-xl mb-1 font-normal">
                    <div
                      class="flex justify-between course-card-title course-card-title{{
                        course.properties.level
                      }}"
                      [attr.data-level]="course.properties.level"
                      [ngClass]="{
                        'course-card-title-group': course.group_course
                      }"
                    >
                      <div class="flex items-center font-base">
                        <img
                          [src]="course.icon_p"
                          width="32"
                          class="mr-1 course-icon"
                        />
                        {{ course.name }}
                      </div>
                      <!-- <ng-container *ngIf="!course.user_course">
                        <div *ngIf="course.properties && course.properties.childCourses" class="flex child-course">
                          <span *ngFor="let childCourse of course.properties.childCourses" class="flex items-center">
                            +&nbsp;<img [src]="childCourse.icon_p" width="24" height="24"/>
                          </span>
                        </div>
                      </ng-container> -->
                    </div>
                  </mat-card-title>
                  <mat-card-content>
                    <!-- <ng-container *ngIf="!course.user_course">
                    <div class="flex mb-1 mt-1">
                      <span class="font-semi-bold color-primary font-xs">{{course.properties.duration}} {{course.properties.durationUnit}}</span> 
                      <span class="dot-separator color-primary">.</span>
                      <span class="font-semi-bold color-primary font-xs">{{course.properties.sessions}} Sessions</span> 
                      <span class="dot-separator color-primary">.</span>
                      <span class="font-semi-bold color-primary font-xs">{{course.properties.projectSessions}} Hr. Project</span>
                    </div>
                  </ng-container> -->
                    <ng-container *ngIf="course.user_course">
                      <div
                        class="flex mb-1 items-center justify-evenly mt-4 mb-2"
                      >
                        <div
                          class="flex flex-column items-center justify-center"
                        >
                          <span
                            class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                            style="width: 32px; height: 32px"
                            >0</span
                          >
                          <span
                            class="color-primary highlight-item-title mt-2 font-semi-bold"
                            >Assign</span
                          >
                        </div>
                        <div
                          class="flex flex-column items-center justify-center"
                        >
                          <span
                            class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                            style="width: 32px; height: 32px"
                            >0</span
                          >
                          <span
                            class="color-primary highlight-item-title mt-2 font-semi-bold"
                            >Assess</span
                          >
                        </div>
                        <div
                          class="flex flex-column items-center justify-center"
                        >
                          <span
                            class="font-semi-bold color-primary font-xs border-radius-full shadow-1 items-center justify-center flex"
                            style="width: 32px; height: 32px"
                            >0</span
                          >
                          <span
                            class="color-primary highlight-item-title mt-2 font-semi-bold"
                            >Projects</span
                          >
                        </div>
                      </div>
                    </ng-container>

                    <p
                      class="font-xs color-secondary line-clamp-2 mt-3 mb-2"
                      *ngIf="!course.user_course"
                    >
                      {{ course.short_description }}
                    </p>
                  </mat-card-content>
                  <mat-card-actions
                    class="flex justify-between mat-card-actions"
                    [ngClass]="{ 'flex-column': course.user_course }"
                  >
                    <ng-container *ngIf="!course.user_course">
                      <button
                        mat-raised-button
                        color="primary"
                        class="letter-spacing-15 text-uppercase font-xss"
                        routerLink="/course/{{ course.slug }}"
                        style="height: 32px; line-height: 32px"
                      >
                        Explore
                      </button>
                      <ng-container *ngIf="!platform.IOS">
                        <button
                          mat-stroked-button
                          class="letter-spacing-15 text-uppercase font-xss"
                          (click)="buyNow(course)"
                          style="height: 32px; line-height: 32px"
                          color="primary"
                        >
                          Buy Now
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="course.user_course">
                      <!-- <div class="w-full">
                        <span class="float-right" style="margin-top: -1em"
                          >0%</span
                        >
                        <mat-progress-bar
                          class="mt-4 w-full"
                          color="course{{ course.id }}"
                          mode="determinate"
                          value="0"
                          style="bottom: 0.75em"
                        ></mat-progress-bar>
                      </div> -->
                      <div class="w-full mt-2">
                        <span class="package_info opacity-background font-xs">{{
                          course.user_course?.package_properties?.name
                        }}</span>
                        <!-- <button
                          mat-mini-fab
                          class="float-right mat-elevation-z1"
                          routerLink="/course/{{ course.slug }}"
                          style="background: #ffbd32; color: #565050"
                        >
                          <mat-icon>east</mat-icon>
                        </button> -->
                      </div>
                    </ng-container>
                  </mat-card-actions>
                </mat-card>
              </ng-container>
            </div>
            <!-- <div class="xs:display-none">
          <div class="flex free-stuff border-radius-lg shadow-1 p-4 mb-4 items-center" style="height: 10vh;">
            <div class="w-1/3 flex items-center cursor-pointer" (click)="openBigBang()">
              <img src="assets/images/animation_200_kt0lmc5r.gif" width="60" style="width: 60px;"/>
              AI Big bang theory
            </div>
            <div class="w-1/3 flex items-center cursor-pointer" (click)="book417()">
                <img src="assets/images/Booklet.png">
                CBSE AI Course Books (Code - 417)
            </div>
            <div class="w-1/3 flex items-center cursor-pointer">
              <a href="https://sfai.cc/0J" target="_blank" class="flex items-center">
                <img src="assets/images/AI_Booklet.png">
                AI Booklet.  
              </a>
            </div>
        </div>
        </div> -->
          </div>
        </div>

        <div
          class="courses-section column-start-10 row-span-1 column-end-12 xs:display-none md:display-none xl:display-block"
        >
          <div class="py-4 pr-4">
            <mat-accordion multi="true">
              <mat-expansion-panel
                expanded="true"
                hideToggle
                class="today-schedule m-0 xs:mb-4 mb-4 mat-elevation-z0"
                disabled
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="font-medium font-sm">Upcoming</span>
                  </mat-panel-title>
                  <mat-panel-description>
                    <button
                      mat-button
                      color="primary"
                      routerLink="/schedule"
                      style="letter-spacing: 1.5px; font-weight: 400"
                    >
                      VIEW ALL
                    </button>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-container *ngIf="schedulesProcessing">
                  <div
                    class="flex flex-column items-center justify-center h-full relative"
                  >
                    <mat-spinner strokeWidth="3"></mat-spinner>
                    <img
                      src="assets/images/icon.png"
                      class="absolute"
                      width="64"
                    />
                  </div>
                </ng-container>

                <div class="flex flex-column">
                  <div
                    *ngFor="let schedule of schedules"
                    class="flex mt-2 px-0 py-2 cursor-pointer items-center"
                    (click)="openEvent(schedule)"
                  >
                    <div
                      class="mr-4 course1-text course-border-top border-radius-lg shadow-1 flex items-center flex-column"
                      style="min-width: 3.2rem; border: 0"
                    >
                      <div
                        style="
                          font-size: 11px;
                          border-radius: 8px 8px 0 0;
                          background-color: currentColor;
                          width: 100%;
                        "
                        class="text-center"
                      >
                        <span style="color: #fff"
                          >{{ schedule.event_start_date | date : "MMM" }}
                          {{ schedule.event_start_date | date : "dd" }}
                        </span>
                      </div>
                      <div
                        class="flex flex-column items-center"
                        style="padding: 3px 2px 0 2px"
                      >
                        <p class="font-bolder text-center">
                          {{
                            schedule.event_start_date +
                              " " +
                              schedule.event_start_time | date : "h:mm"
                          }}
                        </p>
                        <p class="font-xs time-prefix">
                          {{
                            schedule.event_start_date +
                              " " +
                              schedule.event_start_time | date : "a"
                          }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p class="font-medium">{{ schedule.name }}</p>
                      <p class="font-xs line-clamp-1 font-light mt-1">
                        {{ schedule.short_description }}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  class="empty-state flex items-center flex-column justify-center"
                  *ngIf="!schedules.length && !schedulesProcessing"
                  style="height: 288px !important"
                >
                  <mat-icon class="border-radius-full">search</mat-icon>
                  <p class="text-center mt-2 color-secondary">
                    No Schedules found. <br />Please explore the courses and
                    start learning.
                  </p>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel
                expanded="true"
                hideToggle
                class="start-practicing m-0 xs:display-none mb-2 mat-elevation-z0"
                disabled
              >
                <div
                  class="start-practicing-empty"
                  style="
                    background: url('assets/images/coder.jpg');
                    height: 300px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                  "
                >
                  <div
                    class="start-practicing-empty-wrapper h-full"
                    style="background-color: rgba(0, 0, 0, 0.4)"
                  >
                    <div
                      class="text-center mb-3 cursor-pointer"
                      routerLink="/coder"
                    >
                      <!-- <img src="assets/images/coder.jpeg" style="width: 60%" /> -->
                      <h2
                        class="letter-spacing-15 text-uppercase"
                        style="color: white"
                      >
                        CODER
                      </h2>
                      <p
                        class="font-sm mt-2 mx-4"
                        style="color: rgb(255 255 255 / 75%)"
                      >
                        Practice your coding skills in Python and MySQL
                        supported by code snippets
                      </p>
                    </div>
                    <button
                      mat-raised-button
                      color="primary"
                      class="letter-spacing-15 text-uppercase mb-3"
                      routerLink="/coder"
                    >
                      GO CODING
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>

              <!-- <mat-expansion-panel expanded="true" hideToggle class="start-practicing m-0 xs:display-none" disabled>
                <div class="start-practicing-empty ai">
                  <div class="start-practicing-empty-wrapper">
                    <div class="text-center mb-3">
                      <h2 class="letter-spacing-15 text-uppercase">AI Practice</h2>
                      <p class="font-sm mt-2 color-secondary-white">One of a kind AI practice modules in Data Science
                        and AI for skill building</p>
                    </div>
                    <button mat-raised-button color="primary" class="letter-spacing-15 text-uppercase"
                      routerLink="/ai-coder">START BUILDING</button>

                  </div>
                </div>

              </mat-expansion-panel> -->
            </mat-accordion>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
    <mat-sidenav #drawer mode="over" position="end" class="w-1/3">
      <ng-container *ngIf="activeEvent">
        <im-schedule-details
          [activeEvent]="activeEvent"
          [drawer]="drawer"
        ></im-schedule-details>
      </ng-container>
    </mat-sidenav>
  </mat-sidenav-container>
</ng-container>
