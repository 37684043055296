import { Component, OnInit } from "@angular/core";
import { AuthService } from "@core/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "@core/services/http.service";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit {
  navLinks = [
    {
      label: "Dashboard",
      icon: "assets/images/widgets.svg",
      iconS: "assets/images/widgets.svg",
      path: "/dashboard",
    },
    {
      label: "Courses",
      icon: "assets/images/courses.svg",
      iconS: "assets/images/courses.svg",
      path: "/courses",
    },
    {
      label: "Schedule",
      icon: "assets/images/event_note.svg",
      iconS: "assets/images/event_note.svg",
      path: "/schedule",
    },
    {
      label: "Coder",
      icon: "assets/images/coder.svg",
      iconS: "assets/images/coder.svg",
      path: "/coder",
    },
    {
      label: "AI Labs",
      icon: "assets/images/ai.png",
      iconS: "assets/images/ai.png",
      path: "/ai-coder",
    },
    {
      label: "Notes",
      icon: "assets/images/note.svg",
      iconS: "assets/images/note.svg",
      path: "/notes",
    },
    {
      label: "Community",
      icon: "assets/images/question_answer.svg",
      iconS: "assets/images/question_answer.svg",
      path: "/community",
    },
  ];

  loginUser: any;

  scQueryParam: boolean = false;

  constructor(
    private http: HttpService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.authService.userProfileUpdated.subscribe((res) => {
      this.loginUser = res;
    });
  }

  async ngOnInit() {
    //this.loginUser = await this.authService.me();
    this.loginUser = await this.authService.loginUser;
    // if (!this.loginUser) {
    //   this.loginUser = await this.authService.me();
    // }
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.sc) {
        this.scQueryParam = true;
      } else {
        this.scQueryParam = false;
      }
    });
  }

  logout() {
    this.http.post("logout", {}).subscribe((res) => {
      if (res.success) {
        this.authService.logout();
        this.router.navigate(["auth"]);
      }
    });
  }
}
