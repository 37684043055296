<div class="im-bar-recorded-sessions">
  <div class="flex items-center ml-4">
    <button
      mat-icon-button
      class="mr-3"
      (click)="back()"
      style="border: 1px solid; width: 32px; height: 32px; line-height: 32px"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <h2 class="px-2 xs:px-4">Recorded Sessions - {{ course?.name }}</h2>
  </div>
</div>
<div
  class="w-full flex flex-row relative overflow-hidden course-record-videos"
  style="height: calc(100vh - 60px)"
  *ngIf="!processing"
>
  <!-- <h2 class="p-4"><strong>{{course.name}}</strong> Collections</h2>
    <div class="flex flex-wrap px-4 py-2" *ngIf="course.properties?.record_collections">
        <div class="width-48 ml-6 mt-6" *ngFor="let video of collections">
            <div style='padding:62.5% 0 0 0;position:relative;'><iframe [src]='video' allowfullscreen frameborder='0'
                    style='position:absolute;top:0;left:0;width:100%;height:100%;'></iframe>
            </div>
        </div>
    </div> -->

  <!-- <div class="w-2/5 px-6 mt-6">
    <img [src]="course.cover_image_p" />
    <h2>{{ course.name }}</h2>
    <p>{{ course.short_description }}</p>
  </div> -->
  <div
    class="w-3/5 xs:w-full"
    style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
  >
    <mat-tab-group
      (selectedIndexChange)="changeTopic($event)"
      [selectedIndex]="selectedIndex"
    >
      <ng-container *ngFor="let topic of topics">
        <mat-tab [label]="topic">
          <ng-container *ngIf="movieApiProcessing[topic]">
            <div
              class="flex flex-column items-center justify-center relative"
              style="height: calc(100vh - 110px)"
            >
              <mat-spinner strokeWidth="3"></mat-spinner>
              <img src="assets/images/icon.png" class="absolute" width="64" />
            </div>
          </ng-container>
          <div
            class="p-6"
            style="height: calc(100vh - 110px); overflow-y: auto"
            *ngIf="!movieApiProcessing[topic]"
          >
            <div
              class="grid has-3-columns xs:has-1-columns column-gap-20 row-gap-20"
            >
              <ng-container *ngFor="let movie of movies[topic]">
                <div
                  [ngClass]="{
                    'active-movie-card': activeMovie?.id == movie.id
                  }"
                  class="movie-record-card"
                >
                  <mat-card
                    class="p-0 cursor-pointer"
                    (click)="openVideo(movie)"
                  >
                    <img mat-card-image [src]="movie.thumbnail" class="m-0" />
                    <mat-card-content>
                      <p class="p-2 font-xs font-semi-bold">
                        {{ movie.title }}
                      </p>
                    </mat-card-content>
                  </mat-card>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
  <div class="w-2/5 movie-sidebar" [ngClass]="{ 'movie-slecetd': activeMovie }">
    <ng-container *ngIf="activeMovie">
      <div class="flex justify-between items-center">
        <h3 class="p-6">{{ activeMovie.title }}</h3>
        <button
          mat-icon-button
          color="warn"
          (click)="activeVideo = ''; activeMovie = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div
        style="padding-top: 56%; position: relative; overflow: hidden"
        *ngIf="activeVideo"
      >
        <iframe
          frameborder="0"
          allowfullscreen=""
          scrolling="no"
          allow="autoplay;fullscreen"
          [src]="activeVideo"
          style="
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0px;
            top: 0px;
          "
        ></iframe>
      </div>
    </ng-container>
    <ng-container *ngIf="!activeMovie">
      <im-empty></im-empty>
    </ng-container>
  </div>
  <!-- <div class="w-1/2 px-8 absolute" style="left: 50%">
        <mat-list *ngIf="classes.length > 0">
            <ng-container *ngFor="let class of classes">
                <mat-list-item>
                    <mat-icon mat-list-icon (click)="play(class.video_id)" class="cursor-pointer">play_circle_outline
                    </mat-icon>
                    <h4 mat-line><strong>{{class.name}}</strong></h4>
                    <p mat-line> {{class.class_date | date}} </p>
                    <button mat-stroked-button color="primary" style="letter-spacing: 1.5px;"
                        (click)="play(class.video_id)">PLAY</button>

                </mat-list-item>
                <mat-divider></mat-divider>
            </ng-container>
        </mat-list>

        <div class="empty-state flex items-center px-6 py-12 my-12 flex-column" *ngIf="classes.length == 0">
            <mat-icon class="border-radius-full">search</mat-icon>
            <p class="text-center mt-2 font-base color-secondary">
                No Recordings available. <br />Please explore the by click on the below button.
            </p>
            <button mat-raised-button color="primary" class="mt-2" routerLink="/courses">Explore</button>
        </div>
    </div> -->
</div>
