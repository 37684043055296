<div class="alert">
  <div class="alert-title">Create Batch</div>
  <div class="alert-subtitle">Create a new batch for enrolled courses</div>
  <form [formGroup]="createBatchGroup">
    <input
      type="text"
      placeholder="Enter batch keyword"
      formControlName="name"
    />
    <mat-form-field appearance="outline" class="mt-2 course-selection w-full">
      <mat-select placeholder="Select" formControlName="refId">
        <mat-option
          value="{{ course.id }}"
          *ngFor="let course of originalCourses"
          >{{ course.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      class="search-buttons"
      (click)="createBatch()"
      [disabled]="!this.createBatchGroup.valid"
    >
      Create Now
    </button>
  </form>
</div>
