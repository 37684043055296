<ng-container *ngIf="data && !processing">
  <div style="width: 500px">
    <img
      [src]="'assets/images/event-cover-image.jpg'"
      class="w-full cover-image"
      style="object-position: top; height: 220px"
    />
    <div class="px-4 py-4 event-details-popup">
      <h3 class="font-xl">
        {{ data.user_project.title }}
      </h3>
      <div class="mt-1 course-about-section">
        <div id="editorjs"></div>
      </div>

      <!-- <p class="mt-8">Participants</p> -->
    </div>
    <h3 class="px-4">Resource Files</h3>
    <div
      *ngFor="let resource_file of resource_files; let i = index"
      class="px-4"
    >
      <div class="cdx-attaches cdx-attaches--with-file mt-2">
        <div
          class="cdx-attaches__file-icon"
          data-extension="zip"
          style="color: #4f566f"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="40">
            <path
              d="M17 0l15 14V3v34a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h20-6zm0 2H3a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V14H17V2zm2 10h7.926L19 4.602V12z"
            ></path>
          </svg>
        </div>
        <div class="cdx-attaches__file-info">
          <div class="cdx-attaches__title" contenteditable="false">
            {{ resource_file.file_name }}
          </div>
          <div class="cdx-attaches__size" data-size="KiB">174.4</div>
        </div>
        <a
          class="cdx-attaches__download-button"
          [href]="resource_file.file_name_p"
          target="_blank"
          rel="nofollow noindex noreferrer"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="17pt"
            height="17pt"
            viewBox="0 0 17 17"
          >
            <path
              d="M9.457 8.945V2.848A.959.959 0 0 0 8.5 1.89a.959.959 0 0 0-.957.957v6.097L4.488 5.891a.952.952 0 0 0-1.351 0 .952.952 0 0 0 0 1.351l4.687 4.688a.955.955 0 0 0 1.352 0l4.687-4.688a.952.952 0 0 0 0-1.351.952.952 0 0 0-1.351 0zM3.59 14.937h9.82a.953.953 0 0 0 .953-.957.952.952 0 0 0-.953-.953H3.59a.952.952 0 0 0-.953.953c0 .532.425.957.953.957zm0 0"
              fill-rule="evenodd"
            ></path></svg
        ></a>
      </div>
    </div>
  </div>
</ng-container>
