import { Component, OnInit, Input } from "@angular/core";
import { HttpService } from "@core/services/http.service";

import Header from "@editorjs/header";
import List from "@editorjs/list";
import RawTool from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Checklist from "@editorjs/checklist";
import Embed from "../../../../core/ui/editor/embed";
import Quote from "@editorjs/quote";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import CodeTool from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import Paragraph from "@editorjs/paragraph";
import InlineCode from "@editorjs/inline-code";
import EditorJS from "@editorjs/editorjs";
import AttachesTool from "../../../../core/ui/editor/attaches";

import { environment } from "@environment/environment";

@Component({
  selector: "im-project-detail",
  templateUrl: "./project-detail.component.html",
  styleUrls: ["./project-detail.component.scss"],
})
export class ProjectDetailComponent implements OnInit {
  @Input() data: any;

  resource_files: any = [];

  editor: any;

  processing = false;

  constructor(private http: HttpService) {}

  ngOnInit(): void {
    this.processing = true;
    this.getFiles();
  }

  getFiles() {
    this.http
      .get(
        "user_project_files?filter=user_project_id eq " +
          this.data.user_project_id
      )
      .subscribe((res) => {
        this.processing = false;
        this.resource_files = res.data;
        setTimeout(() => {
          this.processEditor();
        }, 100);
      });
  }

  processEditor() {
    const that = this;
    this.editor = new EditorJS({
      data: this.data.user_project.description,
      readOnly: true,
      tools: {
        header: Header,
        list: List,
        paragraph: {
          class: Paragraph,
        },
        //image: SimpleImage,
        checklist: Checklist,
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              vimeo: {
                regex:
                  /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
                embedUrl: environment.playerUrl + "<%= remote_id %>",
                html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
                height: 300,
                width: 600,
                id: ([id]) => {
                  const params = [
                    "autoplay=false",
                    "autopause=true",
                    "muted=false",
                    "loop=false",
                    `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                    "poster=",
                    "time=true",
                    "progressBar=true",
                    "overlay=true",
                    "muteButton=true",
                    "fullscreenButton=true",
                    "style=light",
                    "quality=auto",
                    "playButton=true",
                    "pip=true",
                  ];
                  return "?" + params.join("&");
                },
              },
              schoolforai: {
                regex:
                  /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
                embedUrl:
                  environment.playerUrl +
                  "?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true",
                html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
                height: 300,
                width: 600,
              },
            },
          },
        },
        attaches: {
          class: AttachesTool,
          config: {
            //   endpoint: environment.mediaUrl+'/upload',
            //   field: 'file[0]',
            //   additionalRequestData: {
            //     upload_path: '/content',
            //     custom_attrs: [],
            //     random_names: false,
            //     modify_output: true
            //   },
            //   additionalRequestHeaders: {
            //    Authorization: `Bearer ${this.authService.getToken()}`
            //  },
          },
        },
        quote: Quote,
        table: Table,
        raw: RawTool,
        code: CodeTool,
        linkTool: LinkTool,
        marker: Marker,
        warning: Warning,
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
        image: {
          class: ImageTool,
        },
      },
      onChange: () => {},
      onReady: () => {
        window.addEventListener("message", function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + "%";
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }
}
