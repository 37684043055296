import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class UserCheckGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}
  
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.auth.me();
    return true;
  }
}