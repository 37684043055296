<ng-container [ngSwitch]="question.type">
	<div [formGroup]="form">
		<ng-container *ngSwitchCase="'array'"> 
			<a class="flex items-center cursor-pointer mb-4" (click)="createField(form, question.key, question.childQuestions)"><img src="assets/images/add-new.svg"  class="mr-2" width="18">Add</a>
			<div formArrayName="{{question.key}}" class="flex justify-between flex-column">
				<mat-error *ngIf="form.get(question.key).hasError('minLengthArray')"> Minimum {{question.minimum}} {{question.label}} <strong>required</strong> </mat-error>
					<ng-container *ngFor="let y of form.get(question.key)['controls'];let j=index">
						<div class="flex flex-row justify-between px-2 pb-2 border border-width-1 mb-4 relative pt-8 border-radius-lg items-center xs:flex-column" [formGroupName]="j">
							<a class="flex justify-end items-center cursor-pointer absolute" (click)="removeField(form, question.key, j)" style="top: 0.5em;right: 0.5em;"> <mat-icon class="mr-1" color="warn">highlight_off</mat-icon></a>
							<div *ngFor="let childQuestion of question.childQuestions;" class="form-row w-48 xs:w-full">
								<app-question [question]="childQuestion" [form]="form.get(question.key+'.'+j)"></app-question>
							</div>
						</div>
					</ng-container>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="'object'"> 
			<div class="flex justify-between xs:flex-column">
				<div *ngFor="let childQuestion of question.childQuestions" class="form-row w-48 xs:w-full">
					<app-question [question]="childQuestion" [form]="form.get(question.key)"></app-question>
				</div>	
			</div>
				   
		</ng-container>
		<ng-container *ngSwitchCase="'hidden'">
			<input [formControlName]="question.key" type="hidden" [id]="question.key"> 
		</ng-container>
		<ng-container *ngSwitchCase="'string'">
			<mat-form-field class="full-width clearfix clear"  appearance="outline">
				<mat-label [attr.for]="question.key">{{question.label}}</mat-label>
				<input matInput placeholder=""  [formControlName]="question.key" [type]="question.type" [id]="question.key" [required]="question.required" [readonly]="question.readonly">
				<mat-error *ngIf="!isValid"> {{question.label}} is <strong>required</strong> </mat-error>
				<mat-hint *ngIf="question.hintText">{{question.hintText}}</mat-hint>
			</mat-form-field>
		</ng-container>
		<ng-container *ngSwitchCase="'number'">
			<mat-form-field class="full-width clearfix clear"  appearance="outline">
				<mat-label [attr.for]="question.key">{{question.label}}</mat-label>
				<input matInput placeholder=""  [formControlName]="question.key" [type]="question.type" [id]="question.key" [required]="question.required" [min]="question.minimum" [max]="question.maximum" [readonly]="question.readonly">
				<mat-error *ngIf="form.get(question.key).hasError('required')"> {{question.label}} is <strong>required</strong> </mat-error>
				<mat-error *ngIf="form.get(question.key).hasError('min')"> {{question.label}} value should be greater than or equal to  <strong>{{question.minimum}}</strong> </mat-error>
				<mat-error *ngIf="form.get(question.key).hasError('max')"> {{question.label}} value should be less than or equal to  <strong>{{question.maximum}}</strong> </mat-error>
				<mat-hint *ngIf="question.hintText">{{question.hintText}}</mat-hint>
			</mat-form-field>
		</ng-container>
		<ng-container *ngSwitchCase="'textarea'">
			<mat-form-field class="full-width clearfix clear im-textarea"  appearance="outline">
				<mat-label [attr.for]="question.key">{{question.label}}</mat-label>
				<textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" [formControlName]="question.key" [id]="question.key" [required]="question.required" [readonly]="question.readonly"></textarea>
				<mat-error *ngIf="!isValid"> {{question.label}} is <strong>required</strong> </mat-error>
				<mat-hint *ngIf="question.hintText">{{question.hintText}}</mat-hint>
			</mat-form-field>
		</ng-container>
		<ng-container *ngSwitchCase="'email'">
			<mat-form-field class="full-width clearfix clear"  appearance="outline">
				<mat-label [attr.for]="question.key">{{question.label}}</mat-label>
				<input matInput placeholder=""  [formControlName]="question.key" [type]="question.type" [id]="question.key" [attr.required]="question.required">
				<mat-error *ngIf="!isValid"> {{question.label}} is <strong>required</strong> </mat-error>
				<mat-hint *ngIf="question.hintText">{{question.hintText}}</mat-hint>
			</mat-form-field>
		</ng-container>
		<ng-container *ngSwitchCase="'colorpicker'">
			<mat-form-field class="full-width clearfix clear"  appearance="outline">
				<mat-label [attr.for]="question.key">{{question.label}}</mat-label>
				<input matInput placeholder=""  [formControlName]="question.key" type="color" [id]="question.key" [attr.required]="question.required">
				<mat-error *ngIf="!isValid"> {{question.label}} is <strong>required</strong> </mat-error>
				<mat-hint *ngIf="question.hintText">{{question.hintText}}</mat-hint>
			</mat-form-field>
		</ng-container>
		<ng-container *ngSwitchCase="'radio'">
			<div class="full-width mb-4">
				<label>{{question.label}}: </label>
				<mat-radio-group formControlName="floatLabel" class="flex flex-column" [formControlName]="question.key" [value]="question.value">
					<ng-container *ngFor="let opt of question.options">
						<mat-radio-button [value]="opt.value" class="py-2">{{opt.title}}</mat-radio-button>
					</ng-container>
				</mat-radio-group>
			  </div>
		</ng-container>
		<ng-container *ngSwitchCase="'range'">
			<mat-form-field class="full-width clearfix clear nopaading-infix"  appearance="outline">
				<input matInput type="number"  [formControlName]="question.key" [id]="question.key" hidden />
				<mat-label [attr.for]="question.key">{{question.label}}</mat-label>
				<mat-slider
				class="w-full"
				[max]="question.maximum"
				[min]="question.minimum"
				thumbLabel="true"
				tickInterval="10"
				[(value)]="question.value"
				(change)="setValue(form, question.key, $event)"
				>
			</mat-slider>
			<ng-container *ngIf="question.hintText">
				<span matSuffix><mat-icon matTooltip="{{question.hintText}}">info</mat-icon></span>
			</ng-container>
			</mat-form-field>
			 
		</ng-container>
		<ng-container *ngSwitchCase="'dropdown'">
			<mat-form-field class="full-width clearfix clear"  appearance="outline">
				<mat-label [attr.for]="question.key">{{question.label}}</mat-label>
				<mat-select [id]="question.key" [formControlName]="question.key" [value]="question.value" [panelClass]="'im-select-dropdown'" [compareWith]="compareFn" [required]="question.required">
					<ng-container *ngFor="let opt of question.options">
						<mat-option [value]="opt.value" [disabled]="opt?.disabled"> {{opt.title}} </mat-option>
						<ng-container *ngIf="opt?.child">
							<mat-optgroup [label]="">
								<ng-container *ngFor="let child of opt.child">
									<mat-option [value]="child.id"> {{child.name}} </mat-option>
									<ng-container *ngIf="child?.child">
										<mat-optgroup [label]="">
											<ng-container *ngFor="let child1 of child.child">
												<mat-option [value]="child1.id" style="margin-left: 8px;"> {{child1.name}} </mat-option>
												<ng-container *ngIf="child1?.child">
													<mat-optgroup [label]="">
														<mat-option *ngFor="let child2 of child1.child" [value]="child2.id" style="margin-left: 16px;"> {{child2.name}} </mat-option>
													</mat-optgroup>
												</ng-container>
											</ng-container>
										</mat-optgroup>
									</ng-container>
								</ng-container>
							</mat-optgroup>
						</ng-container>
					</ng-container>
				</mat-select>
				<mat-error *ngIf="!isValid"> {{question.label}} is <strong>required</strong> </mat-error>
				<mat-hint *ngIf="question.hintText">{{question.hintText}}</mat-hint>
			</mat-form-field>
		</ng-container>
		<ng-container *ngSwitchCase="'dropdown_search'"> </ng-container>
		<ng-container *ngSwitchCase="'editor'"> </ng-container>
		<ng-container *ngSwitchCase="'file'">
			<mat-label [attr.for]="question.key">{{question.label}} <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker" *ngIf="question.required"> *</span> </mat-label>
			<app-file-upload [field]="question" (fileSuccess)="fileSuccess($event)" [parentGroup]="form" [control]="form.get(question.key)"></app-file-upload>
			<mat-error *ngIf="form.get(question.key).hasError('minLengthArray')"> Minimum {{question.minimum}} {{question.label}}`s <strong>required</strong> </mat-error>
		</ng-container>
	</div>
</ng-container>