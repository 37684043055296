import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleComponent } from './schedule.component';

import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@core/ui/calendar';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { DetailsComponent } from './details/details.component';
import { OnlineComponent } from './online/online.component';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { WeekComponent } from './week/week.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';
import { LongComponent } from './long/long.component';
import { MatStepperModule } from '@angular/material/stepper';
import { QuestionnaireModule } from './questionnaire/questionnaire.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CertificateComponent } from './long/certificate/certificate.component';
import {MatListModule} from '@angular/material/list';

@NgModule({
  declarations: [ScheduleComponent, OnlineComponent, DetailsComponent, WeekComponent, LongComponent, CertificateComponent],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    RouterModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatStepperModule,
    QuestionnaireModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule
  ],
  exports: [DetailsComponent],
  entryComponents: [OnlineComponent]
})
export class ScheduleModule { }
