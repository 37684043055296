import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header'; 
import List from '@editorjs/list'; 
import RawTool from '@editorjs/raw';
import SimpleImage from '@editorjs/simple-image';
import Checklist  from '@editorjs/checklist';
import Embed from '@editorjs/embed';
import Quote from '@editorjs/quote';
import Table from '@editorjs/table';
import CodeTool from '@editorjs/code';
import Marker from '@editorjs/marker';
import Delimiter from '@editorjs/delimiter';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';
import { NoteService } from '../note.service';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'im-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, AfterViewInit {

  editor: any;

  activeNote: any;
  activeId: any;

  title: any;

  constructor(public dialog: MatDialog, public http: HttpService, private route: ActivatedRoute, private router: Router, private noteService: NoteService) { 
    this.route.paramMap.subscribe(params => {
      this.activeId = params.get('id');
      if(this.activeId) {
        this.noteService.activeNoteId.next(this.activeId);
        this.getNote(this.activeId);
      }
    });

  }

  ngOnInit() {

  }

  
  getNote(id) {
    this.http.get('user_note/'+id).subscribe((res)=>{
      this.activeNote = res.data;
      this.title = res.data.title;
      this.editorActivate();
    });
  }

  ngAfterViewInit() {
      //this.editorActivate();
  }

  save() {
    this.editor.save().then((outputData) => {
      outputData.blocks.forEach((block) => {
          if (block.type == 'embed') {
              block.data.embed = block.data.embed.replaceAll("&amp;", "&");
          }
      }) 
       let description = outputData;
       this.http.post('user_note', {description: description, title: this.title, id: this.activeNote.id}).subscribe((res)=>{
        this.activeNote = res.data;
        this.noteService.updateNoteInfo.next(this.activeNote);
       });
    }).catch((error) => {
      console.log('Saving failed: ', error)
    });
  }

  editorActivate() {
    if(this.editor  && this.editor.render) {
      if (this.activeNote && this.activeNote.description) {
        this.editor.render(this.activeNote.description);
      }
      else  { 
          this.editor.render({ blocks: []});
      }
    } else {
      this.editor = new EditorJS({
        holder: 'noteeditor',
        data: (this.activeNote && this.activeNote.description) ? this.activeNote.description : { blocks: []},
        tools:{
          header: Header, 
          marker: Marker,
          code: CodeTool,
          delimiter: Delimiter,
          list: List,
          image: SimpleImage,
          checklist: Checklist,
          embed: Embed,
          quote: Quote,
          table: Table,  
          raw: RawTool 
        }
      });
    }
    
  }

  
  copy() {

  }

  

  delete() {
    let dialogRef = this.dialog.open(DeleteNoteComponent, {
      data: { 
        id: this.activeNote.id
      },
      width: '30vw',
      minHeight: '20vh',
      panelClass: 'confirmation-popup'
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.noteService.refreshNotes.next(true);
          this.router.navigate(['../'], {relativeTo: this.route});
        }
    });
   
  }

  focusEditor() {
    this.editor.focus();
  }

  titleKeydownHandler(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      this.focusEditor();
    }
  }

}


@Component({
  selector: "note-delete",
  templateUrl: './delete.html',
})
export class DeleteNoteComponent {
  showErrorMessage: string;

  constructor(private http: HttpService, public dialogRef: MatDialogRef<DeleteNoteComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  delete () {
    this.http.post('delete/user_note', {id: this.data.id}).subscribe((res)=>{
      this.dialogRef.close(this.data.id);
     });
  }
}