<div>
  <img src="assets/images/Buy.png" class="w-full" style="height: 200px" />
  <div class="px-8 pt-4 justify-center text-center">
    This feature is only for enrolled students. <br />By clicking on the below
    button try to explore our courses.
  </div>
  <div class="px-8 py-4 flex justify-center">
    <button
      mat-stroked-button
      class="letter-spacing-15 text-uppercase flex mr-4"
      color="course-button"
      (click)="cancel()"
    >
      CANCEL
    </button>
    <button
      mat-raised-button
      class="letter-spacing-15 text-uppercase flex"
      (click)="explore()"
      color="primary"
    >
      EXPLORE NOW
    </button>
  </div>
</div>
