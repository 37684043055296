<div>
  <h2 class="px-4 mt-4">Create User</h2>
  <form [formGroup]="createUserGroup" class="p-4 flex flex-column">
    <mat-form-field appearance="outline" class="mt-2 w-3/4">
      <input matInput placeholder="Enter user name" formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="mt-2 w-3/4">
      <input
        matInput
        placeholder="Enter email address"
        formControlName="email"
        type="email"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="mt-2 w-3/4">
      <input
        matInput
        placeholder="Enter Mobile Number"
        formControlName="mobile_number"
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="mt-2 course-selection w-3/4">
      <mat-select placeholder="Select Course" formControlName="course">
        <mat-option
          value="{{ course.id }}"
          *ngFor="let course of originalCourses"
          >{{ course.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="mt-2 course-selection w-3/4">
      <mat-select placeholder="Select Package" formControlName="package">
        <mat-option value="{{ package.id }}" *ngFor="let package of packages"
          >{{ package.name }} - {{package.price}}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div class="actions flex">
      <button class="search-buttons" (click)="createNewUser()">Create</button>
    </div>
  </form>
</div>
