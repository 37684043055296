<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="resource-active-section" mode="over" position="end">
    <ng-container *ngIf="activeBook">
      <ng-container *ngIf="subscriptionExist || loginUser.user_type == 'trainer'">
        <ng-container *ngIf="!hideCloseButton">
          <button mat-icon-button color="warn" class="absolute right-2-rem top-1-rem cursor-pointer"
            (click)="drawer.close(); activeBook = null"
            style="z-index: 2;    background-color: rgba(244, 67, 54, 0.1);">
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>

        <div>
          <iframe [src]="activeBook.link" frameborder="0" class="presentation-wrapper xs:w-screen" allow="fullscreen"
            allowfullscreen style="width: calc(100vw - 60px); height: 100vh"></iframe>
        </div>
      </ng-container>
      <ng-container *ngIf="!subscriptionExist && loginUser.user_type == 'student'">
        <div style="background: rgba(0, 0, 0, 0.65)" class="relative overflow-hidden h-screen">
          <img [src]="activeBook.image" alt="" class="opacity-25 overflow-hidden"
            [ngStyle]="activeBook.properties?.styles" style="object-fit: cover; object-position: top" />
          <mat-card class="w-1/2 absolute top-30-% left-30-%">
            <mat-card-content>
              <div class="text-center mt-4">
                <mat-icon class="material-icons-outlined" color="warn"
                  style="font-size: 4rem; height: 4rem; width: 4rem">report_problem</mat-icon>
                <p class="text-center mt-2">
                  This feature is only for enrolled students. <br />By clicking
                  on the below button try to explore our courses.
                </p>
              </div>

              <div class="flex justify-center px-8 py-4 mt-4">
                <button mat-stroked-button class="letter-spacing-15 text-uppercase flex mr-4" color="course-button"
                  (click)="drawer.close()">
                  CANCEL
                </button>
                <button mat-raised-button class="letter-spacing-15 text-uppercase flex" (click)="exploreCourses(drawer)"
                  color="primary">
                  EXPLORE NOW
                </button>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </ng-container>
  </mat-drawer>
  <div class="book-store">
    <div class="main-wrapper">
      <div class="popular-books">
        <div class="im-bar-resources">
          <div>
            <div class="flex justify-between items-center">
              <div class="flex items-center ml-6">
                <h2 class="">Resources</h2>
              </div>
            </div>
            <nav mat-tab-nav-bar>
              <a mat-tab-link *ngFor="let link of navLinks" class="letter-spacing-15"
                [active]="activeTab?.key == link?.key" (click)="getActiveData(link)">
                {{ link.label }}
              </a>
            </nav>
          </div>
          <div class="mr-6">
            <ng-container *ngIf="loginUser.user_type == 'trainer'">
              <mat-form-field appearance="outline">
                <mat-label>Courses</mat-label>
                <mat-select [formControl]="selectedCourse" (selectionChange)="courseChange($event)">
                  <mat-option *ngFor="let course of courses" [value]="course.slug">{{ course.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="loginUser.user_type == 'student'">
              <mat-form-field appearance="outline">
                <mat-label>Courses</mat-label>
                <mat-select [formControl]="selectedCourse" (selectionChange)="courseChange($event)">
                  <mat-option *ngFor="let course of courses" [value]="course.course.slug">{{ course.course.name
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="activeCategory?.type == 'books'">
          <div class="book-cards">
            <ng-container *ngFor="let item of activeCategory?.items">
              <div class="book-card" (click)="openBook(item, drawer)">
                <div class="content-wrapper">
                  <img [src]="item.image" alt="" class="book-card-img" />
                  <div class="card-content relative">
                    <div class="book-name mb-2">{{ item.name }}</div>
                    <div class="book-by" *ngIf="item.properties?.author">
                      by
                      <span class="font-semi-bold">{{
                        item.properties?.author?.name
                        }}</span>
                    </div>
                    <div class="book-sum card-sum">
                      {{ item.short_description }}
                    </div>
                    <div class="tags-info mt-4 absolute bottom-15" *ngIf="item.properties?.tags">
                      <span *ngFor="let tag of item.properties?.tags">{{
                        tag
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="activeCategory?.type == 'downloads'">
          <div class="book-cards download-cards">
            <ng-container *ngFor="let item of activeCategory?.items">
              <div class="book-card download-card">
                <div class="content-wrapper">
                  <div class="py-4 px-2 w-full">
                    <div class="book-name flex items-center">
                      <img [src]="item.icon" alt="" width="32" height="32" class="mr-4" />
                      <span>{{ item.name }}</span>
                    </div>
                    <button mat-mini-fab class="mt-3 float-right mat-elevation-z1" (click)="downloadFile(item)">
                      <mat-icon>file_download</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</mat-drawer-container>