<ng-container *ngIf="checkingUserModels">
  <div class="flex flex-column items-center justify-center h-screen relative">
    <mat-spinner strokeWidth="3"></mat-spinner>
    <img src="assets/images/icon.png" class="absolute" width="64"> 

  </div>
</ng-container>
<ng-container *ngIf="!checkingUserModels">
<div class="im-bar" *ngIf="activeModel == ''">
  <h2 class="ml-6 mb-2">AI Labs</h2>
<nav mat-tab-nav-bar>
    <a mat-tab-link
       *ngFor="let link of navLinks"
       [routerLink]="link.path"
       routerLinkActive #rla="routerLinkActive"
       [active]="rla.isActive" class="letter-spacing-15" [routerLinkActiveOptions]="{exact:
        false}"> 
      {{link.label}}
    </a>
  </nav>
</div>
  
  <router-outlet>

  </router-outlet>
</ng-container>  