import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@core/services/auth.service';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  secondFormGroup: FormGroup;

  loginUser: any;

  enableButton: boolean = false;

  processing: boolean = false;

  showForm: boolean = true;

  previewImage: any;

  grades = [
    
    {
      value: 'Working Profissional',
      viewValue: 'Working Profissional'
    },
    {
      value: 'completed-engineering',
      viewValue: 'Completed Engineering'
    },
    {
      value: 'pursuing-engineering',
      viewValue: 'Pursuing Engineering'
    }
  ]

  constructor(private _formBuilder: FormBuilder, private http: HttpService, private authService: AuthService) { }

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    this.secondFormGroup = this._formBuilder.group({
      name: [this.loginUser.name, Validators.required],
     'subforms.0.user_meta.meta_key': ['about', Validators.required],
     'subforms.0.user_meta.meta_value': [this.loginUser.about],
      mobile_number: [this.loginUser.mobile_number, Validators.required],
      email: [this.loginUser.email, [
        Validators.required,
        Validators.email
      ]],
     'subforms.1.user_meta.meta_key': ['grade', Validators.required],
     'subforms.1.user_meta.meta_value': [this.loginUser.grade, Validators.required],
     'subforms.2.user_meta.meta_key': ['gender', Validators.required],
     'subforms.2.user_meta.meta_value': [this.loginUser.gender, Validators.required],
     'subforms.3.user_meta.meta_key': ['school', Validators.required],
     'subforms.3.user_meta.meta_value': [this.loginUser.school, Validators.required],
    });
    this.secondFormGroup.valueChanges.subscribe(x => {
      this.enableButton = true;
    })
  }

  process(){
    if(this.secondFormGroup.valid){
      this.processing = true;
      this.http.put('user/update',{...this.secondFormGroup.value}).subscribe(async (res)=>{
          this.processing = false;
          this.loginUser = await this.authService.me();
      }, (err) => {
          this.processing = false;
      })
    }
  }

  uploadSource(e){
  	this.upload(e.target.files[0]);
  }

 upload(source){
    let that = this;
  	let ajaxData = new FormData();
    ajaxData.append('file[0]',source);
    var reader = new FileReader();
    reader.onload = function(e:any) {
      that.previewImage = e.target.result;
    }
    reader.readAsDataURL(source);
    ajaxData.append('upload_path','/user');
    ajaxData.append('custom_attrs','[]');
    ajaxData.append('random_names','true');
    this.http.upload('media/upload',ajaxData).subscribe((res: any)=>{
      if (res.length == 1 && res[0].success) {
        this.http.put('user/update',{'subforms.0.user_meta.meta_key':'avatar','subforms.0.user_meta.meta_value':res[0].file_name}).subscribe(async (res)=>{
            this.processing = false;
            this.loginUser = await this.authService.me();
        }, (err) => {
            this.processing = false;
        })
      }
    });
  }

}
