<div class="schedule-section">
  <mat-sidenav-container autosize>
    <mat-sidenav-content>
      <div
        class="grid has-12-columns has-1-rows column-gap-25 lg:column-gap-15 justify-items-stretch h-screen mobile-h-screen">
        <div class="main column-start-1 row-span-1 column-end-8 xs:column-end-12">
          <router-outlet></router-outlet>
        </div>
        <div class="main column-start-9 row-span-1 column-end-12 courses-section xs:display-none">
          <div class="py-8 px-8 my-3 mx-12 notes border-radius-lg" *ngIf="loginUser.user_type == 'trainer'">
            <h2 class="text-center mb-2 letter-spacing-10">
              About CEDLEARN
            </h2>
            <p class="font-xs line-height-15rem">
              CEDLEARN is India’s First AI & Data Science focused online
              mentor-led, self-paced learning platform for students of Grade 7 &
              above with unique hybrid-learning pedagogy. We are here to
              democratize AI and our curriculum is AI and Data Science focused.
              Hybrid learning is a new way to learn the emerging technologies on
              a proven platform where the strengths of both mentor-led training
              and video-based learning are leveraged. It encapsulates
              experiential learning where learners play around the interactive
              practical learning modules, block and text coding techniques along
              with 1-on-1 interaction with the mentor. We ensure that the
              students master the basics and challenge themselves towards
              solving real-life problems using AI technologies. We empower
              schools and colleges to incorporate AI curriculum through teacher
              training and setting up AI labs.
            </p>
          </div>

          <ng-container *ngIf="loginUser.user_type == 'student'">
            <mat-accordion class="md:grid has-1-columns row-gap-20 column-gap-20 justify-items-stretch mt-6 mr-6"
              [multi]="true">
              <mat-expansion-panel expanded="true" hideToggle class="register-workshops m-0 sm:mb-4" disabled
                *ngIf="loginUser.user_type == 'student'">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <span class="font-medium font-sm">Register For Events</span>
                  </mat-panel-title>
                  <mat-panel-description> </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-container *ngIf="workshopsProcessing">
                  <div class="flex flex-column items-center justify-center h-full relative">
                    <mat-spinner strokeWidth="3"></mat-spinner>
                    <img src="assets/images/icon.png" class="absolute" width="64" />
                  </div>
                </ng-container>

                <div class="flex flex-column">
                  <div *ngFor="let workshop of workshops" class="flex my-2 px-4 py-2 cursor-pointer items-center"
                    (click)="openWorkShop(workshop)">
                    <div
                      class="mr-4 course1-text course-border-top border-radius-lg shadow-1 flex items-center flex-column"
                      style="width: 5rem; padding: 4px 2px">
                      <p class="font-xs date-prefix">
                        {{ workshop.event_start_date | date : "MMM" }}
                      </p>
                      <p class="font-bolder text-center">
                        {{ workshop.event_start_date | date : "dd" }}
                      </p>
                    </div>
                    <div>
                      <p class="font-medium">{{ workshop.name }}</p>
                      <p class="font-xs line-clamp-1 font-light mt-1">
                        {{ workshop.short_description }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="empty-state flex items-center flex-column justify-center h-full"
                  *ngIf="!workshops.length && !workshopsProcessing">
                  <mat-icon class="border-radius-full">search</mat-icon>
                  <p class="text-center mt-2 color-secondary">
                    No activities found. <br />
                    Please explore activities and enroll yourself.
                  </p>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </div>
    </mat-sidenav-content>
    <mat-sidenav #drawer mode="over" position="end" class="w-1/3 xs:w-full" (closedStart)="closedStart()">
      <im-schedule-details [activeEvent]="activeEvent" [drawer]="drawer"></im-schedule-details>
    </mat-sidenav>
  </mat-sidenav-container>
</div>