import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { HttpService } from '@core/services/http.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleService } from '../schedule.service';


@Component({
  selector: 'im-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent implements OnInit {

  selectedDay: any;

  schedules: any = [];

  processing: boolean = true;

  activeEvent: any;

  urlDate: any;

  moment = moment;

  constructor(private http: HttpService, private router: Router, private route: ActivatedRoute, private scheduleService: ScheduleService) { }

  ngOnInit() {
    this.selectedDay = moment();
    this.route.params.subscribe((res) => {
      this.urlDate = parseInt(res.date);
      if (this.urlDate) {
        this.selectedDay = moment(this.urlDate);
        console.log(this.selectedDay);
      }
      console.log(res);
    })
    this.getSchedules();
    this.scheduleService.closeDetailsEvent.subscribe((detailsInfo) => {
      this.router.navigate([], { relativeTo: this.route, queryParams: { sc: null, ref: null } });
    })
  }


  getSchedules() {
    this.http.get("user_event?date=" + this.selectedDay.format('YYYY-MM-DD')).subscribe((res) => {
      this.schedules = res.data;
      this.processing = false;
    })
  }

  onCalendarSelectedChange($event: any) {
    this.selectedDay = $event;
    this.processing = true;
    if (!this.urlDate) {
      this.router.navigate([this.selectedDay.valueOf()], { relativeTo: this.route });
    } else {
      this.router.navigate(['..', this.selectedDay.valueOf()], { relativeTo: this.route });
    }
    this.getSchedules();
  }

  eventDetails(schedule) {
    if (schedule.is_it_program == 1) {
      this.router.navigate(['/schedule/full/' + schedule.slug]);
    } else {
      this.activeEvent = schedule;
      this.scheduleService.openDetailsEvent.next({ activeEvent: this.activeEvent, status: true });
      this.router.navigate([], { relativeTo: this.route, queryParams: { sc: 'schedule', ref: this.activeEvent.id } });

    }
  }

}
