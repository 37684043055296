import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../../core/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { environment } from "@environment/environment";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "im-final-assessment",
  templateUrl: "./final-assessment.component.html",
  styleUrls: ["./final-assessment.component.scss"],
})
export class FinalAssessmentComponent implements OnInit {
  courseSlug: string;
  course: any;
  processing: boolean = false;

  navLinks = [];

  activeTab: any;

  loginUser: any;

  assessmentsData: any;

  pdfSrc = "";

  batchName = "";

  constructor(
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      }
    });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    if (this.loginUser.batch.length > 0) {
      this.batchName = this.loginUser.batch[0].batch.name;
    }
  }

  takeAction(action: any) {
    if (action.type == "external") {
      window.open(action.link, "_blank").focus();
    }
    if (action.type == "download") {
      this.downloadFile(action.link);
    }
  }

  back(): void {
    //this.location.back()
    this.router.navigate(["course", this.courseSlug]);
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get("na/course/" + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        if (!this.course.user_course && this.loginUser.user_type == "student") {
          this.router.navigate(["/course/" + this.courseSlug]);
        } else {
          this.http
            .getExternal(
              environment.storageUrl +
                "final-assessment/" +
                this.courseSlug +
                ".json?t=" +
                new Date().getTime()
            )
            .subscribe((res) => {
              this.assessmentsData = res;
              this.pdfSrc = res.pdf_link;
            });
        }
        this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }
}
