import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService } from '../auth.service';
import decode from 'jwt-decode';


@Injectable()
export class RoleGuardService implements CanActivate {


  constructor(public auth: AuthService, public router: Router) {}


  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data.expectedRole;
    const token = this.auth.getToken();
    // decode the token to get its payload
    const tokenPayload = decode(token);
    console.log(tokenPayload);
    if (
      !this.auth.isAuthenticated() || 
      !tokenPayload.scopes.includes(expectedRole)
    ) {
      this.router.navigate(['auth/start']);
      return false;
    }
    return true;
  }
  
}