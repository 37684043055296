<div class="im-bar-courses">
    <div class="flex justify-between items-center">
        <h2 class="ml-6">Courses</h2>
    </div>
    <nav mat-tab-nav-bar>
        <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.path" routerLinkActive #rla="routerLinkActive"
            [active]="rla.isActive" class="letter-spacing-15" [routerLinkActiveOptions]="{exact:
          true}">
            {{link.label}}
        </a>
    </nav>
</div>

<div class="overflow-y-auto display-block" style="height: calc(100vh - 103px);">
    <router-outlet></router-outlet>
</div>