import { Component, OnInit } from "@angular/core";
import { HttpService } from "@core/services/http.service";
import { PaymentService } from "@modules/payment/payment.service";
import { Platform } from "@angular/cdk/platform";

@Component({
  selector: "im-all",
  templateUrl: "./all.component.html",
  styleUrls: ["./all.component.scss"],
})
export class AllComponent implements OnInit {
  courses: any = [];

  processing: boolean = false;

  constructor(
    private http: HttpService,
    private paymentService: PaymentService,
    public platform: Platform
  ) {}

  ngOnInit() {
    this.processing = true;
    this.getCourses();
  }

  getCourses() {
    this.http
      .get(
        "na/course?fields=id,name,icon,properties,short_description,slug,status,cover_image,group_course"
      )
      .subscribe((res) => {
        this.courses = res.data;
        this.processing = false;
      });
  }

  buyNow(course: any) {
    course.packageType = "course";
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "Success") {
        this.getCourses();
      }
    });
  }

  upgrade(course: any) {
    course.packageType = "course";
    const dialogRef = this.paymentService.initPayment(course, true);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "Success") {
        this.getCourses();
      }
    });
  }
}
