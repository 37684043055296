<div class="h-screen mx-auto my-auto lg:w-32vw xs:w-full md:w-full">
  <ng-container *ngIf="resetProcessing">
    <div
      class="flex flex-column items-center justify-center h-full absolute w-full z-10"
    >
      <mat-spinner strokeWidth="3"></mat-spinner>
      <img src="assets/images/icon.png" class="absolute" width="64" />
    </div>
  </ng-container>
  <fieldset
    [disabled]="resetProcessing"
    [ngClass]="{
      'cursor-not-allowed opacity-50 pointer-events-none': resetProcessing,
      'im-login-center': isItComonent
    }"
    class="flex flex-column p-12 justify-center h-full xs:py-6"
  >
    <im-auth-avatar
      type="password"
      passwordId="#authPassword"
      [showPasswordCheck]="!hide"
    ></im-auth-avatar>
    <div class="mb-4 flex flex-column justify-center items-center">
      <p
        class="font-sm border pl-4 border-radius-xlg flex items-center"
        *ngIf="email"
        style="margin-top: 0.15rem"
      >
        <img src="assets/images/account_circle.svg" class="mr-2" width="18" />
        <strong class="mr-4">{{ email }}</strong>
        <button
          mat-icon-button
          aria-label="Change the user account"
          (click)="changeUser()"
          style="width: 32px; height: 32px; line-height: 32px"
        >
          <mat-icon inline="true">cancel</mat-icon>
        </button>
      </p>
    </div>
    <div class="mb-4 flex flex-column justify-center items-center">
      <h3 class="mb-2">2 Step Authorization</h3>
      <p class="px-4 py-0 xs:text-center">
        We sent the otp to the register email address
      </p>
      <a
        class="pt-2 text-right cursor-pointer mat-color-primary font-sm"
        (click)="resendOtp()"
        >Didn't Received Otp? Try Again</a
      >
    </div>
    <form>
      <div class="mb-4">
        <im-otp-field
          class="text-center"
          (onInputChange)="otpChange($event)"
          [config]="otpConfig"
        ></im-otp-field>
        <mat-error class="text-center display-block pt-2 font-xs">
          <ng-container *ngIf="otpError && !otp"
            ><strong>Please enter a otp</strong></ng-container
          >
          <ng-container *ngIf="otpError && otp"
            ><strong>Please enter a valid otp</strong></ng-container
          >
          &nbsp;
        </mat-error>
      </div>
      <mat-form-field class="w-full" appearance="outline" floatLabel="always">
        <mat-label>PASSWORD</mat-label>
        <input
          matInput
          placeholder=""
          [formControl]="passwordFormControl"
          color="#fff"
          [type]="hide ? 'password' : 'text'"
          id="authPassword"
          (keyup.enter)="next()"
          #passwordInput
          autocomplete="im-passowrd"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
          type="button"
        >
          <mat-icon inline="true">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>

        <mat-error
          *ngIf="passwordFormControl.hasError('required')"
          class="mb-2"
        >
          Password is <strong>required</strong>
        </mat-error>
        <mat-error
          *ngIf="passwordFormControl.hasError('incorrect')"
          class="mb-2"
        >
          Password is wrong. Please try again
        </mat-error>
      </mat-form-field>

      <div class="action-items flex flex-column mt-10">
        <button
          mat-stroked-button
          color="primary"
          (click)="next()"
          [disabled]="processing"
          class="w-full im-button"
          type="submit"
        >
          <div class="flex justify-between items-center">
            <span>{{ processing ? "Processing..." : "CONTINUE" }}</span>
            <mat-icon>east</mat-icon>
          </div>
        </button>
        <a
          class="mt-2 text-right cursor-pointer mat-color-primary font-sm"
          (click)="resetPassword()"
          >Don't Remember Password?</a
        >
      </div>
    </form>
  </fieldset>
</div>
