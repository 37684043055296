import { Component, OnInit, ViewChild  } from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatVerticalStepper } from '@angular/material/stepper';
import {Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpService } from '@core/services/http.service';
import { environment } from '@environment/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@core/services/auth.service';

@Component({
  selector: 'im-mysql',
  templateUrl: './mysql.component.html',
  styleUrls: ['./mysql.component.scss']
})
export class MysqlComponent implements OnInit {

  currentIndex = 0;

  editorUrl: any;

   mysqlRunningSteps = [
     {
       message: 'sudo apt install mysql-server'
     },
     {
       message: 'Reading package lists... Done'
     },
     {
       message: 'Building dependency tree'
     },
     {
       message: 'Reading state information... Done'
     },
     {
       message: 'The following additional packages will be installed: <br /> libaio1 libevent-core-2.1.6 libhtml-template-perl libssl1.1 mysql-client-5.7 mysql-client-core-5.7 mysql-common mysql-server-5.7 mysq-server-core-5.7'
     },
     {
      message: 'Suggested packages: <br /> libipc-sharedcache-perl mailx tinyca'
    },
     {
       message: 'The following NEW packages will be installed: <br /> libaio1 libevent-core-2.1.6 libhtml-template-perl libssl1.1 mysql-client-5.7 mysql-client-core-5.7 mysql-common mysql-server-5.7 mysq-server-core-5.7'
     },
     {
      message: 'The following packages will be upgraded: <br /> libssl1.1'
    },
     {
       message: '1 upgraded, 9 newly installed, 0 to remove and 601 not upgraded.'
     },
     {
        message: 'Need to get 19.1 MB/20.4 MB of archives.'
     },
     {
       message: 'Afetr this operation, 155 MB of additional disk space will be used.'
     },
     {
        message: 'Get:1 http://in.archive.ubuntu.com/ubuntu/ bionic/main amd64 mysql-common all 5.8+1.0.4 [7,308 B]'
      },
      {
        message: 'Get:2 http://in.archive.ubuntu.com/ubuntu/ bionic-updates/main amd64 libaio1 amd64 0.3.110-5ubuntu0.1 [6,476 B]'
      },
      {
        message: 'Get:3 http://in.archive.ubuntu.com/ubuntu/ bionic-updates/main amd64 mysql-client-core-5.7 amd64 5.7.29-0ubuntu0.18.04.1 [6,642 kB]'
      },
      {
        message: 'Get:4 http://in.archive.ubuntu.com/ubuntu/ bionic-updates/main amd64 mysql-client-5.7 amd64 5.7.29-0ubuntu0.18.04.1 [1,942 kB]'
      },
      {
        message: 'Get:5 http://in.archive.ubuntu.com/ubuntu/ bionic-updates/main amd64 mysql-server-core-5.7 amd64 5.7.29-0ubuntu0.18.04.1 [7,430 kB]'
      },
      {
        message: 'Get:6 http://in.archive.ubuntu.com/ubuntu/ bionic/main amd64 libevent-core-2.1-6 amd64 2.1.8-stable-4build1 [85.9 kB]'
      },
      {
        message: 'Get:7 http://in.archive.ubuntu.com/ubuntu/ bionic-updates/main amd64 mysql-server-5.7 amd64 5.7.29-0ubuntu0.18.04.1 [2,933 kB]'
      },
      {
        message: 'Get:8 http://in.archive.ubuntu.com/ubuntu/ bionic/main amd64 libhtml-template-perl all 2.97-1 [59.0 kB]'
      },
      {
        message: 'Get:9 http://in.archive.ubuntu.com/ubuntu/ bionic/main amd64 libssl1.1 amd64 1.1.1-1ubuntu2.1~18.04.5 [1,270.7 kB]'
      },
      {
        message: 'Preconfiguring packages ...'
      },
      {
        message: 'Setting up libhtml-template-perl (2.97-1) ...'
      },
      {
        message: 'Processing triggers for unreadhead (0.100.0-20) ...'
      },
      {
        message: 'Processing triggers for libc-bin (2.27-3ubuntu1) ...'
      },
      {
        message: 'Setting up libaio1:amd64 (0.3.110-5ubuntu0.1) ...'
      },
      {
        message: 'Setting up libssl1.1:amd64 (1.1.1-1ubuntu2.1~18.04.5) ...'
      },
      {
        message: 'Checking for services that may need to be restarted...done.'
      },
      {
        message: 'Checking for services that may need to be restarted...done.'
      },
      {
        message: 'Processing triggers for systemd (237-3ubuntu10.3) ...'
      },
      {
        message: 'Processing triggers for man-db (2.8.3-2) ...'
      },
      {
        message: 'Setting up mysql-client-core-5.7 (5.7.29-0ubuntu0.18.04.1) ...'
      },
      {
        message: 'Setting up mysql-server-core-5.7 (5.7.29-0ubuntu0.18.04.1) ...'
      },
      {
        message: 'Setting up mysql-client-5.7 (5.7.29-0ubuntu0.18.04.1) ...'
      },
      {
        message: 'Setting up mysql-server-5.7 (5.7.29-0ubuntu0.18.04.1) ...'
      },
      {
        message: 'update-alternatives: using /etc/mysql/mysql.cnf to provide /etc/mysql/my.cnf (my.cnf) in auto mode'
      },
      {
        message: 'Renaming removed key_buffer and myisam-recover options (if present)'
      },
      {
        message: 'Created symlink /etc/systemd/system/multi-user.target.wants/mysql.service -> /lib/systemd/system/mysql.service.'
      },
      {
        message: 'Setting up mysql-server (5.7.29-0ubuntu0.18.04.1) ...'
      },
      {
        message: 'Processing triggers for libc-bin (2.27-3ubuntu1) ...'
      },
      {
        message: 'Processing triggers for unreadhead (0.100.0-20) ...'
      },
      {
        message: 'Processing triggers for systemd (237-3ubuntu10.3) ...'
      },
   ];

  finalMessages: any = [];

  installationCompleted: boolean = false;
  configCompleted: boolean = false;

  mysqlInstallationIndex = 0;

  @ViewChild("stepper") stepper: MatVerticalStepper;

  startInstallationFlag: boolean = false;

  alreadyMysqlSetupDone: boolean = false;

  processing: boolean = false;
  formProcessing: boolean = false;
  hide: boolean = true;

  configGroup: FormGroup;
  
  loginUser: any;

  constructor(private auth: AuthService, private _formBuilder: FormBuilder, private http: HttpService,protected sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.editorUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.mysqlEditorUrl);
    this.processing = true;
    this.http.get("user/my/user_meta_other?filter=meta_key eq 'mysql_installation_started'").subscribe((res)=>{
        if (res.data && res.data.length ==1) {
            if (res.data[0].meta_value == 1) {
              this.mysqlInstallationIndex = 1;
              this.installationCompleted = false;
              this.startInstallationFlag = true;
            } else if (res.data[0].meta_value == 2) {
                this.installationCompleted = false;
                this.mysqlInstallationIndex = 1;
                this.startInstallationFlag = true;
            }
            else if (res.data[0].meta_value == 3) {
                this.alreadyMysqlSetupDone = true;
            }
        }
        this.processing = false;
    })
    this.configGroup = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  startInstallation() {
    this.http.put('user/update',{'subforms.0.user_meta.meta_key':'mysql_installation_started','subforms.0.user_meta.meta_value': 1,  'subforms.0.user_meta.profile_based': 0}).subscribe((res)=>{
    }, (err) => {
    })
    this.startInstallationFlag = true;
    const counter = interval(1000);
    counter.pipe(take(this.mysqlRunningSteps.length)).subscribe(n => {
        this.finalMessages.push(this.mysqlRunningSteps[this.currentIndex]);
        const consoleBody = document.getElementById('console__body__id');
        consoleBody.scrollTop  =  consoleBody.scrollHeight;
        if ((this.mysqlRunningSteps.length-1) == this.currentIndex) {
            this.installationCompleted = true;
            setTimeout(()=>{
              this.http.put('user/update',{'subforms.0.user_meta.meta_key':'mysql_installation_started','subforms.0.user_meta.meta_value': 2,  'subforms.0.user_meta.profile_based': 0}).subscribe((res)=>{
              }, (err) => {
              })
              this.stepper.next();
              this.mysqlInstallationIndex = 1;
            },10);
        } else {
          this.currentIndex++
        }
          ;
    });
  }

  next() {
    if(this.configGroup.valid) {
        this.formProcessing = true;
      this.http.post('extra/mysql/createUser',{...this.configGroup.value}).subscribe((res)=>{
          this.http.put('user/update',{'subforms.0.user_meta.meta_key':'mysql_installation_started','subforms.0.user_meta.meta_value': 3,  'subforms.0.user_meta.profile_based': 0}).subscribe((res)=>{
          }, (err) => {
          })
        })
    }
  }
  

}
