import { Component, OnInit } from "@angular/core";
import { HttpService } from "../../../core/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { environment } from "@environment/environment";
import { Location } from "@angular/common";

@Component({
  selector: "im-assessments",
  templateUrl: "./assessments.component.html",
  styleUrls: ["./assessments.component.scss"],
})
export class AssessmentsComponent implements OnInit {
  courseSlug: string;
  course: any;
  processing: boolean = false;

  navLinks = [];

  activeTab: any;

  loginUser: any;

  assessmentsData: any;

  activeItem: any;

  user_course_assessments: any = [];

  user_course_assessments_obj: any = {};

  quizHistory = [];

  CONGRATULATIONS = "assets/images/Congratulations.png";
  NOT_BAD = "assets/images/Not_bad.png";
  TRY_AGAIN = "assets/images/Try_again.png";

  percentage: number;
  correctAnswersCount = 0;
  totalQuestions = 0;

  constructor(
    private location: Location,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      }
    });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get("na/course/" + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        if (!this.course.user_course && this.loginUser.user_type == "student") {
          this.router.navigate(["/course/" + this.courseSlug]);
        } else {
          this.http
            .get(
              "user_course_assessments?filter=course_id eq " +
                this.course.id +
                "&order_by=modified_at&order=desc"
            )
            .subscribe((res1) => {
              this.user_course_assessments = res1.data;
              this.user_course_assessments.forEach((ucs) => {
                if (!this.user_course_assessments_obj[ucs.assessment_id])
                  this.user_course_assessments_obj[ucs.assessment_id] = [];
                this.user_course_assessments_obj[ucs.assessment_id].push(ucs);
              });
            });
          this.http
            .getExternal(
              environment.storageUrl +
                "assessments/" +
                this.courseSlug +
                ".json?t=" +
                new Date().getTime()
            )
            .subscribe((res) => {
              this.assessmentsData = res;
              this.navLinks = res.categories;
              let tempFM = this.navLinks.find(
                (it) => it.key == this.route.snapshot.queryParamMap.get("m")
              );
              if (tempFM) {
                this.getActiveData(tempFM);
              } else {
                this.getActiveData(this.navLinks[0]);
              }
            });
        }
        //this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  openQuizHistory(data, drawer) {
    this.quizHistory = data;
    this.quizHistory.forEach((item: any, index: number) => {
      this.quizHistory[index]["totalQuestions"] = 0;
      this.quizHistory[index]["correctAns"] = 0;
      if (!item.correct_answers) item.correct_answers = {};

      item.questions.modules.forEach((res: any) => {
        this.quizHistory[index]["totalQuestions"] += res.noOfQuestions;
        if (res.id in item.correct_answers)
          this.quizHistory[index]["correctAns"] += Object.values(
            item.correct_answers[res.id]
          ).length;
      });

      this.quizHistory[index]["percentage"] = Math.round(
        (100 * this.quizHistory[index]["correctAns"]) /
          this.quizHistory[index]["totalQuestions"]
      );
    });

    drawer.toggle();
  }

  getActiveData(link: any) {
    this.activeTab = link;
    this.activeItem = this.assessmentsData[link.key];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: this.activeTab.key },
    });
    console.log(this.activeItem);
  }

  takeAction(action: any) {
    if (action.type == "external") {
      this.sso(action);
    }
    if (action.type == "download") {
      this.downloadFile(action.link);
    }
  }

  sso(action: any) {
    window.open(action.link, "_blank").focus();
  }

  back(): void {
    //this.location.back()
    this.router.navigate(["course", this.courseSlug]);
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }
}
