import { Component, OnInit, Inject } from "@angular/core";
import { HttpService } from "../../../core/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { environment } from "@environment/environment";
import { DomSanitizer } from "@angular/platform-browser";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Location } from "@angular/common";

@Component({
  selector: "im-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
})
export class ProjectsComponent implements OnInit {
  courseSlug: string;
  course: any;
  processing: boolean = false;
  loginUser: any;

  navLinks = [];

  activeTab: any;

  projectsData: any = [];

  activeItem: any;

  activeUploadItem: any;

  userTaks: any = {};

  constructor(
    private location: Location,
    public dialog: MatDialog,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      }
    });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
  }

  back(): void {
    //this.location.back();
    this.router.navigate(["course", this.courseSlug]);
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get("na/course/" + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        if (!this.course.user_course && this.loginUser.user_type == "student") {
          this.router.navigate(["/course/" + this.courseSlug]);
        } else {
          this.http
            .getExternal(
              environment.storageUrl +
                "projects/" +
                this.courseSlug +
                ".json?t=" +
                new Date().getTime()
            )
            .subscribe((res) => {
              this.projectsData = res;
              this.navLinks = res.categories;
              if (this.route.snapshot.queryParamMap.get("m")) {
                let tempFM = this.navLinks.find(
                  (it) => it.key == this.route.snapshot.queryParamMap.get("m")
                );
                if (tempFM) {
                  this.getActiveData(tempFM);
                } else {
                  this.getActiveData(this.navLinks[0]);
                }
              } else {
                this.getActiveData(this.navLinks[0]);
              }
            });
        }
        //this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getActiveData(link: any) {
    this.activeTab = link;
    this.http
      .get(
        "user_tasks?filter=ref eq 'Course/Project' and status eq 2 and refId eq " +
          this.course.id +
          " and module eq '" +
          this.activeTab.label +
          "'"
      )
      .subscribe((res) => {
        res.data.forEach((element) => {
          let tempKey = element.title.split(".")[1];
          if (!this.userTaks[this.activeTab.label])
            this.userTaks[this.activeTab.label] = {};
          this.userTaks[this.activeTab.label][tempKey] = element;
        });
        this.userTaks = { ...this.userTaks };
        console.log(this.userTaks);
      });
    this.activeItem = this.projectsData[link.key];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: this.activeTab.key },
    });
  }

  takeAction(action: any) {
    if (action.type == "external") {
      window.open(action.link, "_blank").focus();
    }
    if (action.type == "download") {
      this.downloadFile(action.link);
    }
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }

  openVideo(videoid) {
    const dialogRef = this.dialog.open(PorjectVimeoModal, {
      width: "60vw",
      data: { id: videoid },
      panelClass: "projectIntroPopup",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  uploadDocument(item, assignmentFile) {
    this.activeUploadItem = item;
    assignmentFile.click();
  }

  uploadSource(e) {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      this.upload(e.target.files[0]);
    }
    e.target.value = "";

    // for (var i = 0; i < e.target.files.length; i++) {
    //   //this.sourceList.push({name: e.target.files[i].name});
    //   this.upload(e.target.files[i], (this.sourceList.length-1));
    // }
  }

  upload(source) {
    let that = this;
    let ajaxData = new FormData();
    ajaxData.append("file[0]", source);
    var reader = new FileReader();

    reader.readAsDataURL(source);
    ajaxData.append(
      "upload_path",
      "/user/projects/" +
        this.loginUser.id +
        "/" +
        this.course.id +
        "/" +
        this.activeTab.label +
        "/" +
        this.activeUploadItem.name
    );
    ajaxData.append("custom_attrs", "[]");
    ajaxData.append("random_names", "false");
    this.http.upload("media/upload", ajaxData).subscribe((res: any) => {
      if (res.length == 1 && res[0].success) {
        let task_data = {
          title: this.activeUploadItem.name + "." + this.activeUploadItem.id,
          ref: "Course/Project",
          status: 2,
          user_id: this.loginUser.id,
          refId: this.course.id,
          module: this.activeTab.label,
          upload_attacment:
            "/user/projects/" +
            this.loginUser.id +
            "/" +
            this.course.id +
            "/" +
            this.activeTab.label +
            "/" +
            this.activeUploadItem.name +
            "/" +
            res[0].file_name,
        };

        this.http.post("user_tasks", task_data).subscribe(
          (res) => {
            //this.activeUploadItem.submittedTask = res.data;
            if (!this.userTaks[this.activeTab.label])
              this.userTaks[this.activeTab.label] = {};
            this.userTaks[this.activeTab.label][this.activeUploadItem.id] =
              res.data;
            this.userTaks = { ...this.userTaks };
          },
          (err) => {}
        );
      }
    });
  }
}

@Component({
  selector: "project-vimeo-modal",
  templateUrl: "vimeo.html",
})
export class PorjectVimeoModal implements OnInit {
  url: any;

  constructor(
    protected sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<PorjectVimeoModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.playerUrl +
        "?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F" +
        this.data.id +
        "&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true"
    );
  }
}
