import { Component, OnInit } from "@angular/core";
import { AuthService } from "@core/services/auth.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpService } from "@core/services/http.service";

@Component({
  selector: "im-performance",
  templateUrl: "./performance.component.html",
  styleUrls: ["./performance.component.scss"],
})
export class PerformanceComponent implements OnInit {
  loginUser: any;

  batchname: string = "";
  url: any;
  constructor(
    private auth: AuthService,
    protected sanitizer: DomSanitizer,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.loginUser = this.auth.loginUser;
    this.getlink();
    // if (this.loginUser.user_type == "trainer" || this.loginUser.id == 2) {
    //   this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
    //     "https://meta.centralindia.cloudapp.azure.com/public/dashboard/75b729eb-4e1f-4824-9b75-6f98fba1fb35?tab=1-cumulative"
    //   );
    // } else {
    //   if (this.loginUser.batch.length > 0) {
    //     this.batchname = this.loginUser.batch[0].batch.name;

    //     this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
    //       "https://meta.centralindia.cloudapp.azure.com/public/dashboard/75b729eb-4e1f-4824-9b75-6f98fba1fb35?tab=1-cumulative&batch=" +
    //         this.batchname +
    //         "#hide_parameters=batch"
    //     );
    //     // this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
    //     //   "https://powerbi.cedlearn.com/?batch=" +
    //     //     btoa(this.batchname) +
    //     //     "&user_type=" +
    //     //     this.loginUser.user_type
    //     // );
    //   } else {
    //   }
    // }
  }

  getlink() {
    let queryParams = {};
    if (this.loginUser.user_type == "trainer" || this.loginUser.id == 2) {
      queryParams["dashboard"] = 4;
    } else {
      this.batchname = this.loginUser.batch[0].batch.name;
      queryParams["batch"] = this.batchname;
      queryParams["dashboard"] = 1;
    }
    this.http
      .getExternal("https://powerbi.cedlearn.com/", queryParams)
      .subscribe((res) => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
      });
  }
}
