import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';

@Component({
  selector: 'im-r-course-list',
  templateUrl: './r-course-list.component.html',
  styleUrls: ['./r-course-list.component.scss']
})
export class RCourseListComponent implements OnInit {

  courses: any = [];

  processing: boolean = true;

  constructor(private http: HttpService) { }

  ngOnInit() {
    this.processing = true;
    this.getCourses();
  }

  getCourses() {
    this.http.get('na/course?fields=id,name,icon,properties,short_description,slug,status,cover_image,group_course').subscribe((res) => {
      this.courses = res.data;
      this.processing = false;
    })
  }

}
