<ng-container *ngIf="!processing">
  <div class="im-bar-coder">
    <h2 class="px-4 xs:px-4">Recorded Courses</h2>
  </div>
  <div class="flex flex-wrap overflow-y-auto" style="    height: calc(100vh - 60px);">
    <div *ngFor="let course of courses" class="border w-1/5 m-4 border-radius-lg cursor-pointer"
      [routerLink]="course.slug">
      <img [src]="course.cover_image_p"
        style="height: 200px;border-top-right-radius: 0.5rem;border-top-left-radius: 0.5rem;" />
      <h3 class="p-3 font-sm font-semi-bold">
        {{ course.name }}
      </h3>
    </div>
  </div>
</ng-container>
