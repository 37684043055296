<div class="md:grid has-12-columns has-1-rows justify-items-stretch">
  <div
    class="main column-start-1 row-span-1 column-end-6 xs:display-none"
    *ngIf="!isItComonent"
  >
    <img src="assets/images/23.jpg" class="h-screen w-full" />
  </div>
  <div
    class="main column-start-7 row-span-1 column-end-12 relative"
    [ngClass]="{ 'column-start-1': isItComonent }"
  >
    <img
      src="assets/images/logotag2.png"
      style="
        width: 20%;
        object-fit: contain;
        float: right;
        position: absolute;
        right: 4%;
        top: 2%;
      "
      *ngIf="!isItComonent"
    />
    <div
      [@routeAnimations]="getRouteAnimationData(outlet)"
      class="w-full h-screen relative overflow-hidden"
    >
      <div>
        <router-outlet #outlet="outlet"> </router-outlet>
      </div>
    </div>
  </div>

  <!-- <div class="main column-start-5  row-span-1 column-end-12 h-screen xs:display-none">
        <swiper>
          <img src="assets/images/1.jpeg" class="h-screen w-full"/>
          <img src="assets/images/2.jpeg" class="h-screen w-full"/>
          <img src="assets/images/5.jpeg" class="h-screen w-full"/>
        </swiper>
          
      </div> -->
</div>
