<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="over" position="end">
    <div
      style="background-color: white; width: 80vw"
      class="jupiter-resources overflow-y-auto h-screen"
    >
      <mat-toolbar>
        <div class="flex justify-between items-center w-full">
          <h2 class="">Data Sets</h2>
          <a (click)="drawer.toggle()" class="cursor-pointer">
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
              style="width: 24px"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </a>
        </div>
      </mat-toolbar>

      <div class="book-cards download-cards relative">
        <ng-container *ngFor="let item of dataSets?.items">
          <div class="book-card download-card relative">
            <div class="content-wrapper">
              <div class="py-4 px-2 w-full">
                <div class="book-name flex items-center">
                  <img
                    [src]="item.icon"
                    alt=""
                    width="32"
                    height="32"
                    class="mr-4"
                  />
                  <span>{{ item.name }}</span>
                </div>
                <button
                  mat-mini-fab
                  class="mat-elevation-z1 absolute right-15-px bottom-15-px"
                  (click)="downloadFile(item.link)"
                >
                  <mat-icon>file_download</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-drawer>
  <div class="im-bar-jupiter" *ngIf="course">
    <div class="flex justify-between items-center">
      <div class="flex items-center ml-6">
        <button
          mat-icon-button
          class="mr-3"
          (click)="back()"
          style="
            border: 1px solid;
            width: 32px;
            height: 32px;
            line-height: 32px;
          "
        >
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <h2 class="">{{ course?.name }} - Jupyter Notebooks</h2>
      </div>
    </div>
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let link of navLinks"
        class="letter-spacing-15"
        [active]="activeTab?.key == link?.key"
        (click)="getActiveData(link)"
      >
        {{ link.label }}
      </a>
    </nav>
  </div>

  <div
    class="overflow-y-auto display-block pb-6"
    style="height: calc(100vh - 103px)"
  >
    <div class="jupiter-items-section flex mx-8 flex-wrap mt-8">
      <ng-container *ngFor="let item of activeItem?.items">
        <div class="item">
          <mat-card
            class="mat-card border-radius-lg"
            [style.background]="item.background"
          >
            <mat-card-content>
              <div class="absolute right-10-px top-10-px">
                <mat-chip-list aria-label="model tags">
                  <mat-chip color="primary" selected>{{
                    item.properties?.tags
                  }}</mat-chip>
                </mat-chip-list>
              </div>
              <div class="icon-info">
                <div class="border-radius-full">
                  <img [src]="item.icon" width="32" height="32" />
                </div>
              </div>
              <h4 class="mt-2 font-medium ml-2">{{ item.name }}</h4>

              <ng-container *ngIf="item.type == 'download'">
                <button
                  mat-mini-fab
                  class="mt-3 float-right mat-elevation-z1 absolute right-15-px bottom-15-px"
                  (click)="takeAction(item)"
                >
                  <mat-icon>file_download</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="item.type == 'external'">
                <button
                  mat-mini-fab
                  class="mt-3 float-right mat-elevation-z1 absolute right-15-px bottom-15-px"
                  (click)="takeAction(item)"
                >
                  <mat-icon>east</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="item.type == 'datasets'">
                <button
                  mat-mini-fab
                  class="mt-3 float-right mat-elevation-z1 absolute right-15-px bottom-15-px"
                  (click)="getDataSets(item); drawer.toggle()"
                >
                  <mat-icon>east</mat-icon>
                </button>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</mat-drawer-container>
