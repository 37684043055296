<div class="im-bar-coder">
  <h2 class="px-4 xs:px-4">Community</h2>
</div>
<div class="community-wrapper">
  <div class="flex justify-between mr-4 xs:mr-0">
    <div class="w-3/4 mt-2 pl-12 xs:w-full xs:pl-0" style="max-width: 678px">
      <im-community-list></im-community-list>
    </div>
    <!-- <div class="w-1/4 xs:display-none">
      <!-- <im-leaderboard></im-leaderboard> -->
    <!-- </div>  -->
    <div class="w-2/5 xs:display-none fixed right-0" style="max-width: 480px">
      <!-- <div class="shadow-1 border-radius-lg h-auto mt-4 flex">
        <img src="assets/images/DS_Bootcamp.png" class="border-radius-lg" />
      </div> -->
      <!-- <ng-container *ngIf="loginUser.user_type == 'student'">
        <mat-accordion class="md:grid has-1-columns row-gap-20 column-gap-20 justify-items-stretch mt-6 mr-6"
          [multi]="true">
          <mat-expansion-panel expanded="true" hideToggle class="register-workshops m-0 sm:mb-4" disabled
            *ngIf="loginUser.user_type == 'student'">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="font-medium font-sm">Register For Events</span>
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <ng-container *ngIf="workshopsProcessing">
              <div class="flex flex-column items-center justify-center h-full relative">
                <mat-spinner strokeWidth="3"></mat-spinner>
                <img src="assets/images/icon.png" class="absolute" width="64" />
              </div>
            </ng-container>

            <div class="flex flex-column">
              <div *ngFor="let workshop of workshops" class="flex my-2 px-4 py-2 cursor-pointer items-center"
                (click)="openWorkShop(workshop)">
                <div class="mr-4 course1-text course-border-top border-radius-lg shadow-1 flex items-center flex-column"
                  style="width: 5rem; padding: 4px 2px">
                  <p class="font-xs date-prefix">
                    {{ workshop.event_start_date | date : "MMM" }}
                  </p>
                  <p class="font-bolder text-center">
                    {{ workshop.event_start_date | date : "dd" }}
                  </p>
                </div>
                <div>
                  <p class="font-medium">{{ workshop.name }}</p>
                  <p class="font-xs line-clamp-1 font-light mt-1">
                    {{ workshop.short_description }}
                  </p>
                </div>
              </div>
            </div>

            <div class="empty-state flex items-center flex-column justify-center h-full"
              *ngIf="!workshops.length && !workshopsProcessing">
              <mat-icon class="border-radius-full">search</mat-icon>
              <p class="text-center mt-2 color-secondary">
                No activities found. <br />
                Please explore activities and enroll yourself.
              </p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container> -->

      <mat-accordion class="md:grid has-1-columns row-gap-20 column-gap-20 justify-items-stretch mt-6 mr-6"
        [multi]="true">
        <mat-expansion-panel expanded="true" hideToggle class="youtube-videos m-0 sm:mb-4" disabled>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span class="font-medium font-sm letter-spacing-15">HACKATHONS</span>
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>



          <div class="flex flex-column">
            <div *ngFor="let video of videos" class="p-4">
              <mat-card class="example-card mb-4">
                <!-- <mat-card-header>
                  <div mat-card-avatar class="example-header-image" style="    background-size: 64%;
                    background-image: url(/assets/images/logo2.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: #f2f4f6;">
                  </div>
                  <mat-card-title style="font-size: 14px;letter-spacing: 2px;">CEDLEARN</mat-card-title>
                  <mat-card-subtitle>{{moment(video.snippet.publishedAt).fromNow()}}</mat-card-subtitle>
                </mat-card-header> -->

                <iframe [src]='getYoutubeUrl(video.id.videoId)' frameborder='0' class="mat-card-image"
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowfullscreen style="height: 260px;"></iframe>

                <!-- <img mat-card-image style="max-width: calc(100% + 32px);" [src]="video.snippet.thumbnails.high.url"
                  alt=""> -->
                <mat-card-content>
                  <p class="font-semi-bold">
                    {{video.snippet.title}}
                  </p>
                </mat-card-content>

              </mat-card>
            </div>
          </div>


        </mat-expansion-panel>
      </mat-accordion>


    </div>
  </div>
</div>
