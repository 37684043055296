import { Component, OnInit } from "@angular/core";
import { HttpService } from "@core/services/http.service";
import { ProfilePageService } from "../profile.service";

@Component({
  selector: "im-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  courses: any = [];

  user_projects: any = [];

  processing: boolean = false;

  constructor(
    private http: HttpService,
    private profilePageService: ProfilePageService
  ) {}

  ngOnInit() {
    this.processing = true;
    this.getCourses();
    this.getUserProjects();
  }
  openProjectDetails(data) {
    this.profilePageService.initiateProject.next({ data: data });
  }
  getCourses() {
    this.http.get("user/my/user_courses").subscribe((res) => {
      this.courses = res.data;
      this.processing = false;
    });
  }

  getUserProjects() {
    this.http.get("user_project_students").subscribe((res) => {
      this.user_projects = res.data;
    });
  }
}
