import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../../../core/services/http.service';

@Component({
  selector: 'codelab-quiz-intro',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent {

  courseSlug: string;
  moduleSlug: string;
  chapterSlug: string;
  topicSlug: string;
  course: any;
  processing: boolean = false;
  module: any;
  chapter: any;
  activeTopic: any;
  quizHistory:any = [];
  Math = Math;

  constructor(private router: Router, private http: HttpService, private route: ActivatedRoute) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('slug')) {
      this.courseSlug = this.route.snapshot.paramMap.get('slug');
      this.moduleSlug = this.route.snapshot.paramMap.get('moduleSlug');
      this.chapterSlug = this.route.snapshot.paramMap.get('chapterSlug');
      this.topicSlug = this.route.snapshot.paramMap.get('topicSlug');
      this.getCourse();
    }
  }

  getQuizQuestions() {
    return this.http.get('course_questions?filter="refId eq '+this.activeTopic.id+' and ref eq \'ChapterTopic\' and status eq 1"&order_by=order');
  }

  getQuizHistory() {
    let query;
    if(this.topicSlug) {
      query = this.http.get('user_quiz?filter="topic_id eq '+this.activeTopic.id+'"&order_by=created_at');
    } else if(this.moduleSlug) {
      query = this.http.get('user_quiz?filter="module_id eq '+this.activeTopic.id+' and topic_id eq null "&order_by=created_at');
    }
    else if(this.courseSlug) {
      query = this.http.get('user_quiz?filter="course_id eq '+this.activeTopic.id+' and topic_id eq null and module_id eq null"&order_by=created_at');
    }
    if(query) {
      query.subscribe((res)=>  {
        this.quizHistory = res.data;
      });
    }

  }

  goToResult(item) {
    this.router.navigate(['../results/'+btoa(item.id)],{relativeTo: this.route});

  }

  startQuiz() {
    this.getQuizQuestions().subscribe((res)=>{
      console.log(res);
      this.router.navigate(['../question/1'],{relativeTo: this.route, state: {course: this.course, module: this.module, chapter: this.chapter, topic: this.activeTopic, questions: res.data}});
    });
  }

  getCourse() {
    this.processing = true;
      this.http.get('course/'+this.courseSlug+'?fields=id,name,properties,icon,slug').subscribe((res)=>{
        this.course = res.data;
        this.getModule();
        this.getChapter();
        //this.processing = false;
      }, (err) => {
        this.processing = false;
      })
  }

  getModule() {
    this.processing = true;
      this.http.get('course_module/'+this.moduleSlug+'?fields=id,name,properties,slug&filter=course_id eq '+this.course.id).subscribe((res)=>{
        this.module = res.data;
        //this.processing = false;
      }, (err) => {
        this.processing = false;
      })
  }

  getChapter() {
    this.processing = true;
      this.http.get('course_chapter/'+this.chapterSlug+'?recursive=-1&fields=id,title,properties,slug&filter=course_id eq '+this.course.id).subscribe((res)=>{
        this.chapter = res.data;
        this.getChapterTopics();
        this.processing = false;
      }, (err) => {
        this.processing = false;
      })
  }

  getChapterTopics() {
    this.processing = true;
      this.http.get('course_topic/'+this.topicSlug+'?recursive=0&fields=id,name,properties,slug&filter=course_id eq '+this.course.id).subscribe((res)=>{
        this.activeTopic = res.data;
        this.processing = false;
        this.getQuizHistory();
      }, (err) => {
        this.processing = false;
      })
  }

  back() {
    this.router.navigate(['/course', this.courseSlug, this.moduleSlug, this.chapterSlug], {queryParams: {topic: this.topicSlug}});
  }
}
