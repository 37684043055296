import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'im-recorded',
  templateUrl: './recorded.component.html',
  styleUrls: ['./recorded.component.scss']
})
export class RecordedComponent implements OnInit {


  processing: boolean = false;

  constructor(private location: Location, private http: HttpService, public dialog: MatDialog) { }

  ngOnInit() {
  }

  back(): void {
    this.location.back()
  }



}
