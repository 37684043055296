<div style="height: 30vh" class="py-4">
  <h2 class="mb-8">{{data.batch.name}} - User Assigning</h2>
  <mat-form-field class="w-full" appearance="outline">
    <input
      matInput
      placeholder="Students"
      aria-label="Students"
      [matAutocomplete]="auto"
      [formControl]="stateCtrl"
    />
    <button
      mat-button
      *ngIf="stateCtrl.value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="stateCtrl.setValue('')"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        *ngFor="let user of filteredUsers | async"
        [value]="user"
        [ngClass]="{'block-user': user.status == 3}"
        style="height: 65px; line-height: 24px"
      >
        <span class="font-semi-bold text-capitalize">{{user.user.name}}</span>
        (<small>{{user.user.email}}</small> |
        <small>{{user.user.mobile_number}}</small>) <br />
        <small>{{user.mcourse?.name}}</small>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    class="float-right"
    (click)="assign()"
    [disabled]="!stateCtrl.value"
  >
    Assign
  </button>
</div>
