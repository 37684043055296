import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProfileComponent } from "./profile.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { RouterModule } from "@angular/router";
import { MatTabsModule } from "@angular/material/tabs";
import { AboutComponent } from "./about/about.component";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { TransactionsComponent } from "./transactions/transactions.component";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { IntegrationsComponent } from "./integrations/integrations.component";
import { MatCardModule } from "@angular/material/card";
import { ProfilePageComponent } from "./profile-page/profile-page.component";
import { CommunityModule } from "../community";
import { TimelineComponent } from "./profile-page/timeline/timeline.component";
import { SummaryComponent } from "./profile-page/summary/summary.component";
import { JobReadinessComponent } from "./job-readiness/job-readiness.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ProjectDetailComponent } from './profile-page/project-detail/project-detail.component';

@NgModule({
  declarations: [
    ProfileComponent,
    ChangePasswordComponent,
    AboutComponent,
    TransactionsComponent,
    IntegrationsComponent,
    ProfilePageComponent,
    TimelineComponent,
    SummaryComponent,
    JobReadinessComponent,
    ProjectDetailComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTabsModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCardModule,
    CommunityModule,
    PdfViewerModule,
    MatSidenavModule,
  ],
})
export class ProfileModule {}
