<div class="im-bar-final-assessments" *ngIf="course">
  <div class="flex items-center px-4 ml-2">
    <button
      mat-icon-button
      class="mr-3"
      (click)="back()"
      style="border: 1px solid; width: 32px; height: 32px; line-height: 32px"
    >
      <mat-icon>keyboard_backspace</mat-icon>
    </button>
    <h2 class="">{{ course?.name }} - Tools</h2>
  </div>
</div>

<div
  class="overflow-y-auto display-block pb-6"
  style="height: calc(100vh - 103px)"
  *ngIf="tools.length > 0"
>
  <div class="tool-items-section flex mx-8 flex-wrap mt-8">
    <ng-container *ngFor="let item of tools">
      <div class="item">
        <mat-card
          class="mat-card border-radius-lg"
          [style.background]="item.background"
        >
          <mat-card-content>
            <div class="absolute right-10-px top-10-px">
              <mat-chip-list aria-label="model tags">
                <mat-chip color="primary" selected>{{
                  item.properties?.tags
                }}</mat-chip>
              </mat-chip-list>
            </div>
            <div class="icon-info">
              <div class="border-radius-full">
                <img [src]="item.icon" width="48" height="48" />
              </div>
            </div>
            <ng-container *ngIf="item.properties?.data">
              <ul class="list-unstyled">
                <li
                  *ngFor="let d of item.properties?.data; let i = index"
                  class="flex flex-column m-2"
                >
                  <span class="font-xs">{{ d.title }}</span>
                  <div class="flex items-center">
                    <span class="font-semi-bold mr-2" id="dval{{ i }}">{{
                      d.value
                    }}</span>
                    <ng-container *ngIf="d.type == 'download'">
                      <mat-icon
                        (click)="takeAction(d)"
                        style="transform: scale(0.85)"
                        class="cursor-pointer"
                        >file_download</mat-icon
                      >
                    </ng-container>
                    <ng-container *ngIf="d.copy">
                      <mat-icon
                        class="cursor-pointer"
                        style="transform: scale(0.7)"
                        (click)="copy(d.value)"
                        >content_copy</mat-icon
                      >
                    </ng-container>
                  </div>
                </li>
              </ul>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="loginUser.batch.length == 0 && loginUser.id != 2">
  <div
    class="overflow-y-auto display-block pb-6"
    style="height: calc(100vh - 103px)"
  >
    <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
      <mat-icon class="border-radius-full">search</mat-icon>
      <p class="text-center mt-2 font-base color-secondary">
        No Batch assigned yet. <br />Please explore by click on the below
        button.
      </p>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        routerLink="/courses"
      >
        Explore
      </button>
    </div>
  </div>
</ng-container>
