<div class="im-bar-final-assessments" *ngIf="course">
  <div class="flex items-center px-4 ml-2 justify-between">
    <!-- <h2>Welcome, {{ loginUser?.name }}</h2> -->
    <div class="flex">
      <button mat-icon-button class="mr-3" (click)="back()"
        style="border: 1px solid; width: 32px; height: 32px; line-height: 32px">
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
      <h2 class="">{{ course?.name }} - Final Assessment</h2>
    </div>
    <div>
      <div class="border p-2 border-radius-md">
        <h3 class="text-uppercase letter-spacing-15">{{batchName}}</h3>
      </div>
    </div>
  </div>
</div>

<div class="overflow-y-auto display-block" style="height: calc(100vh - 74px)" *ngIf="assessmentsData?.enable">
  <div class="flex">
    <div class="left w-3/4 shadow-2" style="border: 3px solid aliceblue; border-radius: 8px">
      <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false"
        style="width: 400px; height: 500px"></pdf-viewer>
    </div>
    <div class="right w-1/4">
      <div class="flex flex-wrap flex-column" *ngIf="assessmentsData.other_sources.length > 0">
        <h3 class="px-2 py-2 letter-spacing-15 font-semi-bold" style="background: aliceblue;margin-left: 3px;">RESOURCES
        </h3>
        <ng-container *ngFor="let item of assessmentsData.other_sources">
          <div class="item mt-4 mx-2">
            <mat-card class="mat-card border-radius-lg" style="height: 220px">
              <mat-card-content>
                <div class="icon-info">
                  <div class="border-radius-full">
                    <img [src]="item.icon" width="32" height="32" />
                  </div>
                </div>
                <h3>{{ item.name }}</h3>
                <p class="mt-1 line-clamp-3 color-secondary font-xs" style="height: 4.2em">
                  {{ item.short_description }}
                </p>
                <ng-container *ngIf="item.type == 'download'">
                  <button mat-mini-fab class="mt-3 float-right mat-elevation-z1" (click)="takeAction(item)">
                    <mat-icon>file_download</mat-icon>
                  </button>
                </ng-container>
                <ng-container *ngIf="item.type == 'external'">
                  <button mat-mini-fab class="mt-3 float-right mat-elevation-z1" (click)="takeAction(item)">
                    <mat-icon>east</mat-icon>
                  </button>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>

      <div class="py-4 px-4 my-1 mx-2 notes border-radius-lg">
        <h2 class="mb-2 letter-spacing-10">
          Submission
        </h2>
        <div class="font-xs line-height-15rem">
          <p>After completing your assessment, kindly send it to <a style="color: #2964aa;
    font-weight: bold;">training@ceduratech.com</a>. When submitting, please ensure
            that your full name and batch number are included in the subject line of the email for proper
            identification.
          <p>

          <p class="font-semi-bold">Additionally, please format your answer document as follows:</p>
          <ul>
            <li>Use a Word document.</li>
            <li>Clearly mention the question number followed by your answer for each question.</li>
            <li>Ensure your answers are well-organized and easy to read, making it simple for us to review.</li>
          </ul>
          <p>This will help streamline the review process and ensure your submission is properly evaluated.</p>

        </div>
      </div>

    </div>
  </div>
</div>

<div class="overflow-y-auto" style="
    height: calc(100vh - 103px);
    display: flex !important;
    align-items: center;
    justify-content: center;
  " *ngIf="!assessmentsData?.enable && !processing">
  <div class="empty-state flex items-center flex-column justify-center h-full">
    <mat-icon class="border-radius-full">assignment</mat-icon>
    <h4 class="mt-2">Not yet Activated.</h4>
    <p class="text-center mt-2 color-secondary">
      Final Assessment would be enabled once you complete the required course
      modules.
    </p>
  </div>
  <!-- <h2 style="color: red">Paper Will be published at 1:00 PM</h2> -->
</div>