<mat-drawer-container class="example-container" autosize>
  <mat-drawer
    #drawer
    class="example-sidenav"
    mode="over"
    position="end"
    (closedStart)="drawerCloseEvent($event)"
  >
    <div style="background-color: white; width: 100vw">
      <ng-container *ngIf="activeItem">
        <button
          mat-icon-button
          color="warn"
          class="absolute right-1-rem top-1-rem cursor-pointer"
          (click)="drawer.close()"
          style="z-index: 2"
        >
          <mat-icon>close</mat-icon>
        </button>
        <pdf-viewer
          [src]="activeItem.link"
          [render-text]="true"
          [original-size]="true"
          [fit-to-page]="true"
        ></pdf-viewer>
      </ng-container>
    </div>
    <div
      style="background-color: white; width: 80vw"
      class="assignment-resources overflow-y-auto h-screen"
      *ngIf="dataSets?.items.length > 0"
    >
      <mat-toolbar>
        <div class="flex justify-between items-center w-full">
          <h2 class="">Data Sets</h2>
          <a (click)="drawer.toggle()" class="cursor-pointer">
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
              style="width: 24px"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </a>
        </div>
      </mat-toolbar>

      <div class="book-cards download-cards relative">
        <ng-container *ngFor="let item of dataSets?.items">
          <div class="book-card download-card relative">
            <div class="content-wrapper">
              <div class="py-4 px-2 w-full">
                <div class="book-name flex items-center">
                  <img
                    [src]="item.icon"
                    alt=""
                    width="32"
                    height="32"
                    class="mr-4"
                  />
                  <span>{{ item.name }}</span>
                </div>
                <button
                  mat-mini-fab
                  class="mat-elevation-z1 absolute right-15-px bottom-15-px"
                  (click)="downloadFile(item.link)"
                >
                  <mat-icon>file_download</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-drawer>

  <div class="im-bar-assignment" *ngIf="course">
    <div class="flex justify-between items-center">
      <div class="flex items-center ml-6">
        <button
          mat-icon-button
          class="mr-3"
          (click)="back()"
          style="
            border: 1px solid;
            width: 32px;
            height: 32px;
            line-height: 32px;
          "
        >
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <h2 class="">{{ course?.name }} - Assignments</h2>
      </div>
    </div>
    <nav mat-tab-nav-bar class="mt-2">
      <a
        mat-tab-link
        *ngFor="let link of navLinks"
        class="letter-spacing-15"
        [active]="activeTab?.key == link?.key"
        (click)="getActiveData(link)"
      >
        {{ link.label }}
      </a>
    </nav>
  </div>

  <div
    class="overflow-y-auto display-block pb-16"
    style="height: calc(100vh - 130px)"
  >
    <input
      type="file"
      name="files"
      id="file"
      style="display: none"
      #assignmentFile
      (change)="uploadSource($event)"
    />
    <ng-container *ngFor="let ch of activeCategory">
      <h3 class="mx-8 mt-8" *ngIf="!ch.disabled">
        {{ ch.chapter_title }}
      </h3>
      <div class="assignments-items-section flex mx-8 flex-wrap mt-2">
        <ng-container *ngFor="let item of ch.items">
          <div class="item" *ngIf="item.type == 'datasets'">
            <mat-card
              class="mat-card border-radius-lg"
              [style.background]="item.background"
            >
              <mat-card-content>
                <div class="absolute right-10-px top-10-px">
                  <mat-chip-list aria-label="model tags">
                    <mat-chip color="primary" selected>{{
                      item.properties?.tags
                    }}</mat-chip>
                  </mat-chip-list>
                </div>
                <div class="icon-info">
                  <div class="border-radius-full">
                    <img [src]="item.icon" width="32" height="32" />
                  </div>
                </div>
                <h4 class="mt-2 font-medium ml-2">{{ item.name }}</h4>

                <ng-container *ngIf="item.type == 'datasets'">
                  <button
                    mat-mini-fab
                    class="mt-3 float-right mat-elevation-z1 absolute right-15-px bottom-15-px"
                    (click)="getDataSets(item, drawer); drawer.toggle()"
                  >
                    <mat-icon>east</mat-icon>
                  </button>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="item" *ngIf="item.link && item.type != 'datasets'">
            <mat-card class="mat-card border-radius-lg relative">
              <mat-card-content>
                <div
                  class="absolute right-10-px top-5-px"
                  *ngIf="
                    userTaks &&
                    userTaks[activeTab.label] &&
                    userTaks[activeTab.label][item.title]
                  "
                >
                  <mat-chip-list aria-label="model tags">
                    <mat-chip color="primary" selected>{{
                      userTaks[activeTab.label][item.title].created_at
                        | date : "medium"
                    }}</mat-chip>
                  </mat-chip-list>
                </div>
                <div
                  class="icon-info"
                  [ngClass]="{
                    completed:
                      userTaks &&
                      userTaks[activeTab.label] &&
                      userTaks[activeTab.label][item.title]
                  }"
                >
                  <div class="border-radius-full">
                    {{ item.display_title || item.title }}
                  </div>
                </div>
                <h4 class="mt-2 font-medium ml-2">{{ item.topic_title }}</h4>

                <ng-container *ngIf="item.type == 'download'">
                  <div
                    class="flex justify-between items-center absolute bottom-10 px-4 left-0 w-full"
                  >
                    <ng-container
                      *ngIf="
                        userTaks &&
                        userTaks[activeTab.label] &&
                        userTaks[activeTab.label][item.title]
                      "
                    >
                      <!-- <button
                        mat-button
                        class="mt-3"
                        (click)="
                          downloadFile(
                            userTaks[activeTab.label][item.title]
                              .upload_attacment
                          )
                        "
                        color="primary"
                      >
                        <mat-icon>file_download</mat-icon> Submitted
                      </button> -->
                      <ng-container *ngIf="item.solution">
                        <button
                          mat-button
                          class=""
                          (click)="downloadFile(item.solution)"
                          color="primary"
                        >
                          <mat-icon>file_download</mat-icon> Solution
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        !(
                          userTaks &&
                          userTaks[activeTab.label] &&
                          userTaks[activeTab.label][item.title]
                        )
                      "
                    >
                      <button
                        mat-fab
                        class="mat-elevation-z1 upload-button"
                        (click)="uploadDocument(item, assignmentFile)"
                      >
                        <mat-icon>file_upload</mat-icon> Upload
                      </button>
                    </ng-container>

                    <button
                      mat-mini-fab
                      class="mat-elevation-z1"
                      (click)="downloadFile(item.link)"
                    >
                      <mat-icon>file_download</mat-icon>
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="!item.type">
                  <div
                    class="flex justify-between items-center absolute bottom-10 px-4 left-0 w-full"
                  >
                    <ng-container
                      *ngIf="
                        userTaks &&
                        userTaks[activeTab.label] &&
                        userTaks[activeTab.label][item.title]
                      "
                    >
                      <ng-container *ngIf="item.solution">
                        <button
                          mat-button
                          class=""
                          (click)="downloadFile(item.solution)"
                          color="primary"
                        >
                          <mat-icon>file_download</mat-icon> Solution
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        !(
                          userTaks &&
                          userTaks[activeTab.label] &&
                          userTaks[activeTab.label][item.title]
                        )
                      "
                    >
                      <button
                        mat-fab
                        class="mat-elevation-z1 upload-button"
                        (click)="uploadDocument(item, assignmentFile)"
                      >
                        <mat-icon>file_upload</mat-icon> Upload
                      </button>
                    </ng-container>
                    <button
                      mat-mini-fab
                      class="mat-elevation-z1"
                      (click)="activeItem = item; drawer.toggle()"
                    >
                      <mat-icon>east</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</mat-drawer-container>
