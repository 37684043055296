import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'im-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  navLinks = [
    {
      path: '/courses',
      label: 'All'
    },
    {
      path: '/courses/my',
      label: 'My Courses'
    },
    {
      path: '/courses/completed',
      label: 'Completed Courses'
    },
    {
      path: '/courses/free',
      label: 'Free Courses'
    },
    {
      path: '/courses/certificates',
      label: 'Certificates'
    }
  ];



  constructor() { }

  ngOnInit() {
  }



}
