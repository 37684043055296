<div class=" 
    md:grid 
    has-12-columns 
    has-1-rows
    justify-items-stretch h-screen sm:overflow-y-auto im-bar-h-screen">


  <div class="image-section column-start-1  row-span-1 column-end-2 py-12 items-center flex flex-column">

    <a class="cursor-pointer" (click)="profileAvatar.click()">
      <img [src]="previewImage || loginUser.avatar" width="150" height="150" class="user-upload-image" />
    </a>

    <input type="file" id="avatar" name="avatar" accept="image/png, image/jpeg" style="display: none;" #profileAvatar
      (change)="uploadSource($event)">
    <button mat-stroked-button color="primary" class="mt-3 letter-spacing-10" (click)="profileAvatar.click()">Change
      Avatar</button>

  </div>

  <div class="main column-start-3  row-span-1 column-end-12 overflow-y-auto pb-12">

    <form [formGroup]="secondFormGroup">
      <div class="pr-3 py-8 flex flex-row-reverse w-3/5 xs:w-full xs:pt-0 xs:pr-5 ">
        <button mat-raised-button color="primary" class="letter-spacing-15" [disabled]="!enableButton"
          (click)="process()">SAVE CHANGES</button>
      </div>
      <div class="flex flex-column px-12">

        <div class="w-3/5 flex justify-between xs:w-full xs:flex-column">
          <mat-form-field class="w-2/5 xs:w-full" appearance="outline" style="width: 45%;">
            <mat-label>Name</mat-label>
            <input matInput placeholder="" formControlName="name" required>
          </mat-form-field>
          <div class="w-2/5 xs:w-full xs:mb-4" style="width: 45%;">
            <label>Gender* </label>
            <br />
            <mat-radio-group formControlName="subforms.2.user_meta.meta_value" required>
              <mat-radio-button value="Male" class="mr-2 mt-2">Male</mat-radio-button>
              <mat-radio-button value="Female" class="mx-2 mt-2">Female</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>


        <div class="w-3/5 flex justify-between xs:w-full xs:flex-column">

          <mat-form-field class="w-2/5 xs:w-full" style="width: 45%;" appearance="outline" disabled="true">
            <mat-label>Email Address</mat-label>
            <input matInput placeholder="" formControlName="email" required type="email" readonly disabled="true">
          </mat-form-field>
          <mat-form-field class="w-2/5 xs:w-full" style="width: 45%;" appearance="outline">
            <mat-label>10-digit mobile number</mat-label>
            <input matInput placeholder="" formControlName="mobile_number" required>
          </mat-form-field>

        </div>

        <div class="w-3/5 flex justify-between xs:w-full xs:flex-column">
          <mat-form-field class="w-2/5 xs:w-full" style="width: 45%;" appearance="outline">
            <mat-label>Occupation</mat-label>
            <mat-select formControlName="subforms.1.user_meta.meta_value" required>
              <mat-option *ngFor="let grade of grades" [value]="grade.value">
                {{grade.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-2/5 xs:w-full" style="width: 45%;" appearance="outline">
            <mat-label>College Name</mat-label>
            <input matInput placeholder="" formControlName="subforms.3.user_meta.meta_value" required type="text">
          </mat-form-field>
        </div>

        <mat-form-field class="w-3/5 mb-2 xs:w-full" appearance="outline" hintLabel="Max 500 characters">
          <mat-label>About</mat-label>
          <textarea #about maxlength="500" matInput placeholder="" formControlName="subforms.0.user_meta.meta_value"
            rows="4" cols="10" spellcheck="false"></textarea>
          <mat-hint align="end">{{about.value?.length || 0}}/500</mat-hint>
        </mat-form-field>
      </div>

    </form>


  </div>


</div>