import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CourseComponent,
  ChapterLockStatusSheet,
  CourseIntroModal,
} from "./course.component";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { TrialComponent, BookComponent } from "./trial/trial.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import {
  ChapterComponent,
  TopicLockStatusSheet,
} from "./chapter/chapter.component";
import { RouterModule } from "@angular/router";
import { MatStepperModule } from "@angular/material/stepper";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { TextFieldModule } from "@angular/cdk/text-field";
import { PictureVideoComponent } from "../../themes/default/picture-video/picture-video.component";
import { ListComponent } from "./list/list.component";
import { MatTabsModule } from "@angular/material/tabs";
import { AllComponent } from "./list/all/all.component";
import { MyComponent } from "./list/my/my.component";
import { CompletedComponent } from "./list/completed/completed.component";
import { CertificatesComponent } from "./list/certificates/certificates.component";
import { FreeComponent } from "./list/free/free.component";
import { RecordedComponent } from "./recorded/recorded.component";
import { MatListModule } from "@angular/material/list";
import { RCourseComponent } from "./recorded/r-course/r-course.component";
import { RCourseListComponent } from "./recorded/r-course-list/r-course-list.component";
import { AssessmentsComponent } from "./assessments/assessments.component";
import { MatChipsModule } from "@angular/material/chips";
import {
  ProjectsComponent,
  PorjectVimeoModal,
} from "./projects/projects.component";
import { PreparationComponent } from "./preparation/preparation.component";
import { FinalAssessmentComponent } from "./final-assessment/final-assessment.component";

import { PdfViewerModule } from "ng2-pdf-viewer";
import { AssignmentsComponent } from "./assignments/assignments.component";

import { MatSidenavModule } from "@angular/material/sidenav";
import { JupiterNotebooksComponent } from "./jupiter-notebooks/jupiter-notebooks.component";
import { MatTreeModule } from "@angular/material/tree";

import { EmptyModule } from "../../core/ui/empty";

import { PlatformModule } from "@angular/cdk/platform";
import { ToolsAccessComponent } from "./tools-access/tools-access.component";
import { RBatchCourseComponent } from "./recorded/r-course-batch/r-batch-course.component";

@NgModule({
  declarations: [
    PorjectVimeoModal,
    PictureVideoComponent,
    CourseComponent,
    TrialComponent,
    BookComponent,
    ChapterComponent,
    ChapterLockStatusSheet,
    TopicLockStatusSheet,
    CourseIntroModal,
    ListComponent,
    AllComponent,
    MyComponent,
    CompletedComponent,
    CertificatesComponent,
    FreeComponent,
    RecordedComponent,
    RCourseComponent,
    RCourseListComponent,
    AssessmentsComponent,
    ProjectsComponent,
    PreparationComponent,
    FinalAssessmentComponent,
    AssignmentsComponent,
    JupiterNotebooksComponent,
    ToolsAccessComponent,
    RBatchCourseComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressBarModule,
    MatToolbarModule,
    RouterModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatBottomSheetModule,
    TextFieldModule,
    MatListModule,
    MatChipsModule,
    PdfViewerModule,
    MatSidenavModule,
    MatTreeModule,
    EmptyModule,
    PlatformModule,
  ],
  exports: [TrialComponent],
  entryComponents: [
    PorjectVimeoModal,
    PictureVideoComponent,
    BookComponent,
    ChapterLockStatusSheet,
    TopicLockStatusSheet,
    CourseIntroModal,
  ],
})
export class CourseModule {}
