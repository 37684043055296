<div class="notes-routing">
  <mat-drawer-container class="w-full" autosize style="background-color: #fff">
    <div class="notes-section">
      <button
        mat-icon-button
        color="primary"
        class="display-none xs:display-block absolute top-0.5-rem left-1-rem"
        (click)="drawer.toggle()"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <div class="right xs:w-full">
        <div [ngClass]="{ 'display-none': !activeId }">
          <router-outlet></router-outlet>
        </div>
        <div *ngIf="!activeId" class="p-8 xs:p-0 xs:mt-2 xs:ml-16">
          <h2 class="mb-6">Get Started</h2>
          <div style="width: 65%; margin: 0 auto" class="xs:w-full xs:mnl-8">
            <div style="padding-top: 74%; position: relative; overflow: hidden">
              <iframe
                frameborder="0"
                allowfullscreen=""
                scrolling="no"
                allow="autoplay;fullscreen"
                [src]="url"
                style="
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 0px;
                  top: 0px;
                  border-radius: 8px;
                "
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="left xs:display-none">
        <div class="head">
          <h2 style="letter-spacing: 1.5px">Notes</h2>
          <div class="actions">
            <button
              mat-button
              mat-icon-button
              matTooltip="Create a note"
              (click)="openDialog()"
            >
              <img src="assets/images/add-new.svg" />
            </button>
          </div>
        </div>

        <div class="content mt-4">
          <ng-container *ngFor="let note of notes">
            <button
              (click)="goToNote(note.id)"
              [ngClass]="{ active: activeId == note.id }"
            >
              <div class="note-item w-full">
                <mat-icon>numbers</mat-icon>
                <span>{{ note.name }}</span>
                <span
                  style="margin-left: auto"
                  class="font-xss mr-4 color-secondary"
                  >{{ moment(note.modified_at).fromNow() }}</span
                >
              </div>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <mat-drawer #drawer class="example-sidenav" mode="over">
      <div class="left" style="width: 100%">
        <div class="head">
          <h2 style="letter-spacing: 1.5px">Notes</h2>
          <div class="actions">
            <button
              mat-button
              mat-icon-button
              matTooltip="Create a note"
              (click)="openDialog()"
            >
              <img src="assets/images/add-new.svg" />
            </button>
          </div>
        </div>

        <div class="content mt-4">
          <ng-container *ngFor="let note of notes">
            <button
              (click)="goToNote(note.id)"
              [ngClass]="{ active: activeId == note.id }"
            >
              <div class="note-item w-full">
                <mat-icon>numbers</mat-icon>
                <span>{{ note.name }}</span>
                <span
                  style="margin-left: auto"
                  class="font-xss mr-4 color-secondary"
                  >{{ moment(note.modified_at).fromNow() }}</span
                >
              </div>
            </button>
          </ng-container>
        </div>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
