import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { ActivatedRoute } from '@angular/router';
import { AiService } from '../ai.service';
import { HttpService } from '@core/services/http.service';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environment/environment';
import { Location } from '@angular/common'
import { DomSanitizer } from '@angular/platform-browser';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'im-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})
export class WrapperComponent implements OnInit, OnDestroy {

  activeModelSlug: string;

  model: any;

  processing: boolean = true;

  modelConfig: any;

  Object = Object;

  userModelProperties: any = [];

  replaceVlaues = {
    user_id : '',
    model_id: '',
    storagePath: environment.storageUrl
  }

  outputs = {};

  imDynamicForms = {};

  storageUrl = environment.storageUrl;

  isString = (value)  => {
    return typeof value === 'string' || value instanceof String || this.isNumber(value);
  }
  isNumber = (value) => {
    return typeof value === 'number' && isFinite(value);
  }
  isObject = (value) => {
    return value && typeof value === 'object' && value.constructor === Object;
  }
  isArray = (value) => {
    return value && Array.isArray(value);
  }

  activeSubStepInfo: any;

  @ViewChild('stepper') private myStepper: MatStepper;

  constructor(protected sanitizer: DomSanitizer, private location: Location, private http: HttpService, private route: ActivatedRoute, private aiService: AiService, private authService: AuthService) { }

  ngOnInit() {
    this.replaceVlaues.user_id = this.authService.loginUser.id;
    this.activeModelSlug = this.route.snapshot.paramMap.get('slug');
    this.aiService.activeModel.next(this.activeModelSlug);
    this.getModel();
  }

  back(): void {
    this.location.back()
  }

  getModel(){
    this.http.get('ai_models/'+this.activeModelSlug).subscribe((res)=>{
        this.model = res.data;
        this.replaceVlaues.model_id = this.model.id;
        this.http.get('ai_models/'+this.model.id+'/userModel').subscribe((res)=> {
            this.userModelProperties = res.data;
            
            this.http.getExternal(environment.aiUrl+this.model.properties.ref_ai_model+'/model').subscribe((res)=>{
              this.modelConfig = res;
              // this.modelConfig.forEach((modelConfigSingle) => {
              //     let modelStepExist = this.userModelProperties.find(item => item.model_id == modelConfigSingle.id);
              //     if (modelStepExist) {
              //       modelConfigSingle.steps.forEach((modelConfigSingleSubStep)=> {
              //         let subStepExit = modelStepExist.find(item => item.sub_id == modelConfigSingleSubStep.id);
              //         if (subStepExit) {
              //           subStepExit.
              //         }
              //       })
              //     }
              // });
              this.processing = false;
            })
        })
    });
  }

  getPatchValues(id, modelIndex: number, stepIndex: number, imDynamicForm) {
      this.imDynamicForms[id] = imDynamicForm;
      let exist = this.userModelProperties.find(substep => substep.sub_id == id);
      if (exist) {
        this.modelConfig[modelIndex]['steps'][stepIndex]['properties'] = exist.properties;
        return exist.properties;
      } else {
          return false;
      }
  }

  ngOnDestroy() {
    this.aiService.activeModel.next('');
  }

  next(steps, mi) {
      if(steps.steps && steps.steps.length > 0) {
          let goNext = false;
          let inputExist = false;
          let inputValid = false;
          steps.steps.forEach((subStep,si) => {
            if(subStep.type == 'input') {
              inputExist = true;
              if (this.imDynamicForms[subStep.id].form && this.imDynamicForms[subStep.id].form.valid) {
                  this.submitStep(JSON.stringify(this.imDynamicForms[subStep.id].form.getRawValue()), subStep, mi, si, false);
                  goNext = true;
                  inputValid = true;
              } else {
                goNext = false;
                inputValid = false;
              }
            } else {
              goNext = true;
            }
        });
        if(goNext) {
          if(inputExist) {
            if(inputValid)  this.myStepper.next();
          } else {
            this.myStepper.next();
          }
        }
      } else {
        this.myStepper.next();
      }
    
  }

  submitStep($event, subStep, modelIndex: number, stepIndex: number, excuteFunctionRef = true) {
    $event = JSON.parse($event);
    if (subStep.outputStepRef) {
      this.outputs[subStep.outputStepRef] = '';
    }
    let url = 'user_ai_model';
    let ajaxData:any = {properties: $event, sub_id: subStep.id, ai_model_id: this.model.id, model_id: this.modelConfig[modelIndex].id};
    let userModelExistFlag = false;
    let userModelExist = -1;
    if (this.modelConfig[modelIndex]['steps'][stepIndex]['properties']) {
       userModelExist = this.userModelProperties.findIndex(item => {
          return  item.model_id == this.modelConfig[modelIndex].id && item.sub_id == this.modelConfig[modelIndex]['steps'][stepIndex].id
      });
      if (userModelExist > -1) {
        userModelExistFlag = true;
        ajaxData = {properties: $event, id: this.userModelProperties[userModelExist].id};
      }
    }
    this.http.post(url, ajaxData, {showSuccessMessage: excuteFunctionRef}).subscribe((res)=>{
        if (!userModelExistFlag) {
          this.userModelProperties.push(res.data);
        } else {
          this.userModelProperties[userModelExist].properties = $event;
        }
        this.modelConfig[modelIndex]['steps'][stepIndex]['properties'] = $event;
        if(subStep.functionRef && excuteFunctionRef) {
          let properties = {};
          this.modelConfig.forEach(lstep => {
              lstep.steps.forEach(lsubStep => {
                if (lsubStep.properties) {
                  properties[lsubStep.id] = lsubStep.properties;
                }
              });
          });
          this.http.postExternal(environment.aiUrl+this.model.properties.ref_ai_model+'/model/'+modelIndex+'/'+stepIndex+'/'+subStep.id, properties).subscribe((res)=>{
              if (subStep.outputStepRef) {
                this.outputs[subStep.outputStepRef] = res;
              }
          })
        }
    })
  }

  openSubInfo(drawer, subStep) {
    this.activeSubStepInfo=subStep.info;
    this.activeSubStepInfo['iframeUrl'] = this.sanitizer.bypassSecurityTrustResourceUrl(environment.storageUrl+this.activeSubStepInfo.url);
    drawer.toggle();
  }

}
