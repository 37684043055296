import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageComponent } from "./page/page.component";
import { HeaderComponent } from "./includes/header/header.component";
import { FooterComponent } from "./includes/footer/footer.component";
import { RoutesModule } from "./routes";

import {
  DashboardComponent,
  VimeoModal,
  BlockedCourses,
} from "./dashboard/dashboard.component";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PlatformModule } from "@angular/cdk/platform";

import { PaymentModule } from "@modules/payment";
import { CourseModule } from "@modules/course";
import { ScheduleModule } from "@modules/schedule";
import { AiEditor } from "@modules/ai-editor";
import { CoderModule } from "./coder";
import { QuizModule } from "@modules/quiz";
import { NoteModule } from "@modules/notes";
import { CommunityModule } from "@modules/community";
import { BatchModule } from "@modules/batches";
import { TaskModule } from "@modules/tasks";
import { FeedbackComponent } from "./feedback/feedback.component";
import { ContactComponent } from "./contact/contact.component";
import { PresentationsComponent } from "./presentations/presentations.component";
import { ResourceModule } from "@modules/resources";
import { PerformanceComponent } from "./performance/performance.component";

@NgModule({
  declarations: [
    BlockedCourses,
    VimeoModal,
    PageComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    FeedbackComponent,
    ContactComponent,
    PresentationsComponent,
    PerformanceComponent,
  ],
  imports: [
    CommonModule,
    RoutesModule,
    MatCardModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatTooltipModule,
    TextFieldModule,
    PaymentModule,
    MatInputModule,
    CourseModule,
    MatProgressBarModule,
    MatDialogModule,
    ScheduleModule,
    QuizModule,
    AiEditor,
    NoteModule,
    CommunityModule,
    MatProgressSpinnerModule,
    CoderModule,
    BatchModule,
    TaskModule,
    ResourceModule,
    PlatformModule,
  ],
  entryComponents: [VimeoModal, FeedbackComponent, BlockedCourses],
  bootstrap: [PageComponent],
})
export class DefaultModule {}
