<div style="background: #fff;height: 40px;display: flex;align-items: center;padding-left: 1em;font-weight: 600;">
    {{data.title}}
</div>
<div class="pip-actions">
    <button mat-icon-button aria-label="Maximize the online class" (click)="close()">
        <mat-icon class="material-icons-outlined">cancel</mat-icon>
    </button>
</div>
<ng-container *ngIf="link">
    <div style="padding-top: 56%; position: relative; overflow: hidden;"><iframe frameborder="0" allowfullscreen="" scrolling="no" allow="autoplay;fullscreen" [src]="link" style="position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;"></iframe></div>
</ng-container>
