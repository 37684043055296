<div class="im-bar-coder">
    <nav mat-tab-nav-bar >
      <a mat-tab-link
         *ngFor="let link of navLinks"
         [routerLink]="link.path"
         routerLinkActive #rla="routerLinkActive"
         [active]="rla.isActive" class="letter-spacing-15" [routerLinkActiveOptions]="{exact:
          true}"> 
        {{link.label}}
      </a>
    </nav>
  </div>

  <div class="overflow-y-auto display-block">
    <router-outlet></router-outlet>
  </div>