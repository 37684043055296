import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { ScheduleComponent } from './schedule.component';
import { WeekComponent } from './week/week.component';
import { LongComponent } from './long/long.component';

export const scheduleRoutes: Routes = [
    {
        path: "schedule/full/:event_slug", component: LongComponent, canActivate: [AuthGuard]
    },
    {
        path: "schedule", component: ScheduleComponent, canActivate: [AuthGuard], children: [
            {
                path: "", component: WeekComponent,
            },

            {
                path: ":date", component: WeekComponent,
            }

        ]

    },


];
