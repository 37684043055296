<ng-container *ngIf="loginUser.user_type == 'trainer' || loginUser.id == 2">
  <iframe
    [src]="url"
    frameborder="0"
    class="powerbi-wrapper"
    allowtransparency
  ></iframe>
</ng-container>

<ng-container *ngIf="loginUser.user_type == 'student' && loginUser.id != 2">
  <ng-container *ngIf="loginUser.batch.length > 0">
    <ng-container *ngIf="batchname">
      <iframe
        [src]="url"
        frameborder="0"
        class="powerbi-wrapper"
        allowtransparency
      ></iframe>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="loginUser.batch.length == 0 && loginUser.id != 2">
    <div class="empty-state flex items-center px-6 py-12 my-12 flex-column">
      <mat-icon class="border-radius-full">search</mat-icon>
      <p class="text-center mt-2 font-base color-secondary">
        No Batch assigned yet. <br />Please explore by click on the below
        button.
      </p>
      <button
        mat-raised-button
        color="primary"
        class="mt-2"
        routerLink="/courses"
      >
        Explore
      </button>
    </div>
  </ng-container>
</ng-container>
