<ng-container *ngIf="data">
  <mat-horizontal-stepper
    [linear]="isLinear"
    #stepper
    (selectionChange)="stepChange($event)"
    [selectedIndex]="packageIndex"
  >
    <ng-container *ngIf="loginRequired">
      <mat-step
        [stepControl]="loginDone"
        errorMessage="Registration required."
        state="imRegistration"
        [editable]="!loginUser"
      >
        <ng-template matStepLabel> Login </ng-template>
        <div>
          <im-auth-comp
            isItComonent="true"
            (onSuccess)="loginSuccess($event)"
          ></im-auth-comp>
        </div>
      </mat-step>
    </ng-container>
    <mat-step
      [stepControl]="firstFormGroup"
      errorMessage="Package is required."
      state="impackage"
    >
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>
          <ng-container *ngIf="currentStep == 0">
            Choose the Package
          </ng-container>
          <ng-container *ngIf="currentStep > 0">
            {{ activePackage.name }}
          </ng-container>
        </ng-template>
        <div class="flex justify-between xs:flex-column" *ngIf="loginUser">
          <div class="flex flex-column w-2/5 course-info xs:w-full">
            <img
              [src]="data?.properties?.payment_image || data.cover_image_p"
              height="200"
            />
            <div class="flex items-center justify-between mt-3 mb-2">
              <h3>{{ data?.name }}</h3>
              <ng-container *ngIf="!data.isItUpgrade">
                <div
                  *ngIf="data.properties && data.properties.childCourses"
                  class="flex child-course"
                >
                  <span
                    *ngFor="let childCourse of data.properties.childCourses"
                    class="flex items-center"
                  >
                    +&nbsp;<img
                      [src]="childCourse.icon_p"
                      width="24"
                      height="24"
                    />
                  </span>
                </div>
              </ng-container>
            </div>

            <div class="flex mt-1">
              <span class="font-medium font-xs"
                >{{ data.properties.duration }}
                {{ data.properties.durationUnit }}</span
              >
              <span class="dot-separator">.</span>
              <span class="font-medium font-xs"
                >{{ data.properties.sessions }} Sessions</span
              >
              <span class="dot-separator">.</span>
              <span class="font-medium font-xs"
                >{{ data.properties.projectSessions }} Projects</span
              >
            </div>
            <p class="mt-2 color-secondary font-xs">
              {{ data?.short_description }}
            </p>
          </div>
          <div
            class="flex flex-column justify-center w-1/2 items-center xs:w-full xs:mt-4"
          >
            <ng-container
              *ngIf="object.keys(packages).length == 0 && !processing"
            >
              <p class="mat-color-primary error-message">
                No packages available
              </p>
            </ng-container>
            <ng-container
              *ngFor="let package_category of object.keys(packages)"
            >
              <h4 class="w-full mb-2 font-medium">
                {{ packageCategoryTitles[package_category] }}
              </h4>
              <div class="package-category">
                <ng-container
                  *ngFor="
                    let package of packages[package_category];
                    let pi = index
                  "
                >
                  <mat-card
                    class="mat-card mat-elevation-z0 border-radius-lg course{{
                      data.id
                    }}-text course-border cursor-pointer"
                    (click)="selectPackage(package)"
                    [ngClass]="{
                      'active mat-elevation-z8':
                        activePackage?.id == package.id,
                      'mb-6': pi + 1 < packages[package_category].length
                    }"
                  >
                    <mat-card-title class="font-xl flex justify-between">
                      <span>{{ package.name }}</span>
                      <span
                        class="mat-color-primary font-semi-bold price-display"
                        >{{
                          "
                        " + package.price
                            | currency : "INR" : "symbol" : "2.0-0"
                        }}</span
                      >
                    </mat-card-title>
                    <mat-card-content>
                      <p class="font-xs color-secondary">
                        {{ package.short_description }}
                      </p>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="processing">
              <mat-spinner strokeWidth="3"></mat-spinner>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="object.keys(packages).length && !processing">
          <div class="flex justify-end pt-8">
            <button
              mat-button
              matStepperNext
              mat-stroked-button
              class="letter-spacing-15"
            >
              NEXT
            </button>
          </div>
        </ng-container>
      </form>
    </mat-step>
    <mat-step
      [stepControl]="secondFormGroup"
      errorMessage="required."
      state="imBilling"
    >
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Billing Address</ng-template>
        <div class="flex flex-column items-center" *ngIf="loginUser">
          <mat-form-field class="w-3/5 xs:w-full" appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="" formControlName="name" required />
          </mat-form-field>

          <div class="w-3/5 flex justify-between xs:w-full xs:flex-column">
            <mat-form-field
              class="w-2/5 xs:w-full"
              style="width: 45%"
              appearance="outline"
            >
              <mat-label>10-digit mobile number</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="mobile_number"
                required
                minlength="10"
                maxlength="10"
                (keypress)="onlyNumber($event)"
              />
            </mat-form-field>
            <mat-form-field
              class="w-2/5 xs:w-full"
              style="width: 45%"
              appearance="outline"
            >
              <mat-label>Pincode</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="pincode"
                required
                minlength="6"
                maxlength="6"
                (keypress)="onlyNumber($event)"
                (keyup)="checkZipCode()"
              />
            </mat-form-field>
          </div>
          <mat-form-field
            class="w-3/5 mb-2 xs:w-full xs:mb-5"
            appearance="outline"
            hintLabel="Max 500 characters"
          >
            <mat-label>Address (Area and Street)</mat-label>
            <textarea
              #address
              maxlength="500"
              matInput
              placeholder=""
              formControlName="address"
              required
              rows="4"
              cols="10"
              spellcheck="false"
            ></textarea>
            <mat-hint align="end"
              >{{ address.value?.length || 0 }}/500</mat-hint
            >
          </mat-form-field>
          <div class="w-3/5 flex justify-between xs:w-full xs:flex-column">
            <mat-form-field
              class="w-2/5 xs:w-full"
              style="width: 45%"
              appearance="outline"
            >
              <mat-label>City/District/Town</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="city_district_town"
                required
              />
            </mat-form-field>
            <mat-form-field
              class="w-2/5 xs:w-full"
              style="width: 45%"
              appearance="outline"
            >
              <mat-label>State</mat-label>
              <input matInput placeholder="" formControlName="state" required />
            </mat-form-field>
          </div>
        </div>
        <div class="flex justify-end pt-6">
          <button
            mat-button
            matStepperPrevious
            mat-stroked-button
            class="mr-4 letter-spacing-15"
          >
            BACK
          </button>
          <button
            mat-button
            matStepperNext
            mat-stroked-button
            (click)="saveBillingAddress()"
            class="letter-spacing-15"
          >
            NEXT
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step editable="false" state="imdone">
      <ng-template matStepLabel
        >{{
          !paymentStatus
            ? "Summary"
            : paymentStatus === "Initialized"
            ? "Processing"
            : "Done"
        }}
      </ng-template>
      <div
        *ngIf="
          (!paymentStatus || paymentStatus === 'Initialized') && activePackage
        "
        class="payment-summary pb-4"
      >
        <div
          class="flex justify-between items-center p-6 border-radius-lg"
          style="background: #ecf2f6"
        >
          <div class="w-1/2">
            <h2 class="course{{ data?.id }}-text font-medium">
              {{ data?.name }}
            </h2>
            <h4 class="font-medium">{{ activePackage?.name }}</h4>
            <p class="font-xs color-secondary font-light mt-1">
              {{ activePackage?.short_description }}
            </p>
          </div>
          <div class="text-center">
            <p
              *ngIf="couponInfoApply?.effected_price || isItFree"
              class="font-light price-display font-base text-line-through"
            >
              {{
                " " + activePackage.price
                  | currency : "INR" : "symbol" : "2.0-0"
              }}
            </p>
            <p
              class="mat-color-primary font-semi-bold price-display font-xl"
              *ngIf="!isItFree"
            >
              {{
                "
              " + (couponInfoApply?.effected_price || activePackage.price)
                  | currency : "INR" : "symbol" : "2.0-0"
              }}
            </p>
            <p
              class="mat-color-primary font-semi-bold price-display font-xl"
              *ngIf="isItFree"
            >
              {{ " " + 0 | currency : "INR" : "symbol" }}
            </p>
          </div>
        </div>
        <div class="flex mt-6 justify-end items-center">
          <ng-container *ngIf="!couponInfoApply?.effected_price && !isItFree">
            <ng-container *ngIf="checkingCoupon">
              <mat-spinner strokeWidth="3" diameter="50"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="!checkingCoupon">
              <mat-form-field appearance="outline" class="mr-4">
                <mat-label>Promo Code / Coupon Code</mat-label>
                <input
                  matInput
                  placeholder=""
                  #coupon
                  class="text-uppercase"
                  style="letter-spacing: 3px"
                  (keyup.enter)="couponApply(coupon.value)"
                />
                <mat-icon matSuffix class="material-icons-outlined"
                  >local_offer</mat-icon
                >
              </mat-form-field>
              <button
                type="button"
                mat-flat-button
                color="accent"
                (click)="couponApply(coupon.value)"
                style="
                  letter-spacing: 0.2em;
                  font-size: 0.7rem;
                  height: 32px;
                  line-height: 32px;
                "
              >
                APPLY
              </button>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="couponInfoApply?.effected_price || isItFree">
            <div class="flex items-center justify-between">
              <div class="flex items-center mr-8">
                <mat-icon
                  matSuffix
                  class="material-icons-outlined mr-2"
                  color="accent"
                  >local_offer</mat-icon
                >
                <div class="flex flex-column">
                  <span
                    >YAY! You saved
                    {{
                      " " +
                        (activePackage.price - couponInfoApply.effected_price)
                        | currency : "INR" : "symbol" : "2.0-0"
                    }}</span
                  >
                  <span class="font-xs font-light">Coupon Applied</span>
                </div>
              </div>

              <button
                (click)="couponInfoApply = ''; couponNumber = ''"
                mat-icon-button
              >
                <mat-icon class="material-icons-outlined" style="color: #525252"
                  >highlight_off</mat-icon
                >
              </button>
            </div>
          </ng-container>
        </div>
        <div class="flex justify-end mt-6">
          <button
            mat-button
            matStepperPrevious
            mat-stroked-button
            class="mr-4 letter-spacing-15"
          >
            BACK
          </button>
          <button
            mat-stroked-button
            color="course{{ data.id }}-button"
            class="letter-spacing-15 text-uppercase font-xss"
            (click)="initiatePaymentGateway()"
          >
            {{ isItFree ? "Enroll Now" : "Pay Now" }}
          </button>
        </div>
      </div>

      <div
        class="flex flex-column items-center justify-center pt-12 absolute w-full h-full top-0 left-0"
        *ngIf="paymentStatus === 'Initialized'"
        style="background: rgba(0, 0, 0, 0.05); z-index: 999"
      >
        <mat-spinner strokeWidth="3" class="pt-12"></mat-spinner>
        <img src="assets/images/icon.png" class="absolute" width="64" />
      </div>
      <div
        class="flex flex-column items-center py-12"
        *ngIf="paymentStatus === 'Canceled'"
      >
        <div class="border-radius-full p-3 failure-icon">
          <div class="border-radius-full flex items-center justify-center">
            <mat-icon aria-hidden="false" aria-label="Canceled icon"
              >close</mat-icon
            >
          </div>
        </div>
        <div class="flex flex-column items-center mt-4 line-height-2rem">
          <p class="font-semi-bold font-lg letter-spacing-15">
            {{ paymentOrder }}
          </p>
          <p class="font-semi-bold font-lg letter-spacing-10">Payment Failed</p>
        </div>
        <div class="mt-4">
          <button
            mat-button
            (click)="paymentStatus = ''"
            mat-stroked-button
            class="mr-4"
          >
            Retry
          </button>
          <button
            mat-button
            (click)="stepper.reset(); close()"
            mat-stroked-button
          >
            Close
          </button>
        </div>
      </div>
      <div
        class="flex flex-column items-center py-12"
        *ngIf="paymentStatus === 'Success'"
      >
        <div class="border-radius-full p-3 success-icon">
          <div class="border-radius-full flex items-center justify-center">
            <mat-icon aria-hidden="false" aria-label="Success icon"
              >check</mat-icon
            >
          </div>
        </div>
        <div class="flex flex-column items-center mt-4 line-height-2rem">
          <p class="font-semi-bold font-lg letter-spacing-15">
            {{ paymentOrder }}
          </p>
          <p class="font-semi-bold font-lg letter-spacing-10">
            Payment Successful
          </p>
        </div>
        <div class="mt-4">
          <button
            mat-button
            (click)="stepper.reset(); close()"
            mat-stroked-button
            class="letter-spacing-15"
          >
            DONE
          </button>
        </div>
      </div>
    </mat-step>
    <ng-template matStepperIcon="imRegistration">
      <mat-icon>login</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="impackage">
      <mat-icon>sell</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="imBilling">
      <mat-icon>home</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="imdone">
      <mat-icon>done_all</mat-icon>
    </ng-template>
  </mat-horizontal-stepper>
</ng-container>
