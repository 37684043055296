<div class="im-bar-preparation" *ngIf="course">
  <div class="flex justify-between items-center">
    <div class="flex items-center ml-6">
      <button
        mat-icon-button
        class="mr-3"
        (click)="back()"
        style="border: 1px solid; width: 32px; height: 32px; line-height: 32px"
      >
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
      <h2 class="">{{ course?.name }} - Preparation</h2>
    </div>
  </div>
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      class="letter-spacing-15"
      [active]="activeTab?.key == link?.key"
      (click)="getActiveData(link)"
    >
      {{ link.label }}
    </a>
  </nav>
</div>

<div class="overflow-y-auto display-block" style="height: calc(100vh - 103px)">
  <div class="assessments-items-section flex mx-8 flex-wrap mt-8">
    <div class="item flex" *ngIf="activeQuestion" style="align-items: center">
      <button
        mat-mini-fab
        color="primary"
        (click)="backQuestion()"
        *ngIf="activeQuestionIndex > 0"
      >
        <mat-icon style="margin-left: 8px">arrow_back_ios</mat-icon>
      </button>
      <div class="card-container">
        <div class="card">
          <div class="front">
            <h2 class="sub-title">Question {{ activeQuestionIndex + 1 }}</h2>
            <h1 class="title">{{ activeQuestion.title }}</h1>
            <img
              class="image"
              src="https://pre14.deviantart.net/0aef/th/pre/f/2014/015/2/f/look_at_that_thing_morty____rick_and_morty_by_kory226-d72a6ub.png"
              alt=""
            />
          </div>
          <div class="back">
            <div [innerHTML]="activeQuestion.description"></div>
          </div>
        </div>
      </div>
      <button
        mat-mini-fab
        color="primary"
        (click)="nextQuestion()"
        *ngIf="activeQuestionIndex < activeItem.items.length - 1"
      >
        <mat-icon>arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
</div>
