import { Component, OnInit } from '@angular/core';
import { HttpService } from '@core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';

@Component({
  selector: 'im-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {

  courses: any = [];

  processing: boolean = false;

  constructor(private http: HttpService, private paymentService: PaymentService) { }

  ngOnInit() {
    this.processing = true;
    this.getCourses();
  }

  getCourses() {
    this.http.get('user/my/user_completed_courses').subscribe((res) => {
      this.courses = res.data;
      this.processing = false;
    })
  }
}
