import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'im-presentations',
  templateUrl: './presentations.component.html',
  styleUrls: ['./presentations.component.scss']
})
export class PresentationsComponent implements OnInit {

  coderUrl: any;

  enableFullScreen: boolean = false;

  constructor(protected sanitizer: DomSanitizer, private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (params.slug) {
        this.coderUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.storageUrl+'trainers_presentations/'+params.slug+'/index.html');
      }
    })
   }

  ngOnInit() {
    document.addEventListener('fullscreenchange',()=>{
      if(this.isFullScreenCurrently()){
        this.enableFullScreen = true;
      }else{
           this.enableFullScreen = false;
      }
  })
  }

  isFullScreenCurrently() {
    let documentRef: any = document
    var full_screen_element = documentRef.fullscreenElement || documentRef.webkitFullscreenElement || documentRef.mozFullScreenElement || documentRef.msFullscreenElement || null;
    
    // If no element is in full-screen
    if(full_screen_element === null)
      return false;
    else
      return true;
  }
}
