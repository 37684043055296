import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "@core/services/auth.service";
import { HttpService } from "@core/services/http.service";
import moment from "moment";

@Component({
  selector: "im-community-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent implements OnInit {
  loginUser: any;

  feedList: any = [];

  content: any = "";

  moment = moment;

  @Input() user_data = 0;

  privacy: string = "PUBLIC";

  likesProcessing: any = {};

  previewImages: any = [];

  uploadImages: any = [];

  constructor(private authService: AuthService, private http: HttpService) {}

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    this.list();
  }

  list() {
    let query = "";
    if (this.user_data == 1) {
      query = "mine=1";
    }
    this.http
      .get("user_stream?limit=10&order_by=factor_weight&order=desc&" + query)
      .subscribe((res) => {
        this.feedList = res.data;
        this.feedList.forEach((element) => {
          this.http
            .post(
              "checkexist/user_stream",
              { ...element, required_comments: true },
              { showSuccessMessage: false }
            )
            .subscribe((res) => {
              element.data = res.data;
              console.log(element.data);
              element.data.stream_info.content = this.replaceURLs(
                element.data.stream_info.content
              );
            });
        });
      });
  }

  replaceURLs(message) {
    if (!message) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      if (/<a\b[^>]*>(.*?)<\/a>/i.test(hyperlink)) {
        return hyperlink;
      } else {
        return (
          '<a href="' +
          hyperlink +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      }
    });
  }

  create() {
    if (!this.content && this.uploadImages.length == 0) return;
    let data = {
      content: this.content,
      taged_users: [],
      activity_type: "user_stream",
      privacy: this.privacy,
    };
    if (this.uploadImages.length > 0) {
      data["image_details"] = this.uploadImages;
    }
    this.http.post("user_stream", data).subscribe((res) => {
      this.content = "";
      this.uploadImages = [];
      this.previewImages = [];
      res.data.stream_info.content = this.replaceURLs(
        res.data.stream_info.content
      );
      this.feedList.unshift({ data: res.data });
    });
  }

  like(post) {
    if (this.likesProcessing[post.id]) return;
    this.likesProcessing[post.id] = true;
    if (post.data.stream.is_liked) {
      let data = {
        stream_id: post.data.stream.id,
        activity_type: "stream",
      };
      this.http.post("delete/like", data).subscribe((res) => {
        post.data.stream.like.count = res.data.likes_count;
        post.data.stream.is_liked = false;
        post.data.stream.like.likes = res.data.likes;
        this.likesProcessing[post.id] = false;
      });
    } else {
      let data = {
        stream_id: post.data.stream.id,
        activity_type: "stream",
      };
      this.http.post("like", data).subscribe((res) => {
        post.data.stream.like.count = res.data.likes_count;
        post.data.stream.is_liked = true;
        post.data.stream.like.likes = res.data.likes;
        this.likesProcessing[post.id] = false;
      });
    }
  }

  comment(post, comment) {
    if (!comment.value) return;
    let data = {
      stream_id: post.data.stream.id,
      comment: comment.value,
    };
    comment.value = "";
    this.http.post("comment", data).subscribe((res) => {
      post.data.stream.total_comments = post.data.stream.total_comments + 1;
      post.data.stream.comment.count = post.data.stream.comment.count + 1;
      if (!post.data.stream.comment.comments) {
        post.data.stream.comment.comments = [];
      }
      post.data.stream.comment.comments.unshift(res.data.comment);
    });
  }

  delete(post, index) {
    let data = {
      id: post.data.stream.id,
    };
    this.http.post("delete/user_stream", data).subscribe((res) => {
      this.feedList.splice(index, 1);
    });
  }

  deleteComment(post, comment, index) {
    let data = {
      stream_id: post.data.stream.id,
      comment_id: comment.id,
    };
    this.http.post("delete/comment", data).subscribe((res) => {
      post.data.stream.total_comments = post.data.stream.total_comments - 1;
      post.data.stream.comment.count = post.data.stream.comment.count - 1;
      post.data.stream.comment.comments.splice(index, 1);
    });
  }

  uploadSource(e) {
    this.upload(e.target.files[0]);
  }

  upload(source) {
    let that = this;
    let ajaxData = new FormData();
    ajaxData.append("file[0]", source);
    var reader = new FileReader();
    reader.onload = function (e: any) {
      that.previewImages.push(e.target.result);
    };
    reader.readAsDataURL(source);
    ajaxData.append("upload_path", "/stream");
    ajaxData.append("custom_attrs", "[]");
    ajaxData.append("random_names", "true");
    this.http.upload("media/upload", ajaxData).subscribe((res: any) => {
      this.uploadImages.push(res[0]);
    });
  }

  removeImage(source, index) {
    this.previewImages.splice(index, 1);
    let data = this.uploadImages[index];
    let deleted_files = [
      {
        name: data["file_name"],
        type: "image/png",
        storage_path: "stream/" + data["file_name"],
      },
    ];
    this.http
      .upload("media/delete-file", { deleted_files: deleted_files })
      .subscribe((res: any) => {
        this.uploadImages.splice(index, 1);
      });
  }
}
