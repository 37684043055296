<mat-drawer-container autosize class="h-screen">
  <mat-drawer #drawer mode="over" class="quiz-history" position="end">
    <div class="container" style="min-width: 400px">
      <div class="milestones">
        <article *ngFor="let item of quizHistory">
          <section class="statistics border-top">
            <span class="mat-color-primary">{{
              item.created_at | date : "medium"
            }}</span>

            <div class="quiz-feedback mt-8">
              <div *ngIf="item.percentage >= 80">
                <img
                  mat-card-image
                  [src]="CONGRATULATIONS"
                  alt="Photo of Congratulations"
                />
                <h3>Great job!</h3>
              </div>
              <div *ngIf="item.percentage >= 60 && item.percentage < 80">
                <img mat-card-image [src]="NOT_BAD" alt="Photo of Not Bad" />
                <h3>Not bad!</h3>
              </div>
              <div *ngIf="item.percentage < 60">
                <img
                  mat-card-image
                  [src]="TRY_AGAIN"
                  alt="Photo of Try Again"
                />
                <h3>Try again!</h3>
              </div>
              <span
                >You scored <strong>{{ item.correctAns || 0 }}</strong> out of
                <strong>{{ item.totalQuestions }}</strong> questions
                correctly.</span
              >
              <span
                >You scored
                <strong>{{ item.percentage }}%</strong> correct.</span
              >
            </div>
          </section>
        </article>
        <div
          class="empty-state flex items-center px-6 py-12 my-12 flex-column"
          *ngIf="quizHistory.length == 0"
        >
          <mat-icon class="border-radius-full">search</mat-icon>
          <p class="text-center mt-2 font-base color-secondary">
            No History available.
          </p>
        </div>
      </div>
    </div>
  </mat-drawer>

  <div class="im-bar-assessments" *ngIf="course">
    <div class="flex justify-between items-center">
      <div class="flex items-center ml-6">
        <button
          mat-icon-button
          class="mr-3"
          (click)="back()"
          style="
            border: 1px solid;
            width: 32px;
            height: 32px;
            line-height: 32px;
          "
        >
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <h2 class="">{{ course?.name }} - Assessments</h2>
      </div>
    </div>
    <nav mat-tab-nav-bar>
      <a
        mat-tab-link
        *ngFor="let link of navLinks"
        class="letter-spacing-15"
        [active]="activeTab?.key == link?.key"
        (click)="getActiveData(link)"
      >
        {{ link.label }}
      </a>
    </nav>
  </div>

  <div
    class="overflow-y-auto display-block"
    style="height: calc(100vh - 103px)"
  >
    <div class="assessments-items-section flex mx-8 flex-wrap mt-8">
      <ng-container *ngFor="let item of activeItem?.items">
        <div class="item">
          <mat-card class="mat-card border-radius-lg">
            <mat-card-content>
              <div
                class="absolute right-10-px top-5-px"
                *ngIf="
                  user_course_assessments_obj &&
                  user_course_assessments_obj[item.id] &&
                  user_course_assessments_obj[item.id].length > 0 &&
                  user_course_assessments_obj[item.id][
                    user_course_assessments_obj[item.id].length - 1
                  ].status == 3
                "
              >
                <mat-chip-list aria-label="model tags">
                  <mat-chip color="primary" selected>{{
                    user_course_assessments_obj[item.id][
                      user_course_assessments_obj[item.id].length - 1
                    ].created_at | date : "medium"
                  }}</mat-chip>
                </mat-chip-list>
              </div>
              <div
                class="absolute right-10-px top-5-px"
                *ngIf="
                  user_course_assessments_obj &&
                  user_course_assessments_obj[item.id] &&
                  user_course_assessments_obj[item.id].length > 0 &&
                  user_course_assessments_obj[item.id][
                    user_course_assessments_obj[item.id].length - 1
                  ].status == 1
                "
              >
                <mat-chip-list aria-label="model tags">
                  <mat-chip color="primary" selected>Resume</mat-chip>
                </mat-chip-list>
              </div>
              <div class="icon-info">
                <div class="border-radius-full">
                  <img [src]="item.icon" width="32" height="32" />
                </div>
              </div>
              <h3>{{ item.name }}</h3>
              <p
                class="mt-1 line-clamp-3 color-secondary font-xs"
                style="height: 4.2em"
              >
                {{ item.short_description }}
              </p>
              <div
                class="mt-3 float-left"
                *ngIf="
                  user_course_assessments_obj &&
                  user_course_assessments_obj[item.id] &&
                  user_course_assessments_obj[item.id].length > 0
                "
              >
                <button
                  mat-raised-button
                  class="mt-3 float-right mat-elevation-z1"
                  (click)="
                    openQuizHistory(
                      user_course_assessments_obj[item.id],
                      drawer
                    )
                  "
                >
                  History
                </button>
              </div>

              <ng-container *ngIf="item.type == 'download'">
                <button
                  mat-mini-fab
                  class="mt-3 float-right mat-elevation-z1"
                  (click)="takeAction(item)"
                >
                  <mat-icon>file_download</mat-icon>
                </button>
              </ng-container>
              <ng-container *ngIf="item.type == 'external'">
                <button
                  mat-mini-fab
                  class="mt-3 float-right mat-elevation-z1"
                  (click)="takeAction(item)"
                >
                  <mat-icon>east</mat-icon>
                </button>
              </ng-container>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</mat-drawer-container>
