<div style="padding-top: 56%; position: relative; overflow: hidden">
  <iframe
    frameborder="0"
    allowfullscreen=""
    scrolling="no"
    allow="autoplay;fullscreen"
    [src]="url"
    style="position: absolute; height: 100%; width: 100%; left: 0px; top: 0px"
  ></iframe>
</div>
