<div class="flex xs:flex-column xs:h-full xs:justify-center">
  <img src="assets/images/Buy.png" class="w-1/2 h-full xs:w-full xs:h-auto" />

  <div class="flex justify-center flex-column xs:text-center xs:items-center">
    <h2>{{courses[0].mcourse.name}}</h2>
    <div class="mt-2 font-semi-bold" style="color: #f44336">
      Your payment status will show as pending while we verify your
      installments.
    </div>
    <div class="mt-2">
      To unlock your course access please contact the below mobile number
    </div>
    <a
      class="mat-raised-button mat-button-base mat-warn mt-4"
      style="width: max-content"
      href="tel:+918977944951"
      ><span>+91 - </span>8977944951</a
    >
  </div>
  <mat-dialog-actions
    align="end"
    class="display-none xs:display-block absolute bottom-30"
    style="width: 88%"
  >
    <button mat-flat-button mat-dialog-close color="warn">Cancel</button>
  </mat-dialog-actions>
</div>
