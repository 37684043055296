import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { HttpService } from '@core/services/http.service';
import { PaymentService } from '@modules/payment/payment.service';
import { ScheduleService } from './schedule.service';
import { ActivatedRoute } from '@angular/router';
import { CollectionViewer, SelectionChange, DataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { AuthService } from '@core/services/auth.service';
import moment from 'moment';

interface CourseNode {
  name: string;
  id: number;
  icon: string;
  level: number;
  children?: CourseNode[];
}

@Component({
  selector: 'im-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  @ViewChild(MatSidenav) sidenav: MatSidenav;

  processing: boolean = true;

  courses: any = [];

  originalCourses: any = [];

  activeEvent: any;

  treeControl = new NestedTreeControl<CourseNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<CourseNode>();

  hasChild = (_: number, node: CourseNode) => !!node.children && node.children.length > 0;

  subscriptionExist: boolean = false;

  lastCourse: any;

  loginUser: any;

  workshopsProcessing: boolean = true;

  workshops: any = [];


  constructor(private auth: AuthService, private route: ActivatedRoute, private http: HttpService, private paymentService: PaymentService, private scheduleService: ScheduleService) {

  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    if (this.loginUser.user_type == 'student') {
      //this.checkSubscriptionExist();
      this.paymentService.courseRefresh.subscribe((res) => {
        this.checkSubscriptionExist();
      })
      this.getWorkshops();
    }
    this.scheduleService.openDetailsEvent.subscribe((res) => {
      if (res.status) {
        this.activeEvent = res.activeEvent;
        //this.sidenav.open();
      }
    });
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.sc && queryParams.sc == 'schedule' && queryParams.ref) {
        if (this.activeEvent) {
          this.sidenav.open();
        } else {
          this.http.get("user_event/" + queryParams.ref).subscribe((res) => {
            this.activeEvent = res.data;
            this.sidenav.open();
          });
        }

      }
    });
  }

  getWorkshops() {
    this.http.get("workshops?notExists=1&filter=event_start_date ge '" + moment().format('YYYY-MM-DD') + "'").subscribe((res) => {
      this.workshopsProcessing = false;
      this.workshops = res.data;
    })
  }

  checkSubscriptionExist() {
    this.http.post('checkexist/user_course', {}).subscribe((res) => {
      this.subscriptionExist = (res.data.exist > 0) ? true : false;
      if (this.subscriptionExist) {
        this.getCourses();
      } else {
        this.getOriginalCourses();
      }
    });
  }

  getOriginalCourses() {
    this.http.get('course?fields=id,name,icon,properties,short_description,slug,status,cover_image').subscribe((res) => {
      this.originalCourses = res.data;
      this.processing = false;
    })
  }

  getCourses() {
    this.http.get('user/my/user_courses?order=course_id').subscribe((res) => {
      this.courses = res.data;
      this.lastCourse = this.courses[this.courses.length - 1];
      const tree_data: CourseNode[] = [];
      this.courses.forEach((user_course: any) => {
        const temp = {
          name: user_course.course.name,
          id: user_course.course.id,
          icon: user_course.course.icon_p,
          level: 1,
          children: [],
        };
        user_course.course.modules.forEach((module: any) => {
          const temp1 = {
            name: module.name,
            id: module.id,
            level: 2,
            children: []
          };

          module.chapter.forEach(chapter => {
            temp1.children.push({
              name: chapter.title,
              id: chapter.id,
              level: 3
            })
          });
          temp.children.push(temp1);
        })
        tree_data.push(temp);
      })
      this.dataSource.data = tree_data;
      this.treeControl.expansionModel.select(...tree_data);
      this.processing = false;
    })
  }

  buyNow(course: any) {
    course.packageType = 'course';
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.status === 'Success') {
        this.getCourses();
      }
    });
  }

  upgrade() {
    let upgradeCourseId = this.lastCourse.course_id + 1;
    if (upgradeCourseId > 3) {
      return;
    }
    this.http.get('course?filter="id eq ' + upgradeCourseId + '"&limit=1').subscribe((res) => {
      if (res.data.length) {
        let course = res.data[0];
        course.packageType = 'course';
        const dialogRef = this.paymentService.initPayment(course, true);
        dialogRef.afterClosed().subscribe(result => {
          if (result && result.status === 'Success') {
            this.getCourses();
          }
        });
      }
    })

  }

  closedStart() {
    this.scheduleService.closeDetailsEvent.next({});
    this.activeEvent = '';
  }

  openWorkShop(event) {
    this.activeEvent = event;
    this.sidenav.open();
  }




}
