import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { ListComponent } from './list.component';

export const resourceRoutes: Routes = [
    {
        path: 'resources', component: ListComponent, canActivate: [AuthGuard]
    },
    {
        path: 'resources/cheat-sheet', component: ListComponent, canActivate: [AuthGuard]
    },
    {
        path: 'resources/data-sets', component: ListComponent, canActivate: [AuthGuard]
    },
    
];


