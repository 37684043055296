import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "../../../../core/services/http.service";
import { DomSanitizer } from "@angular/platform-browser";
import { MatDialog } from "@angular/material/dialog";
import { CourseIntroModal } from "../../course.component";
import { AuthService } from "@core/services/auth.service";

@Component({
  selector: "im-r-batch-course",
  templateUrl: "./r-batch-course.component.html",
  styleUrls: ["./r-batch-course.component.scss"],
})
export class RBatchCourseComponent implements OnInit {
  courseSlug: string;
  course: any;
  processing: boolean = false;

  classes: any = [];
  loginUser: any;

  collections: any = [];

  topics: any = [];

  movies: any = {};

  activeVideo: any;
  activeMovie: any;

  movieApiProcessing: any = {};

  selectedIndex = 0;

  batchSlug = "";

  batch: any;

  constructor(
    private auth: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    protected sanitizer: DomSanitizer
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.batchSlug = params.batchId;
        this.getCourse();
      }
    });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    window.addEventListener("message", function (e) {
      if (e.data) {
        try {
          let data = JSON.parse(e.data);
          console.log(data);
          let iframe = document.querySelector('iframe[src="' + data.url + '"]');
          if (iframe) {
            if (data.ratio) {
              iframe.parentElement.style.paddingTop = data.ratio + "%";
            } else if (data.event && data.event == "pip") {
              //data.player.pause();
              // const dialogRef = that.dialog.open(PictureVideoComponent, {
              //   data: {url: data.url, title: that.activeTopic.name, cs: data.cs},
              //   panelClass: 'pip-model',
              //   disableClose: true,
              //   hasBackdrop: false,
              // });
            }
          }
        } catch (error) {}
      }
    });
  }

  back(): void {
    this.router.navigate(["course", this.courseSlug]);
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get("na/course/" + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        if (!this.course.user_course && this.loginUser.user_type == "student") {
          this.router.navigate(["/course/" + this.courseSlug]);
        } else {
          if (this.batchSlug) {
            this.http
              .get("batch/" + atob(this.batchSlug))
              .subscribe((res: any) => {
                this.batch = res.data;
                if (this.batch.properties.feed_record_collections) {
                  this.topics = Object.keys(
                    this.batch.properties.feed_record_collections
                  );
                  if (this.route.snapshot.queryParamMap.get("m")) {
                    let tempFM = this.topics.find(
                      (it) => it == this.route.snapshot.queryParamMap.get("m")
                    );
                    if (tempFM) {
                      this.selectedIndex = this.topics.findIndex(
                        (it) => it == this.route.snapshot.queryParamMap.get("m")
                      );
                      this.getShowcaseData(
                        this.batch.properties.feed_record_collections[tempFM],
                        tempFM
                      );
                    } else {
                      this.selectedIndex = 0;
                      this.getShowcaseData(
                        this.batch.properties.feed_record_collections[
                          this.topics[0]
                        ],
                        this.topics[0]
                      );
                    }
                  } else {
                    this.selectedIndex = 0;
                    this.getShowcaseData(
                      this.course.properties.feed_record_collections[
                        this.topics[0]
                      ],
                      this.topics[0]
                    );
                  }
                }
              });
          } else if (this.course.properties.feed_record_collections) {
            this.topics = Object.keys(
              this.course.properties.feed_record_collections
            );
            if (this.route.snapshot.queryParamMap.get("m")) {
              let tempFM = this.topics.find(
                (it) => it == this.route.snapshot.queryParamMap.get("m")
              );
              if (tempFM) {
                this.selectedIndex = this.topics.findIndex(
                  (it) => it == this.route.snapshot.queryParamMap.get("m")
                );
                this.getShowcaseData(
                  this.course.properties.feed_record_collections[tempFM],
                  tempFM
                );
              } else {
                this.selectedIndex = 0;
                this.getShowcaseData(
                  this.course.properties.feed_record_collections[
                    this.topics[0]
                  ],
                  this.topics[0]
                );
              }
            } else {
              this.selectedIndex = 0;
              this.getShowcaseData(
                this.course.properties.feed_record_collections[this.topics[0]],
                this.topics[0]
              );
            }
          }
          this.course["cover_image_pb"] =
            this.sanitizer.bypassSecurityTrustStyle(
              `linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url(${this.course.cover_image_p})`
            );
        }

        this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getShowcaseData(url, key) {
    this.movieApiProcessing[key] = true;
    this.http
      .post("extra/vimeo/getShowCases", { data: url })
      .subscribe((res: any) => {
        console.log(res);
        this.movies[key] = res.data.movies;
        this.movieApiProcessing[key] = false;
      });
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: key },
    });
  }

  openVideo(video) {
    this.activeMovie = video;
    this.activeVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://app.cedlearn.com/player/index.html?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F" +
        video.id +
        "&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true&speedButton=true"
    );
  }

  changeTopic($event) {
    this.activeVideo = "";
    this.activeMovie = "";
    this.getShowcaseData(
      this.batch.properties.feed_record_collections[this.topics[$event]],
      this.topics[$event]
    );
  }

  getClasses() {
    this.http
      .get("recorded_classes?filter=course_id eq " + this.course.id)
      .subscribe((res) => {
        this.classes = res.data;
        this.processing = false;
      });
  }

  play(videoId) {
    const dialogRef = this.dialog.open(CourseIntroModal, {
      width: "60vw",
      data: { properties: { introVideoId: videoId } },
      panelClass: "courseIntroPopup",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
}
