import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { HttpService } from "@core/services/http.service";
import { PaymentService } from "@modules/payment/payment.service";
import { AuthService } from "@core/services/auth.service";
import moment from "moment";
import { MatSidenav } from "@angular/material/sidenav";
import { ScheduleService } from "@modules/schedule/schedule.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { environment } from "@environment/environment";
import { Router } from "@angular/router";
import { Platform } from "@angular/cdk/platform";

@Component({
  selector: "im-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  courses: any = [];
  loginUser: any;

  schedules: any = [];

  workshops: any = [];

  schedulesProcessing: boolean = true;

  workshopsProcessing: boolean = true;

  activeEvent: any;

  processing: boolean = true;

  subscriptionExist: boolean = false;

  freeTrailRequested: boolean = false;

  process: boolean = false;

  onGoingCourses: number = 0;

  batches = [];

  @ViewChild(MatSidenav) sidenav: MatSidenav;

  batchName = "";

  constructor(
    public dialog: MatDialog,
    private http: HttpService,
    private paymentService: PaymentService,
    private auth: AuthService,
    private scheduleService: ScheduleService,
    private router: Router,
    public platform: Platform
  ) {}

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    if (this.loginUser.batch.length > 0) {
      this.batchName = this.loginUser.batch[0].batch.name;
    }
    if (this.loginUser.user_type == "student") {
      this.checkSubscriptionExist();
      this.getCourses();
      //this.getWorkshops();
    }
    if (this.loginUser.user_type == "trainer") {
      this.getTrinerCourses();
      this.getBatches();
    }
    this.getSchedules();
    if (this.loginUser.user_type == "student") {
      this.paymentService.courseRefresh.subscribe((res) => {
        //this.getCourse(res);
        this.getCourses();
      });
    }

    this.scheduleService.dashboardRefresh.subscribe((res) => {
      if (res.refresh) {
        if (this.loginUser.user_type == "student") {
          //this.getWorkshops();
        }
        this.getSchedules();
      }
    });
  }

  getCourse(course: any) {
    this.http.get("course/" + course.slug).subscribe(
      (res) => {
        const existCourseIndex = this.courses.findIndex(
          (item) => item.id == course.id
        );
        this.courses[existCourseIndex] = res.data;
      },
      (err) => {}
    );
  }

  getBatches() {
    this.http.get("batches").subscribe((res) => {
      this.batches = res.data;
    });
  }

  getTrinerCourses() {
    this.http
      .get(
        "course?fields=id,name,icon,properties,short_description,slug,status,cover_image,group_course"
      )
      .subscribe((res) => {
        this.courses = res.data;
        this.processing = false;
      });
  }

  getCourses() {
    this.http
      .get(
        "course?filter=is_it_popular eq 1&fields=id,name,icon,properties,short_description,slug,status,cover_image,group_course"
      )
      .subscribe((res) => {
        this.courses = res.data;
        this.processing = false;
      });
  }

  checkSubscriptionExist() {
    this.process = true;
    this.http.post("checkexist/user_course", {}).subscribe((res) => {
      this.onGoingCourses = res.data.exist;
      this.subscriptionExist = res.data.exist > 0 ? true : false;
      this.freeTrailRequested = res.data.free_trail_requested;
      this.process = false;
      if (res.data.blocked.length > 0) {
        const dialogRef = this.dialog.open(BlockedCourses, {
          width: "35vw",
          data: { courses: res.data.blocked },
          panelClass: "courseIntroPopup",
        });

        dialogRef.afterClosed().subscribe((result) => {
          console.log("The dialog was closed");
        });
      }
    });
  }

  getSchedules() {
    //this.http.get("user_event?date="+moment().format('YYYY-MM-DD')).subscribe((res)=>{
    this.http
      .get(
        "user_event?limit=3&from_date=" +
          moment().format("YYYY-MM-DD") +
          "&from_time=" +
          moment().format("HH:mm:ss")
      )
      .subscribe((res) => {
        this.schedulesProcessing = false;
        this.schedules = res.data;
      });
  }

  getWorkshops() {
    this.http
      .get(
        "workshops?notExists=1&filter=event_start_date gt '" +
          moment().format("YYYY-MM-DD") +
          "'"
      )
      .subscribe((res) => {
        this.workshopsProcessing = false;
        this.workshops = res.data;
      });
  }

  buyNow(course: any) {
    course.packageType = "course";
    const dialogRef = this.paymentService.initPayment(course);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "Success") {
        this.getCourses();
      }
    });
  }

  upgrade(course: any) {
    course.packageType = "course";
    const dialogRef = this.paymentService.initPayment(course, true);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "Success") {
        this.getCourses();
      }
    });
  }

  openEvent(event) {
    if (event.is_it_program == 1) {
      this.router.navigate(["/schedule/full/" + event.slug]);
    } else {
      this.activeEvent = event;
      this.sidenav.open();
    }
  }

  openWorkShop(event) {
    this.activeEvent = event;
    this.sidenav.open();
  }

  openBigBang() {
    const dialogRef = this.dialog.open(VimeoModal, {
      width: "60vw",
      data: { id: 545484402 },
      panelClass: "courseIntroPopup",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  book417() {
    if (this.subscriptionExist) {
    } else {
      this.router.navigate(["auth", "onboarding"]);
    }
  }
}

@Component({
  selector: "vimeo-modal",
  templateUrl: "vimeo.html",
})
export class VimeoModal implements OnInit {
  url: any;

  constructor(
    protected sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<VimeoModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.playerUrl +
        "?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F" +
        this.data.id +
        "&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true"
    );
  }
}

@Component({
  selector: "blocked-courses",
  templateUrl: "blocked-courses.html",
})
export class BlockedCourses implements OnInit {
  courses: any = [];

  constructor(
    private dialogRef: MatDialogRef<VimeoModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.courses = this.data.courses;
  }
}
