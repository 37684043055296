import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "../../core/services/http.service";
import { PaymentService } from "@modules/payment/payment.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { environment } from "@environment/environment";

import Header from "@editorjs/header";
import List from "@editorjs/list";
import RawTool from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Checklist from "@editorjs/checklist";
import Embed from "../../core/ui/editor/embed";
import Quote from "@editorjs/quote";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import CodeTool from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import Paragraph from "@editorjs/paragraph";
import InlineCode from "@editorjs/inline-code";
import EditorJS from "@editorjs/editorjs";
import AttachesTool from "../../core/ui/editor/attaches";
import { AuthService } from "@core/services/auth.service";

import { Platform } from "@angular/cdk/platform";

@Component({
  selector: "im-course",
  templateUrl: "./course.component_backup.html",
  styleUrls: [
    "./course.component.scss",
    "../../core/ui/editor/attaches/index.scss",
    "../../core/ui/editor/embed/index.scss",
  ],
})
export class CourseComponent implements OnInit {
  courseSlug: string;
  course: any;
  processing: boolean = false;
  courseModules: any = [];

  editor: any;

  loginUser: any;

  noTabs = true;

  activeTopic: any;
  processingTopics: boolean = true;

  chapterTopics: any = [];

  constructor(
    public platform: Platform,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private paymentService: PaymentService,
    protected sanitizer: DomSanitizer,
    private auth: AuthService
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      }
    });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
  }

  processEditor() {
    const that = this;
    this.editor = new EditorJS({
      data: this.course.about,
      readOnly: true,
      tools: {
        header: Header,
        list: List,
        paragraph: {
          class: Paragraph,
        },
        //image: SimpleImage,
        checklist: Checklist,
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              vimeo: {
                regex:
                  /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
                embedUrl: environment.playerUrl + "<%= remote_id %>",
                html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
                height: 300,
                width: 600,
                id: ([id]) => {
                  const params = [
                    "autoplay=false",
                    "autopause=true",
                    "muted=false",
                    "loop=false",
                    `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                    "poster=",
                    "time=true",
                    "progressBar=true",
                    "overlay=true",
                    "muteButton=true",
                    "fullscreenButton=true",
                    "style=light",
                    "quality=auto",
                    "playButton=true",
                    "pip=true",
                  ];
                  return "?" + params.join("&");
                },
              },
              schoolforai: {
                regex:
                  /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
                embedUrl:
                  environment.playerUrl +
                  "?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true",
                html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
                height: 300,
                width: 600,
              },
            },
          },
        },
        attaches: {
          class: AttachesTool,
          config: {
            //   endpoint: environment.mediaUrl+'/upload',
            //   field: 'file[0]',
            //   additionalRequestData: {
            //     upload_path: '/content',
            //     custom_attrs: [],
            //     random_names: false,
            //     modify_output: true
            //   },
            //   additionalRequestHeaders: {
            //    Authorization: `Bearer ${this.authService.getToken()}`
            //  },
          },
        },
        quote: Quote,
        table: Table,
        raw: RawTool,
        code: CodeTool,
        linkTool: LinkTool,
        marker: Marker,
        warning: Warning,
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
        image: {
          class: ImageTool,
        },
      },
      onChange: () => {},
      onReady: () => {
        window.addEventListener("message", function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + "%";
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }

  openLockStatus(): void {
    const bottomSheetRef = this._bottomSheet.open(ChapterLockStatusSheet, {
      panelClass: "im-sheet",
    });
    bottomSheetRef.afterDismissed().subscribe((event) => {
      if (event) {
        if (event.event == "buyNow") {
          this.buyNow(this.course);
        }
      }
    });
  }

  buyNow(course: any) {
    this.course.packageType = "course";
    const dialogRef = this.paymentService.initPayment(this.course);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.status === "Success") {
        this.getCourse();
      }
    });
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get("na/course/" + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        this.course["cover_image_pb"] = this.sanitizer.bypassSecurityTrustStyle(
          `linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url(${this.course.cover_image_p})`
        );
        if (
          "buy-now" in this.route.snapshot.queryParams &&
          this.route.snapshot.queryParams["buy-now"] == 1
        ) {
          this.buyNow(this.course);
        }
        this.getModules();
        if (!this.course.user_course) {
          this.processing = false;
          setTimeout(() => {
            this.processEditor();
            document.querySelector(".download_brochure a").addEventListener(
              "click",
              function () {
                that.downloadFile(
                  that.course.properties.brochure,
                  that.course.name + " Brochure"
                );
                document
                  .querySelector(".download_brochure a")
                  .classList.add("loading");
                setTimeout(function () {
                  document
                    .querySelector(".download_brochure a")
                    .classList.remove("loading");
                }, 8000);
              },
              false
            );
          }, 100);
        }
        //this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getModules() {
    this.http.get("na/course/" + this.course.id + "/modules").subscribe(
      (res) => {
        this.courseModules = res.data;
        this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getChapterTopics(chapter) {
    this.processingTopics = true;
    const that = this;
    this.http
      .get(
        'course_topic?recursive=0&filter="course_chapter_id eq ' +
          chapter.id +
          '"'
      )
      .subscribe(
        (res) => {
          this.chapterTopics = res.data;
          // if (this.topicSlug) {
          //   this.activeTopic = this.chapterTopics.find(topic => topic.slug == this.topicSlug);
          // }
          if (!this.activeTopic) {
            const currentTopic = this.chapterTopics.find((item) => {
              return (
                item.user_course_topic &&
                item.user_course_topic.current_topic == 1
              );
            });
            if (currentTopic) {
              this.activeTopic = currentTopic;
            } else {
              this.activeTopic = this.chapterTopics[0];
            }
          }
          if (!this.activeTopic.user_course_topic) {
            if (chapter.properties && chapter.properties.is_it_free) {
            } else {
              this.processingTopics = false;
              this.activeTopic = "";
              return;
            }
          }
          this.processingTopics = false;
          setTimeout(() => {
            this.processEditor();
          }, -1);
        },
        (err) => {
          this.processingTopics = false;
        }
      );
  }

  courseIntro() {
    const dialogRef = this.dialog.open(CourseIntroModal, {
      width: "60vw",
      data: this.course,
      panelClass: "courseIntroPopup",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  downloadCvs() {}

  urlManipulation(url) {
    return url.replace("{{ courseSlug }}", this.courseSlug);
  }
}

@Component({
  selector: "im-chapter-lock",
  templateUrl: "chapter.lock.html",
})
export class ChapterLockStatusSheet {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ChapterLockStatusSheet>
  ) {}

  buyNow(event: MouseEvent): void {
    this._bottomSheetRef.dismiss({ event: "buyNow" });
    event.preventDefault();
  }
}

@Component({
  selector: "course-intro",
  templateUrl: "course.intro.html",
})
export class CourseIntroModal implements OnInit {
  url: any;

  constructor(
    protected sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<CourseIntroModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      "https://app.cedlearn.com/player/index.html?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F" +
        this.data.properties.introVideoId +
        "&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true"
    );
  }
}
