<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="over" position="end">
    <div style="width: 30vw" *ngIf="initiateEditFlag">
      <h2 class="px-4 mt-4">Edit Batch</h2>
      <form [formGroup]="editBatchGroup" class="p-4 flex flex-column">
        <mat-form-field appearance="outline" class="mt-2 w-3/4">
          <mat-label>Batch Name</mat-label>
          <input matInput placeholder="Enter batch keyword" formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="mt-2 course-selection w-3/4">
          <mat-label>Course</mat-label>
          <mat-select placeholder="Select Course" formControlName="refId">
            <mat-option value="{{ course.id }}" *ngFor="let course of originalCourses">{{ course.name }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mt-2 w-3/4">
          <mat-label>Batch Start Date</mat-label>
          <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a Start Date"
            formControlName="batch_start_date" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker hideTime="true"></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-label class="mt-2">Batch Time</mat-label>
        <ngx-mat-timepicker formControlName="batch_time"> </ngx-mat-timepicker>

        <mat-form-field appearance="outline" class="mt-2 w-3/4">
          <mat-label>Batch End Date</mat-label>
          <input matInput [ngxMatDatetimePicker]="picker1" placeholder="Choose a End Date"
            formControlName="batch_end_date" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker1 hideTime="true"></ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="mt-2 w-3/4">
          <mat-label>Event Link</mat-label>
          <input matInput placeholder="Event Link" formControlName="event_link" />
        </mat-form-field>

        <div class="actions flex">
          <button class="search-buttons" (click)="updateBatch(drawer)">
            Update
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="initiateattendanceFlag">
      <im-attendance-details [batch]="activeBatch"></im-attendance-details>
    </div>
  </mat-drawer>
  <div class="job">
    <div class="im-bar-coder">
      <h2 class="px-4 xs:px-4">Batches</h2>
    </div>
    <div class="wrapper detail-page">
      <div class="main-container">
        <div class="searched-jobs">
          <div class="job-overview relative">
            <div class="absolute right-10-px top-5-px">
              <ng-container *ngIf="mainTabSelection ==0">
                <button mat-stroked-button color="primary" class="mr-2" (click)="initBatchCreation()">
                  Create Batch
                </button>
              </ng-container>
              <ng-container *ngIf="mainTabSelection ==1">
                <ng-container *ngIf="loginUser.is_admin == 1">
                  <button mat-stroked-button color="primary" class="mr-2" (click)="initiateCreateUser()">
                    Create User
                  </button>
                </ng-container>
              </ng-container>

            </div>
            <mat-tab-group (selectedTabChange)="batchStudentTab($event)">
              <mat-tab label="Batches">
                <div class="job-overview-cards pt-4" style="
                    height: calc(100vh - 112px);
                    padding-right: 1rem;
                    padding-left: 1rem;
                    width: 22vw;
                  ">
                  <ng-container *ngFor="let batch of batches; let i = index">
                    <div class="job-overview-card" (click)="openBatch(batch, i)">
                      <div class="job-card overview-card" [ngClass]="{
                          'active shadow-2': batch.id == activeBatch?.id
                        }">
                        <div class="overview-wrapper">
                          <img [src]="batch.course.icon_p" class="mr-2" width="32" height="32" />
                          <div class="overview-detail">
                            <div class="job-card-title">{{ batch.name }}</div>
                            <div class="job-card-subtitle">
                              {{ batch.members }} Members
                            </div>
                          </div>
                          <!--<svg class="heart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-heart">
                                                    <path
                                                        d="M20.8 4.6a5.5 5.5 0 00-7.7 0l-1.1 1-1-1a5.5 5.5 0 00-7.8 7.8l1 1 7.8 7.8 7.8-7.7 1-1.1a5.5 5.5 0 000-7.8z" />
                                                </svg> -->
                        </div>
                        <div class="job-overview-buttons">
                          <div class="search-buttons time-button">
                            {{ batch.course.name }}
                          </div>
                          <div class="search-buttons level-button" *ngIf="batch.batch_time">
                            {{
                            currentDate + " " + batch.batch_time
                            | date : "shortTime"
                            }}
                          </div>
                          <!-- <div class="job-stat">New</div>
                            <div class="job-day">4d</div> -->
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
              <mat-tab label="Enrolled Students">
                <div class="job-overview-cards pt-4 relative" style="
                    height: calc(100vh - 112px);
                    padding-right: 1rem;
                    padding-left: 1rem;
                    width: 22vw;
                  ">
                  <mat-form-field appearance="outline">
                    <mat-label>Search For Enrolled Student</mat-label>
                    <input matInput type="text" [(ngModel)]="studentSearchKEy" />
                    <button mat-button *ngIf="studentSearchKEy" matSuffix mat-icon-button aria-label="Clear"
                      (click)="studentSearchKEy = ''">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>

                  <ng-container *ngFor="
                      let enrolledUser of enrolledUsers
                        | filter : studentSearchKEy;
                      let i = index
                    ">
                    <div class="job-overview-card" (click)="openStudent(enrolledUser, i)">
                      <div class="job-card overview-card" [ngClass]="{
                          'active shadow-2':
                            enrolledUser.id == activeStudent?.id
                        }" [ngStyle]="{
                          border:
                            enrolledUser.status == 3
                              ? '3px solid #ffd6d6'
                              : '3px solid transparent'
                        }">
                        <div class="overview-wrapper">
                          <img [src]="enrolledUser.user.profile_image" class="mr-2 border-radius-full" width="42"
                            height="42" style="
                              object-fit: cover;
                              width: 42px;
                              height: 42px;
                              min-width: 42px;
                            " />
                          <div class="overview-detail">
                            <div class="job-card-title text-capitalize">
                              {{ enrolledUser.user.name }}
                            </div>
                            <div class="job-card-subtitle">
                              {{ enrolledUser.user.email }}
                            </div>
                          </div>
                        </div>
                        <div class="job-overview-buttons">
                          <div class="search-buttons time-button">
                            {{ enrolledUser?.mcourse?.name }}
                          </div>
                          <div class="search-buttons time-button" *ngIf="enrolledUser?.user?.batch?.batch?.name">
                            {{ enrolledUser?.user?.batch?.batch?.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </mat-tab>
            </mat-tab-group>

            <div class="job-explain w-full shadow-1" *ngIf="activeBatch">
              <!-- <img class="job-bg" alt=""> -->
              <div class="job-logos">
                <img [src]="activeBatch.course.icon_p" class="shadow-2 border-radius-full" />
              </div>
              <mat-tab-group>
                <mat-tab label="Info">
                  <div class="job-explain-content relative">
                    <div class="job-title-wrapper">
                      <div class="job-card-title flex items-center">
                        {{ activeBatch.name }}
                      </div>
                      <!-- <div class="job-subtitle-wrapper mt-0 ml-2">
                        <div class="company-name">
                          {{ activeBatch.course.name }}
                        </div>
                      </div> -->
                    </div>

                    <div class="explain-bar">
                      <div class="explain-contents">
                        <div class="explain-title">Course</div>
                        <div class="explain-subtitle">
                          {{ activeBatch.course.name }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">Start Date</div>
                        <div class="explain-subtitle">
                          {{ activeBatch.batch_start_date }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">Time</div>
                        <div class="explain-subtitle" *ngIf="activeBatch.batch_time">
                          {{
                          currentDate + " " + activeBatch.batch_time
                          | date : "shortTime"
                          }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">End Date</div>
                        <div class="explain-subtitle">
                          {{ activeBatch.batch_end_date }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">Members</div>
                        <div class="explain-subtitle">
                          {{ activeBatch.members }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">Status</div>
                        <div class="explain-subtitle">IN PROGRESS</div>
                      </div>
                    </div>
                    <div class="flex">
                      <div class="overview-text w-1/2" style="height: 40vh; overflow: auto">
                        <div class="overview-text-header mb-4">Students</div>
                        <ng-container *ngFor="let student of activeBatch?.students">
                          <div class="overview-text-item">
                            {{ student.user.name }}
                          </div>
                        </ng-container>
                      </div>
                      <div class="p-4 mt-6 w-1/2">
                        <textarea class="w-full p-4 border-radius-lg" matInput cdkTextareaAutosize
                          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="5"
                          style="border: 1px solid #d8d8d8">
                        </textarea>
                      </div>
                    </div>
                    <div class="actions absolute bottom-20" style="width: 92%">
                      <div class="flex justify-between">
                        <div>
                          <ng-container *ngIf="loginUser.is_admin == 1">
                            <button mat-stroked-button color="primary" class="mr-2" (click)="initEdit(drawer)">
                              Edit Batch
                            </button>
                          </ng-container>
                          <button mat-stroked-button color="primary" (click)="initAttendance(drawer)" class="mr-2">
                            Attendance
                          </button>
                        </div>
                        <div *ngIf="loginUser.is_admin == 1">
                          <button mat-raised-button color="warn" (click)="deleteBatch()">
                            Delete Batch
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Students">
                  <div class="job-explain-content relative">
                    <div class="job-title-wrapper">
                      <div class="job-card-title">
                        {{ activeBatch.name }}
                        <button mat-icon-button class="assign-student shadow-2 ml-2" (click)="initUserAssign()">
                          <mat-icon>add</mat-icon>
                        </button>
                      </div>
                      <div class="job-subtitle-wrapper mt-0 ml-2">
                        <div class="company-name">
                          {{ activeBatch.course.name }}
                        </div>
                      </div>
                    </div>
                    <ng-container *ngIf="fetchingStudents"> </ng-container>
                    <ng-container *ngIf="!fetchingStudents">
                      <ng-container *ngFor="let student of activeBatch?.students">
                        <div class="explain-bar" [ngStyle]="{
                            background:
                              student.user_course?.status == 3
                                ? '#ffdddd'
                                : '#fff'
                          }">
                          <div class="explain-contents" style="width: 20vw">
                            <div class="explain-subtitle mt-3">
                              <h3 class="text-capitalize">
                                {{ student.user.name }}
                              </h3>
                              <small>{{ student.user.email }},{{
                                student.user.mobile_number
                                }}</small>
                            </div>
                          </div>

                          <div class="explain-contents">
                            <div class="explain-title">Course</div>
                            <div class="explain-subtitle">
                              {{ student.user_course?.mcourse?.name }}
                            </div>
                          </div>
                          <div class="explain-contents">
                            <div class="explain-title">Status</div>
                            <div class="explain-subtitle" [ngStyle]="{
                                color:
                                  student.user_course?.status == 3
                                    ? 'red'
                                    : '#252525'
                              }">
                              {{
                              student.user_course?.status == 1
                              ? "Active"
                              : "Inactive"
                              }}
                            </div>
                          </div>

                          <div class="explain-contents">
                            <div class="explain-title">Actions</div>
                            <div class="explain-subtitle">
                              <button mat-icon-button matTooltip="Remove the User from Batch" class=""
                                (click)="removeUser(student)" style="
                                  width: 24px;
                                  height: 24px;
                                  line-height: 24px;
                                  color: red;
                                ">
                                <mat-icon>remove_circle</mat-icon>
                              </button>
                              <ng-container *ngIf="loginUser.is_admin == 1">
                                <button mat-icon-button matTooltip="Change the Course Acess" class="ml-2"
                                  (click)="courseAccessStatusChange(student)" style="
                                    width: 24px;
                                    height: 24px;
                                    line-height: 24px;
                                  ">
                                  <mat-icon>{{
                                    student.user_course?.status == 1
                                    ? "lock_open"
                                    : "lock"
                                    }}</mat-icon>
                                </button>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </mat-tab>
                <mat-tab label="Trainers">
                  <div class="job-explain-content relative">
                    <div class="job-title-wrapper">
                      <div class="job-card-title">
                        {{ activeBatch.name }}
                        <button mat-icon-button class="assign-student shadow-2 ml-2">
                          <mat-icon>add</mat-icon>
                        </button>
                      </div>
                      <div class="job-subtitle-wrapper mt-0 ml-2">
                        <div class="company-name">
                          {{ activeBatch.course.name }}
                        </div>
                      </div>
                    </div>
                    <div class="explain-bar">
                      <div class="explain-contents">
                        <div class="explain-title">Start Date</div>
                        <div class="explain-subtitle">
                          {{ activeBatch.batch_start_date }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">End Date</div>
                        <div class="explain-subtitle">
                          {{ activeBatch.batch_end_date }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">Members</div>
                        <div class="explain-subtitle">
                          {{ activeBatch.members }}
                        </div>
                      </div>
                      <div class="explain-contents">
                        <div class="explain-title">Status</div>
                        <div class="explain-subtitle">IN PROGRESS</div>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Timeline">
                  <div class="job-explain-content">
                    <div class="job-title-wrapper">
                      <div class="job-card-title">{{ activeBatch.name }}</div>
                      <div class="job-subtitle-wrapper mt-0 ml-2">
                        <div class="company-name">
                          {{ activeBatch.course.name }}
                        </div>
                      </div>
                    </div>

                    <im-batch-timeline-create></im-batch-timeline-create>
                  </div>
                </mat-tab>
                <mat-tab label="Events"></mat-tab>
                <mat-tab label="Tasks"></mat-tab>
              </mat-tab-group>
            </div>
            <div class="job-explain w-full shadow-1" *ngIf="activeStudent">
              <!-- <img class="job-bg" alt=""> -->
              <div class="job-logos">
                <img [src]="activeStudent.user.profile_image" class="shadow-2 border-radius-full"
                  style="width: 64px; height: 64px; background: #fff" />
              </div>
              <mat-tab-group>
                <mat-tab label="Info">
                  <div class="job-explain-content relative p-0">
                    <div class="flex h-full">
                      <div class="w-full p-6">
                        <div class="job-title-wrapper flex-column" style="align-items: flex-start">
                          <div class="job-card-title flex items-center text-capitalize flex items-center">
                            <span>{{ activeStudent.user.name }}</span>
                            <div class="search-buttons time-button mt-0 ml-4"
                              *ngIf="activeStudent?.user?.batch?.batch?.name">
                              {{ activeStudent?.user?.batch?.batch?.name }}
                            </div>
                          </div>
                          <div class="w-full mt-4">
                            <iframe [src]="activeStudent?.performance_url" frameborder="0"
                              class="powerbi-wrapper w-full" allowtransparency style="height: 62vh"></iframe>
                          </div>
                        </div>

                        <div class="actions absolute bottom-20" style="width: 92%">
                          <div class="flex justify-between">
                            <div>
                              <button mat-icon-button class="assign-student shadow-2 ml-2" (click)="initUserAssign()">
                                <mat-icon>add</mat-icon>
                              </button>
                              <!-- <button mat-stroked-button color="primary" (click)="initAttendance(drawer)" class="mr-4">
                                Attendance
                              </button> -->
                              <ng-container *ngIf="loginUser.is_admin == 1">
                                <ng-container *ngIf="activeStudent.status == 1">
                                  <button mat-raised-button color="warn" (click)="
                                      courseAccessStatusChangeStudentSpecific(
                                        activeStudent
                                      )
                                    ">
                                    De-Activate Dashboard
                                  </button>
                                </ng-container>
                                <ng-container *ngIf="activeStudent.status == 3">
                                  <button mat-raised-button color="primary" (click)="
                                      courseAccessStatusChangeStudentSpecific(
                                        activeStudent
                                      )
                                    ">
                                    Re-Activate Dashboard
                                  </button>
                                </ng-container>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="w-2/5 p-6" *ngIf="activeStudent?.user_info">
                        <div class="overview-text m-0" style="overflow: auto">
                          <div class="overview-text-header mb-4">Overview</div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3">Student Id</b>
                            <span class="w-2/3">{{
                              activeStudent?.user_info.id
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3">Email</b>
                            <span class="w-2/3">{{
                              activeStudent?.user_info.email
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3"
                              >Email Verified at</b
                            >
                            <span class="w-2/3">{{
                              activeStudent?.user_info.email_verified_at
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3">Mobile Number</b>
                            <span class="w-2/3">{{
                              activeStudent?.user_info.mobile_number
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3"
                              >Mobile Verified At</b
                            >
                            <span class="w-2/3">{{
                              activeStudent?.user_info.mobile_verified_at
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3">Account Status</b>
                            <span class="w-2/3">{{
                              activeStudent?.user_info.status == 1
                                ? "Active"
                                : "Inactive"
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3">Login Status</b>
                            <span class="w-2/3">{{
                              activeStudent?.user_info.login_status == 1
                                ? "Online"
                                : "Offline"
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3">Account Created</b>
                            <span class="w-2/3">{{
                              activeStudent?.user_info.created_at
                            }}</span>
                          </div>
                          <div class="overview-text-item">
                            <b class="text-capitalize w-1/3">Last Modified</b>
                            <span class="w-2/3">{{
                              activeStudent?.user_info.updated_at
                            }}</span>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Assignments">
                  <div class="p-6 trainer-assignments-section-wrapper">
                    <mat-accordion class="example-headers-align">
                      <ng-container *ngFor="
                          let category of courseAssignments?.categories;
                          let asi = index
                        ">
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                              {{ category.label }}
                            </mat-panel-title>
                            <mat-panel-description>
                              {{
                              activeStudent.userTaks[category.label]
                              ? Object.keys(
                              activeStudent.userTaks[category.label]
                              ).length
                              : 0
                              }}
                              /
                              {{ category.totalAssignments }}
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <div class="trainer-assignments-view-wrapper">
                            <ng-container *ngFor="let ch of courseAssignments[category.key]">
                              <h3 class="px-8 mt-8" *ngIf="!ch.disabled">
                                {{ ch.chapter_title }}
                              </h3>
                              <div class="assignments-items-section flex px-8 flex-wrap mt-2">
                                <ng-container *ngFor="let item of ch.items">
                                  <div class="item" *ngIf="item.link">
                                    <mat-card class="mat-card border-radius-lg relative">
                                      <mat-card-content>
                                        <div class="absolute right-10-px top-10-px" *ngIf="
                                            activeStudent.userTaks &&
                                            activeStudent.userTaks[
                                              category.label
                                            ] &&
                                            activeStudent.userTaks[
                                              category.label
                                            ][item.title]
                                          ">
                                          <mat-chip-list aria-label="model tags">
                                            <mat-chip color="primary" selected>{{
                                              activeStudent.userTaks[
                                              category.label
                                              ][item.title].created_at
                                              | date : "medium"
                                              }}</mat-chip>
                                          </mat-chip-list>
                                        </div>
                                        <div class="icon-info" [ngClass]="{
                                            completed:
                                              activeStudent.userTaks &&
                                              activeStudent.userTaks[
                                                category.label
                                              ] &&
                                              activeStudent.userTaks[
                                                category.label
                                              ][item.title]
                                          }">
                                          <div class="border-radius-full">
                                            {{ item.title }}
                                          </div>
                                        </div>
                                        <h4 class="mt-2 font-medium ml-2">
                                          {{ item.topic_title }}
                                        </h4>
                                        <ng-container *ngIf="item.type == 'download'">
                                          <div class="flex justify-between items-center mt-5">
                                            <ng-container *ngIf="
                                                activeStudent.userTaks &&
                                                activeStudent.userTaks[
                                                  category.label
                                                ] &&
                                                activeStudent.userTaks[
                                                  category.label
                                                ][item.title]
                                              ">
                                              <button mat-button class="mt-3" (click)="
                                                  downloadFile(
                                                    activeStudent.userTaks[
                                                      category.label
                                                    ][item.title]
                                                      .upload_attacment
                                                  )
                                                " color="primary">
                                                <mat-icon>file_download</mat-icon>
                                                Submitted
                                              </button>
                                            </ng-container>

                                            <button mat-mini-fab class="mt-3 mat-elevation-z1"
                                              (click)="downloadFile(item.link)">
                                              <mat-icon>file_download</mat-icon>
                                            </button>
                                          </div>
                                        </ng-container>
                                        <ng-container *ngIf="!item.type">
                                          <div class="flex justify-between items-center mt-5">
                                            <ng-container *ngIf="
                                                activeStudent.userTaks &&
                                                activeStudent.userTaks[
                                                  category.label
                                                ] &&
                                                activeStudent.userTaks[
                                                  category.label
                                                ][item.title]
                                              ">
                                              <ng-container *ngIf="item.solution">
                                                <button mat-button class="mt-3" (click)="
                                                    downloadFile(item.solution)
                                                  " color="primary">
                                                  <mat-icon>file_download</mat-icon>
                                                  Solution
                                                </button>
                                              </ng-container>
                                            </ng-container>

                                            <button mat-mini-fab
                                              class="mt-3 float-right mat-elevation-z1 absolute bottom-15 right-10"
                                              (click)="
                                                activeItem = item;
                                                drawer.toggle()
                                              ">
                                              <mat-icon>east</mat-icon>
                                            </button>
                                          </div>
                                        </ng-container>
                                      </mat-card-content>
                                    </mat-card>
                                  </div>
                                </ng-container>
                              </div>
                            </ng-container>
                          </div>
                        </mat-expansion-panel>
                      </ng-container>
                    </mat-accordion>
                  </div>
                </mat-tab>

                <mat-tab label="Fee Details">
                  <div class="job-explain-content">
                    <div class="job-title-wrapper">
                      <div class="job-card-title text-capitalize">
                        {{ activeStudent.user.name }}
                        <button mat-icon-button class="shadow-2 ml-2" (click)="addNewTerm()">
                          <mat-icon>add</mat-icon>
                        </button>
                        <ng-container *ngIf="activeStudentFeeDetails.length">
                          <button mat-raised-button color="primary" class="ml-4" (click)="saveFeeDetails()">
                            Save
                          </button>
                        </ng-container>
                      </div>
                      <div class="job-subtitle-wrapper mt-0 ml-2">
                        <div class="company-name">
                          {{ activeStudent.mcourse?.name }}
                        </div>
                      </div>
                    </div>
                    <div class="flex mt-4">
                      <form class="w-full">
                        <ng-container *ngFor="
                            let term of activeStudentFeeDetails;
                            let i = index
                          ">
                          <div class="flex w-full items-center justify-between">
                            <div class="w-1/3">
                              <mat-form-field appearance="outline" class="mt-2 w-3/4">
                                <input matInput placeholder="Term Amount" />
                              </mat-form-field>
                            </div>
                            <div class="w-1/3">
                              <mat-form-field appearance="outline" class="mt-2 w-3/4">
                                <input matInput [matDatepicker]="picker" placeholder="Term Date" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker hideTime="true"></ngx-mat-datetime-picker>
                              </mat-form-field>
                            </div>
                            <div class="w-2/5">
                              <button mat-icon-button class="" style="color: red" (click)="removeTerm(i)">
                                <mat-icon>remove_circle</mat-icon>
                              </button>
                            </div>
                          </div>
                        </ng-container>
                      </form>
                    </div>
                  </div>
                </mat-tab>

                <mat-tab label="Payment Receipts"> </mat-tab>
              </mat-tab-group>
            </div>
            <div class="job-explain w-full" *ngIf="!activeBatch && !activeStudent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>