import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../core/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@environment/environment';
import { Location } from '@angular/common'

@Component({
  selector: 'im-jupiter-notebooks',
  templateUrl: './jupiter-notebooks.component.html',
  styleUrls: ['./jupiter-notebooks.component.scss']
})
export class JupiterNotebooksComponent implements OnInit {

  courseSlug: string;
  course: any;
  processing: boolean = false;

  navLinks = [];

  activeTab: any;

  loginUser: any;

  jupiterData: any;

  activeItem: any;

  dataSets: any;


  constructor(private location: Location, private auth: AuthService, private route: ActivatedRoute, private router: Router, private http: HttpService) { 
    this.route.params.subscribe(params => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      }
    })

  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get('na/course/' + this.courseSlug).subscribe((res) => {
      this.course = res.data;
      if (!this.course.user_course && this.loginUser.user_type == 'student') {
        this.router.navigate(['/course/' + this.courseSlug]);
      } else {
        this.http.getExternal(environment.storageUrl + 'jupiter-notebooks/' + this.courseSlug + '.json?t='+new Date().getTime()).subscribe((res) => {
          this.jupiterData = res;
          this.navLinks = res.categories;
          if(this.route.snapshot.queryParamMap.get('m')) {
            let tempFM = this.navLinks.find((it) => it.key == this.route.snapshot.queryParamMap.get('m'));
            if(tempFM) {
              this.getActiveData(tempFM);
            } else {
              this.getActiveData(this.navLinks[0]);
            }
          } else {
            this.getActiveData(this.navLinks[0]);
          }
        })
      }
      //this.processing = false;
    }, (err) => {
      this.processing = false;
    })
  }

  getActiveData(link:any) {
    this.activeTab = link;
    this.activeItem = this.jupiterData[link.key];
    this.router.navigate([], { relativeTo: this.route, queryParams: {m: this.activeTab.key}});

    console.log(this.activeItem);
  }

  getDataSets(item: any) {
    this.http.getExternal(environment.storageUrl + 'jupiter-notebooks/data-sets/' + this.courseSlug + '/'+this.activeTab.key+'.json?t='+new Date().getTime()).subscribe((res) => {
        this.dataSets = res;
    })
  }

  takeAction(action:any) {
    if(action.type == 'external') {
      window.open(action.link, '_blank').focus();
    }
    if(action.type == 'download') {
        this.downloadFile(action.link);
    }
  }

  back(): void {
    //this.location.back()
    this.router.navigate(['course', this.courseSlug]);

  }


  downloadFile(url, filename = '') {
    if (filename.length === 0) filename = url.split('/').pop();
    const req = new XMLHttpRequest();
    req.open('GET', url, true);
    req.responseType = 'blob';
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: 'application/pdf',
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', filename);
        a.setAttribute('href', href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }
}
