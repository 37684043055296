import { AfterViewInit, Component, OnInit } from "@angular/core";
import { HttpService } from "../../../core/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { environment } from "@environment/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { IMSnackBarService } from "@core/utillities/snack-bar";

@Component({
  selector: "im-job-readiness",
  templateUrl: "./job-readiness.component.html",
  styleUrls: ["./job-readiness.component.scss"],
})
export class JobReadinessComponent implements OnInit, AfterViewInit {
  courseSlug: string;
  course: any;
  processing: boolean = false;

  loginUser: any;
  pdfSrc = "";
  activeSource: any;
  resources = [
    {
      name: "ATS Complied Resume",
      link: "https://app.cedlearn.com/static/job-readiness/data/ATS_Complied_Resume.pdf",
      short:
        "Understand difference between CV & Resume and how to create a job winning ATS complied CV with minimum efforts",
    },
    {
      name: "Effective Self-Intro",
      link: "https://app.cedlearn.com/static/job-readiness/data/Effective-Self-Intro.pdf",
      short:
        "What it takes to prepare yourself for the dream job , while impressing the recruiters",
    },
  ];

  constructor(
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private snackbar: IMSnackBarService
  ) {
    // this.route.params.subscribe((params) => {
    //   if (params.slug) {
    //     this.courseSlug = params.slug;
    //     this.getCourse();
    //   }
    // });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    if (this.loginUser.resume) {
      this.pdfSrc = this.loginUser.resume;
    }
    this.processing = false;
  }

  ngAfterViewInit(): void {
    let that = this;
    document.querySelector(".download_brochure a").addEventListener(
      "click",
      function () {
        that.downloadFile(
          "https://app.cedlearn.com/static/job-readiness/data/Resume-Templates.zip",
          "Resume Templates"
        );
        document.querySelector(".download_brochure a").classList.add("loading");
        setTimeout(function () {
          document
            .querySelector(".download_brochure a")
            .classList.remove("loading");
        }, 8000);
      },
      false
    );
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/zip",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get("na/course/" + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        if (!this.course.user_course && this.loginUser.user_type == "student") {
          this.router.navigate(["/course/" + this.courseSlug]);
        } else {
        }
        this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  sourceActivated(source) {
    this.pdfSrc = source.link;
    this.activeSource = source;
  }

  uploadDocument(submitFile) {
    submitFile.click();
  }

  uploadSource(e) {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      if (e.target.files[0].type === "application/pdf") {
        this.upload(e.target.files[0]);
      } else {
        this.snackbar.open(
          "Invalid File Format. File should be in pdf format",
          "error"
        );
      }
    }
    e.target.value = "";
  }

  upload(source) {
    let that = this;
    let ajaxData = new FormData();
    ajaxData.append("file[0]", source);
    var reader = new FileReader();

    reader.readAsDataURL(source);
    ajaxData.append("upload_path", "/user/cvs/" + this.loginUser.id);
    ajaxData.append("custom_attrs", "[]");
    ajaxData.append("random_names", "true");
    this.http.upload("media/upload", ajaxData).subscribe((res: any) => {
      if (res.length == 1 && res[0].success) {
        this.http
          .put("user/update", {
            "subforms.0.user_meta.meta_key": "resume",
            "subforms.0.user_meta.meta_value": res[0].file_name,
          })
          .subscribe(
            async (res) => {
              this.processing = false;
              this.loginUser = await this.auth.me();
              if (this.loginUser.resume) {
                this.pdfSrc = this.loginUser.resume;
              }
            },
            (err) => {
              this.processing = false;
            }
          );
      }
    });
  }

  closeResources() {
    this.activeSource = null;
    if (this.loginUser.resume) {
      this.pdfSrc = this.loginUser.resume;
    } else {
      this.pdfSrc = "";
    }
  }
}
