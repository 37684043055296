import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "../../../core/services/http.service";
import { PaymentService } from "@modules/payment/payment.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "@environment/environment";

import Header from "@editorjs/header";
import List from "@editorjs/list";
import RawTool from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Checklist from "@editorjs/checklist";
import Embed from "../../../core/ui/editor/embed";
import Quote from "@editorjs/quote";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
//import CodeTool from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import Paragraph from "@editorjs/paragraph";
import InlineCode from "@editorjs/inline-code";
import EditorJS from "@editorjs/editorjs";
import AttachesTool from "../../../core/ui/editor/attaches";

import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { PictureVideoComponent } from "../../../themes/default/picture-video/picture-video.component";

@Component({
  selector: "im-chapter",
  templateUrl: "./chapter.component.html",
  styleUrls: [
    "./chapter.component.scss",
    "../../../core/ui/editor/attaches/index.scss",
    "../../../core/ui/editor/embed/index.scss",
  ],
})
export class ChapterComponent implements OnInit {
  courseSlug: string;
  moduleSlug: string;
  chapterSlug: string;
  topicSlug: string;
  course: any;
  processing: boolean = false;
  processingTopics: boolean = true;
  module: any;
  chapter: any;
  chapterTopics: any = [];
  editor: any;
  activeTopic: any;

  enableQuizButton: boolean = false;

  coursePurchased: boolean = false;

  chapterLoked: boolean = false;

  processActions: boolean = false;

  constructor(
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService,
    private paymentService: PaymentService,
    protected sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.route.snapshot.paramMap.get("slug")) {
      this.courseSlug = this.route.snapshot.paramMap.get("slug");
      this.moduleSlug = this.route.snapshot.paramMap.get("moduleSlug");
      this.chapterSlug = this.route.snapshot.paramMap.get("chapterSlug");
      this.topicSlug = this.route.snapshot.queryParamMap.get("topic");
      this.getCourse();
    }
  }

  getCourse() {
    this.processing = true;
    this.http
      .get("course/" + this.courseSlug + "?fields=id,name,properties,icon,slug")
      .subscribe(
        (res) => {
          this.course = res.data;
          if (this.course.user_course) {
            this.coursePurchased = true;
          }
          //this.course.cover_image = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(to bottom,rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4)), url(${this.course.cover_image})`);
          //this.processing = false;
          this.getModule();
          this.getChapter();
        },
        (err) => {
          this.processing = false;
        }
      );
  }

  getModule() {
    this.processing = true;
    this.http
      .get(
        "course_module/" +
          this.moduleSlug +
          "?fields=id,name,properties,slug,icon&filter=course_id eq " +
          this.course.id
      )
      .subscribe(
        (res) => {
          this.module = res.data;
          //this.processing = false;
        },
        (err) => {
          this.processing = false;
        }
      );
  }

  getChapter() {
    this.processing = true;
    this.http
      .get(
        "course_chapter/" +
          this.chapterSlug +
          "?recursive=-1&fields=id,title,properties,slug&filter=course_id eq " +
          this.course.id
      )
      .subscribe(
        (res) => {
          this.chapter = res.data;
          // if (
          //   this.course.user_course &&
          //   this.course.user_course.properties &&
          //   this.course.user_course.properties.chapterStatus &&
          //   this.course.user_course.properties.chapterStatus[this.chapter.id]
          //     .lock == 1
          // ) {
          //   this.chapterLoked == true;
          // }
          //if (!this.chapterLoked) {
          this.getChapterTopics();
          //}
          this.processing = false;
        },
        (err) => {
          this.processing = false;
        }
      );
  }

  makeActiveTopic(topic: any) {
    if (!this.course.user_course) {
      if (this.chapter.properties && this.chapter.properties.is_it_free) {
      } else if (
        this.course &&
        this.course.access &&
        this.course.access.top &&
        this.course.access.top.includes(topic.id)
      ) {
      } else {
        return;
      }
    }
    // if (topic.user_course_topic && topic.user_course_topic.status == 0) {
    //   if (!this.enableQuizButton) {

    //   }  else {
    //     const bottomSheetRef = this._bottomSheet.open(TopicLockStatusSheet, {
    //       panelClass: 'im-sheet'
    //     });
    //     return;
    //   }
    // }
    this.enableQuizButton = false;
    this.processActions = false;
    this.activeTopic = topic;
    this.checkQuizExist();
    if (this.editor) {
      this.editor.render(this.activeTopic.description);
      this.processActions = true;
      this.router.navigate([], {
        queryParams: { topic: this.activeTopic.slug },
        relativeTo: this.route,
      });
    } else {
      this.processEditor();
    }
  }

  processEditor() {
    const that = this;
    let editorTools: any = {
      header: Header,
      list: List,
      paragraph: {
        class: Paragraph,
      },
      //image: SimpleImage,
      checklist: Checklist,
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            vimeo: {
              regex:
                /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
              embedUrl: environment.playerUrl + "<%= remote_id %>",
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
              id: ([id]) => {
                const params = [
                  "autoplay=false",
                  "autopause=true",
                  "muted=false",
                  "loop=false",
                  `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                  "poster=",
                  "time=true",
                  "progressBar=true",
                  "overlay=true",
                  "muteButton=true",
                  "fullscreenButton=true",
                  "style=light",
                  "quality=auto",
                  "playButton=true",
                  "pip=true",
                  "speedButton=true",
                ];
                return "?" + params.join("&");
              },
            },
            schoolforai: {
              regex:
                /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
              embedUrl:
                environment.playerUrl +
                "?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true",
              html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
              height: 300,
              width: 600,
            },
          },
        },
      },
      attaches: {
        class: AttachesTool,
        config: {
          //   endpoint: environment.mediaUrl+'/upload',
          //   field: 'file[0]',
          //   additionalRequestData: {
          //     upload_path: '/content',
          //     custom_attrs: [],
          //     random_names: false,
          //     modify_output: true
          //   },
          //   additionalRequestHeaders: {
          //    Authorization: `Bearer ${this.authService.getToken()}`
          //  },
        },
      },
      quote: Quote,
      table: Table,
      raw: RawTool,
      code: (<any>window).editorjsCodeflask,
      linkTool: LinkTool,
      marker: Marker,
      warning: Warning,
      inlineCode: {
        class: InlineCode,
        shortcut: "CMD+SHIFT+M",
      },
      image: {
        class: ImageTool,
      },
    };
    this.editor = new EditorJS({
      data: this.activeTopic.description,
      readOnly: true,
      tools: {
        ...editorTools,
        twoColumns: {
          class: window["EditorJSLayout"].LayoutBlockTool,
          config: {
            EditorJS,
            editorJSConfig: {
              tools: editorTools,
              readOnly: true,
            },
            enableLayoutEditing: true,
            enableLayoutSaving: true,
            readOnly: true,
            initialData: {
              itemContent: {
                1: {
                  blocks: [],
                },
                2: {
                  blocks: [],
                },
              },
              layout: {
                type: "container",
                id: "",
                className: "",
                style: "border: none;display:flex; padding:0",
                children: [
                  {
                    type: "item",
                    id: "",
                    className: "",
                    style: "border: none; padding:0",
                    itemContentId: "1",
                  },
                  {
                    type: "item",
                    id: "",
                    className: "",
                    style: "border: none;",
                    itemContentId: "2",
                  },
                ],
              },
            },
          },
          shortcut: "CMD+2",
          toolbox: {
            icon: `
              <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
              </svg>
            `,
            title: "2 columns",
          },
        },
      },
      onChange: () => {},
      onReady: () => {
        var elements = document.querySelectorAll("[contenteditable=true]");
        elements.forEach((element) => {
          element.setAttribute("contenteditable", "false");
        });
        window.addEventListener("message", function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + "%";
                } else if (data.event && data.event == "pip") {
                  //data.player.pause();
                  that.dialog.closeAll();
                  const dialogRef = that.dialog.open(PictureVideoComponent, {
                    data: {
                      url: data.url,
                      title: that.activeTopic.name,
                      cs: data.cs,
                    },
                    panelClass: "pip-model",
                    disableClose: true,
                    hasBackdrop: false,
                  });
                }
              }
            } catch (error) {}
          }
        });
        this.processActions = true;
      },
    });
  }

  checkQuizExist() {
    this.http
      .post("checkexist/course_questions", {
        refId: this.activeTopic.id,
        ref: "ChapterTopic",
        status: 1,
      })
      .subscribe((res) => {
        if (res.data.exist) {
          this.enableQuizButton = true;
        }
        console.log(res);
      });
  }

  getChapterTopics() {
    this.processingTopics = true;
    const that = this;
    this.http
      .get(
        'course_topic?recursive=0&filter="course_chapter_id eq ' +
          this.chapter.id +
          '"'
      )
      .subscribe(
        (res) => {
          this.chapterTopics = res.data;
          if (this.topicSlug) {
            this.activeTopic = this.chapterTopics.find(
              (topic) => topic.slug == this.topicSlug
            );
          }
          if (!this.activeTopic) {
            // const currentTopic = this.chapterTopics.find((item) => {
            //   return (
            //     item.user_course_topic &&
            //     item.user_course_topic.current_topic == 1
            //   );
            // });
            // if (currentTopic) {
            //   this.activeTopic = currentTopic;
            // } else {
            this.activeTopic = this.chapterTopics[0];
            //}
          }
          // if (!this.activeTopic.user_course_topic) {
          //   if (this.chapter.properties && this.chapter.properties.is_it_free) {
          //   } else {
          //     this.processingTopics = false;
          //     this.activeTopic = "";
          //     return;
          //   }
          // }
          this.processingTopics = false;
          this.checkQuizExist();
          setTimeout(() => {
            this.processEditor();
          }, -1);
        },
        (err) => {
          this.processingTopics = false;
        }
      );
  }

  quiz() {
    this.router.navigate([
      "/quiz",
      this.course.slug,
      this.module.slug,
      this.chapter.slug,
      this.activeTopic.slug,
    ]);
  }

  next() {
    this.http
      .post("user_course_topic", {
        id: this.activeTopic.user_course_topic.id,
        is_completed: 1,
      })
      .subscribe((res) => {
        this.activeTopic.user_course_topic.is_completed = 1;
        const currentIndex = this.chapterTopics.findIndex(
          (item) => item == this.activeTopic
        );
        if (currentIndex == this.chapterTopics.length - 1) {
        } else {
          const nextTopic = this.chapterTopics[currentIndex + 1];
          nextTopic.user_course_topic.status = 1;
          this.http
            .post("user_course_topic", {
              id: nextTopic.user_course_topic.id,
              status: 1,
            })
            .subscribe((res) => {
              this.makeActiveTopic(nextTopic);
            });
        }
      });
  }
}

@Component({
  selector: "im-topic-lock",
  templateUrl: "topic.lock.html",
})
export class TopicLockStatusSheet {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<TopicLockStatusSheet>
  ) {}

  buyNow(event: MouseEvent): void {
    this._bottomSheetRef.dismiss({ event: "buyNow" });
    event.preventDefault();
  }

  close(event: MouseEvent): void {
    this._bottomSheetRef.dismiss({ event: "close" });
    event.preventDefault();
  }
}
