import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { MatSidenavModule } from "@angular/material/sidenav";
import {
  BatchComponent,
  BottomSheetStudentSelectSheet,
  BottomSheetBatchCreateSheet,
  BottomSheetCreateUserSheet,
  BottomSheetTrainerSelectSheet,
} from "./batch/batch.component";
import { DetailsComponent } from "./details/details.component";
import { CreateComponent } from "./create/create.component";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
} from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";

import { TextFieldModule } from "@angular/cdk/text-field";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatChipsModule } from "@angular/material/chips";
import { MatCardModule } from "@angular/material/card";
import { Ng2SearchPipe } from "@core/utillities/pipes/filter.pipe";

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "l, LTS",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@NgModule({
  declarations: [
    Ng2SearchPipe,
    BatchComponent,
    DetailsComponent,
    CreateComponent,
    BottomSheetStudentSelectSheet,
    BottomSheetBatchCreateSheet,
    BottomSheetCreateUserSheet,
    BottomSheetTrainerSelectSheet,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    RouterModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatDatepickerModule,
    NgxMatNativeDateModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    TextFieldModule,
    MatBottomSheetModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatChipsModule,
    MatCardModule,
  ],
  exports: [],
  providers: [
    Ng2SearchPipe,
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  entryComponents: [
    BottomSheetStudentSelectSheet,
    BottomSheetBatchCreateSheet,
    BottomSheetCreateUserSheet,
    BottomSheetTrainerSelectSheet,
  ],
})
export class BatchModule {}
