import { Component, OnInit, OnChanges, ViewChild } from "@angular/core";
import { AuthService } from "@core/services/auth.service";
import { HttpService } from "@core/services/http.service";
import { ProfilePageService } from "./profile.service";
import { MatDrawer } from "@angular/material/sidenav";

@Component({
  selector: "im-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent implements OnInit, OnChanges {
  leftSide: any = false;
  rightSide: any = false;

  loginUser: any;

  projectDetails: any;

  @ViewChild(MatDrawer) sidenav: MatDrawer;

  constructor(
    private authService: AuthService,
    private http: HttpService,
    private profilePageService: ProfilePageService
  ) {}

  ngOnInit() {
    this.loginUser = this.authService.loginUser;
    this.profilePageService.initiateProject.subscribe((res) => {
      this.projectDetails = res.data;
      this.sidenav.open();
    });
  }
  ngOnChanges() {
    this.loginUser = this.authService.loginUser;
  }
}
