import { Component, OnInit, ViewChildren, AfterViewInit } from "@angular/core";
import { HttpService } from "../../../core/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "@environment/environment";
import moment from "moment-timezone";
import { AuthService } from "@core/services/auth.service";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import RawTool from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Checklist from "@editorjs/checklist";
import Embed from "../../../core/ui/editor/embed";
import Quote from "@editorjs/quote";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import CodeTool from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import Paragraph from "@editorjs/paragraph";
import InlineCode from "@editorjs/inline-code";
import EditorJS from "@editorjs/editorjs";
import AttachesTool from "../../../core/ui/editor/attaches";

@Component({
  selector: "im-long",
  templateUrl: "./long.component.html",
  styleUrls: ["./long.component.scss"],
})
export class LongComponent implements OnInit {
  editor: any;
  event_id: any;
  processing: boolean = true;
  event: any;
  surveySubmitted = false;

  tabIndex = 0;

  selectedIndex = 0;

  startEnableJoin: boolean = false;

  days = [];
  currentDay = moment(new Date().getTime()).tz("Asia/Kolkata").startOf("day");
  //moment = moment;
  loginUser: any;

  joinStatus: boolean[] = [false, false, false];

  currentActiveDayIndex = -1;

  notEnrolled = false;

  matStepperIcons: any[];

  allowUsertoJoinOnline = false;

  maticons = [
    "lightbulb",
    "play_circle_outline",
    "assessment",
    "event_available",
    "event_available",
    "card_membership",
  ];

  activeUploadItem: any;

  userTaks: any = {};

  modules = {
    hackthon: {
      types: [
        {
          title: "Introduction",
          short_description:
            "Let’s have a look at the ground rules for the program",
          icon: "intro",
          key: "hackthon_intro",
          user_tasks: false,
        },
        {
          title: "Resources",
          short_description: "Start your journey",
          icon: "videos",
          key: "hackthon_resources",
          user_tasks: false,
        },
        {
          title: "Submission",
          short_description:
            "Submitting your project correctly and on time is crucial",
          icon: "assessment",
          key: "hackthon_submission",
          user_tasks: true,
          items: [
            {
              type: "file",
              multiple: "Single",
              title: "Final Source",
            },
          ],
        },
      ],
    },
  };

  activeModule: any;

  typeOfEvent = "";

  resource_files: any = [];

  meetingWindow: any;

  constructor(
    private auth: AuthService,
    private http: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    if ("step" in this.route.snapshot.queryParams) {
      this.selectedIndex = this.route.snapshot.queryParams.step;
    }
    if (this.route.snapshot.paramMap.get("event_slug")) {
      this.event_id = this.route.snapshot.paramMap.get("event_slug");
      this.getEvent();
    } else {
      this.router.navigate(["/schedule"]);
    }
  }

  resources() {
    this.http
      .getExternal(
        environment.storageUrl +
          "events/hackathon/" +
          this.event_id +
          ".json?t=" +
          new Date().getTime()
      )
      .subscribe((res) => {
        this.resource_files = res;
      });
  }

  tabChange($event) {
    console.log($event);
    this.tabIndex = $event.selectedIndex;
    this.activeModule = this.modules[this.typeOfEvent].types[this.tabIndex];
    if (this.tabIndex == 0) {
      setTimeout(() => {
        this.processEditor();
      });
    }
    if (this.tabIndex == 2) {
      this.getEventTasks(this.activeModule);
    }
  }

  complete_step() {
    this.http
      .post(
        "user_event/" + this.event.user_event.id + "/event_program_user_track",
        { step: this.tabIndex }
      )
      .subscribe((res) => {
        this.event.user_event = res.data;
      });
  }

  surveySubmitStatus($event) {
    this.surveySubmitted = $event;
  }

  // join() {
  //   let data: any = { type: "day" };
  //   data["day"] = this.tabIndex - 2;
  //   this.http
  //     .post(
  //       "user_event/" + this.event.user_event.id + "/event_program_user_track",
  //       data
  //     )
  //     .subscribe((res) => {
  //       this.event.user_event = res.data;
  //     });
  //   let a = document.createElement("a");
  //   a.target = "_blank";
  //   a.href = this.event.link;
  //   a.click();
  // }

  googleLink() {
    if (this.startEnableJoin) {
      if (this.meetingWindow == null || this.meetingWindow.closed) {
        this.meetingWindow = window.open(
          this.event.link,
          "Google Meet - SchoolforAI",
          "directories=0,titlebar=0,toolbar=0,location=0,status=0,width=650,height=350,scrollbars=1"
        );
      } else {
        this.meetingWindow.focus();
      }
    }
  }

  join() {
    if (this.event.link.includes("meet.google.com")) {
      this.googleLink();
      return;
    } else {
      // const dialogRef = this.dialog.open(OnlineComponent, {
      //   width: '94vw',
      //   maxWidth: '94vw',
      //   minHeight: '100vh',
      //   data: this.activeEvent,
      //   panelClass: 'online-section',
      //   disableClose: true,
      //   hasBackdrop: false,
      // });
      let role = 0;
      if (this.loginUser.user_type == "trainer") {
        role = 1;
      }
      const that = this;
      this.http
        .postExternal("https://zoom.cedlearn.com/", {
          meetingNumber: this.event.meetingId,
          role,
        })
        .subscribe((res) => {
          let meetingConfig = {
            mn: that.event.meetingId,
            name: that.loginUser.name,
            role,
            signature: res.signature,
            apiKey: res.api_key,
            pwd: that.event.link_pwd,
            email: "",
            //lu: this.b64EncodeUnicode(this.document.location.href)
          };

          const rootElement = document.getElementById("ZoomEmbeddedApp");
          const zmClient: any = (<any>window).ZoomMtgEmbedded.createClient();

          zmClient
            .init({
              debug: true,
              zoomAppRoot: rootElement,
              // assetPath: 'https://websdk.zoomdev.us/2.0.0/lib/av', //default
              webEndpoint: "zoom.us",
              language: "en-US",
              customize: {
                meetingInfo: [this.event.name],
                toolbar: {
                  buttons: [
                    // {
                    //   text: "CustomizeButton",
                    //   className: "CustomizeButton",
                    //   onClick: () => {
                    //     console.log("click Customer Button");
                    //   },
                    // },
                  ],
                },
              },
            })
            .then((e) => {
              console.log("success", e);
            })
            .catch((e) => {
              console.log("error", e);
            });

          // WebSDK Embedded join
          zmClient
            .join({
              sdkKey: meetingConfig.apiKey,
              signature: meetingConfig.signature,
              meetingNumber: meetingConfig.mn,
              userName: meetingConfig.name,
              password: meetingConfig.pwd,
              userEmail: meetingConfig.email,
            })
            .then((e) => {
              console.log("success", e);
            })
            .catch((e) => {
              console.log("error", e);
            });
        });
    }
  }
  downloadCer() {}

  getEvent() {
    this.processing = true;

    this.http.get("workshops/" + this.event_id).subscribe((res) => {
      this.event = res.data;
      this.typeOfEvent = "hackthon";
      this.resources();
      if (this.event.user_event) {
        let daysDiff = moment
          .tz(this.event.event_end_date, "Asia/Kolkata")
          .diff(moment.tz(this.event.event_start_date, "Asia/Kolkata"), "days");
        for (let i = 0; i <= daysDiff; i++) {
          let temp = moment
            .tz(this.event.event_start_date, "Asia/Kolkata")
            .add(i, "days")
            .startOf("day");
          if (temp.diff(this.currentDay) == 0) {
            this.currentActiveDayIndex = i;
          }
          this.days.push(temp);
          let start_date_temp = temp.format("YYYY-MM-DD");
          if (
            moment(new Date().getTime())
              .tz("Asia/Kolkata")
              .diff(
                moment.tz(
                  start_date_temp + " " + this.event.event_end_time,
                  "Asia/Kolkata"
                )
              ) > 0
          ) {
            this.joinStatus[i] = false;
          } else {
            this.joinStatus[i] = true;
          }
        }
        let start_date = this.event.event_start_date;
        if (this.currentActiveDayIndex > -1) {
          start_date =
            this.days[this.currentActiveDayIndex].format("YYYY-MM-DD");
        }
        // if (moment(new Date().getTime()).tz('Asia/Kolkata').diff(moment.tz(start_date + ' ' + this.event.event_end_time, 'Asia/Kolkata')) > 0) {
        //   this.joinStatus = false;
        // } else {
        //   this.joinStatus = true;
        // }

        if (
          moment(new Date().getTime())
            .tz("Asia/Kolkata")
            .diff(
              moment.tz(
                start_date + " " + this.event.event_start_time,
                "Asia/Kolkata"
              )
            ) > -600000
        ) {
          this.startEnableJoin = true;
        } else {
          this.startEnableJoin = false;
          setTimeout(
            () => {
              this.startEnableJoin = true;
            },
            -moment(new Date().getTime())
              .tz("Asia/Kolkata")
              .diff(
                moment.tz(
                  start_date + " " + this.event.event_start_time,
                  "Asia/Kolkata"
                )
              )
          );
        }
        if ("step" in this.route.snapshot.queryParams) {
          this.selectedIndex = this.route.snapshot.queryParams.step;
          this.tabIndex = this.route.snapshot.queryParams.step;
        }

        this.modules[this.typeOfEvent].types.forEach((type) => {
          if (type.user_tasks) {
            this.getEventTasks(type);
          }
        });

        this.http
          .get("workshop_user_surveys?filter=event_id eq " + this.event.id)
          .subscribe((res) => {
            if (res.data.length > 0) {
              this.surveySubmitted = true;
            }
            this.processing = false;
            if (this.selectedIndex == 0) {
              setTimeout(() => {
                this.processEditor();
              });
            }
          });
      } else {
        if (this.event.event_type == "course_enrolled_users") {
          this.http.post("checkexist/user_course", {}).subscribe((res) => {
            this.allowUsertoJoinOnline = res.data.is_it_online ? true : false;
            if (this.allowUsertoJoinOnline) {
              if (
                moment(new Date().getTime())
                  .tz("Asia/Kolkata")
                  .diff(
                    moment.tz(
                      this.event.event_start_date +
                        " " +
                        this.event.event_start_time,
                      "Asia/Kolkata"
                    )
                  ) > -600000
              ) {
                this.startEnableJoin = true;
              } else {
                this.startEnableJoin = false;
                setTimeout(
                  () => {
                    this.startEnableJoin = true;
                  },
                  -moment(new Date().getTime())
                    .tz("Asia/Kolkata")
                    .diff(
                      moment.tz(
                        this.event.event_start_date +
                          " " +
                          this.event.event_start_time,
                        "Asia/Kolkata"
                      )
                    )
                );
              }
            }
            this.notEnrolled = false;
            this.processing = false;

            if (this.selectedIndex == 0) {
              setTimeout(() => {
                this.processEditor();
              });
            }
          });
        } else {
          this.notEnrolled = true;
          this.processing = false;
        }
      }

      // if (this.selectedIndex == 0)
      //   this.processEditor();
    });
  }

  back(): void {
    this.router.navigate(["/schedule"]);
  }

  processEditor() {
    const that = this;
    this.editor = new EditorJS({
      data: this.event.intro,
      readOnly: true,
      tools: {
        header: Header,
        list: List,
        paragraph: {
          class: Paragraph,
        },
        //image: SimpleImage,
        checklist: Checklist,
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              vimeo: {
                regex:
                  /(?:http[s]?:\/\/)?(?:www.)?(?:player.)?vimeo\.co(?:.+\/([^\/]\d+)(?:#t=[\d]+)?s?$)/,
                embedUrl: environment.playerUrl + "<%= remote_id %>",
                html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allow='autoplay;fullscreen' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
                height: 300,
                width: 600,
                id: ([id]) => {
                  const params = [
                    "autoplay=false",
                    "autopause=true",
                    "muted=false",
                    "loop=false",
                    `url=https%3A%2F%2Fvimeo.com%2F${id}`,
                    "poster=",
                    "time=true",
                    "progressBar=true",
                    "overlay=true",
                    "muteButton=true",
                    "fullscreenButton=true",
                    "style=light",
                    "quality=auto",
                    "playButton=true",
                    "pip=true",
                  ];
                  return "?" + params.join("&");
                },
              },
              schoolforai: {
                regex:
                  /https?:\/\/(?:s\.)?schoolforai.com\/static\/videos\/(\w+.(mp4))/,
                embedUrl:
                  environment.playerUrl +
                  "?autoplay=false&autopause=false&muted=false&loop=true&url=https%3A%2F%2Fdev.schoolforai.com%2Fstatic%2Fvideos%2F<%= remote_id %>&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true",
                html: "<iframe class='im-embed-videos' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;'></iframe>",
                height: 300,
                width: 600,
              },
            },
          },
        },
        attaches: {
          class: AttachesTool,
          config: {
            //   endpoint: environment.mediaUrl+'/upload',
            //   field: 'file[0]',
            //   additionalRequestData: {
            //     upload_path: '/content',
            //     custom_attrs: [],
            //     random_names: false,
            //     modify_output: true
            //   },
            //   additionalRequestHeaders: {
            //    Authorization: `Bearer ${this.authService.getToken()}`
            //  },
          },
        },
        quote: Quote,
        table: Table,
        raw: RawTool,
        code: CodeTool,
        linkTool: LinkTool,
        marker: Marker,
        warning: Warning,
        inlineCode: {
          class: InlineCode,
          shortcut: "CMD+SHIFT+M",
        },
        image: {
          class: ImageTool,
        },
      },
      onChange: () => {},
      onReady: () => {
        window.addEventListener("message", function (e) {
          if (e.data) {
            try {
              let data = JSON.parse(e.data);
              console.log(data);
              let iframe = document.querySelector(
                'iframe[src="' + data.url + '"]'
              );
              if (iframe) {
                if (data.ratio) {
                  iframe.parentElement.style.paddingTop = data.ratio + "%";
                } else if (data.event && data.event == "pip") {
                  //data.player.pause();
                }
              }
            } catch (error) {}
          }
        });
      },
    });
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }

  uploadDocument(submitFile) {
    this.activeUploadItem = this.activeModule.items[0];
    submitFile.click();
  }

  uploadSource(e) {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      this.upload(e.target.files[0]);
    }
    e.target.value = "";

    // for (var i = 0; i < e.target.files.length; i++) {
    //   //this.sourceList.push({name: e.target.files[i].name});
    //   this.upload(e.target.files[i], (this.sourceList.length-1));
    // }
  }

  getEventTasks(module) {
    this.http
      .get(
        "user_tasks?filter=ref eq 'User/Event' and status eq 2 and refId eq " +
          this.event.user_event.id +
          " and module eq '" +
          module.key +
          "'"
      )
      .subscribe((res) => {
        res.data.forEach((element) => {
          let tempKey = element.title.split(".")[0];
          if (!this.userTaks[module.key]) this.userTaks[module.key] = {};
          this.userTaks[module.key][tempKey] = element;
        });
        this.userTaks = { ...this.userTaks };
        console.log(this.userTaks);
      });
  }

  upload(source) {
    let that = this;
    let ajaxData = new FormData();
    ajaxData.append("file[0]", source);
    var reader = new FileReader();

    reader.readAsDataURL(source);
    ajaxData.append(
      "upload_path",
      "/user/events/" +
        this.loginUser.id +
        "/" +
        this.event.user_event.id +
        "/" +
        this.activeModule.key +
        "/" +
        this.activeUploadItem.title
    );
    ajaxData.append("custom_attrs", "[]");
    ajaxData.append("random_names", "false");
    this.http.upload("media/upload", ajaxData).subscribe((res: any) => {
      if (res.length == 1 && res[0].success) {
        let task_data = {
          title: this.activeUploadItem.title + ". " + this.event.name,
          ref: "User/Event",
          status: 2,
          user_id: this.loginUser.id,
          refId: this.event.user_event.id,
          module: this.activeModule.key,
          upload_attacment:
            "/user/events/" +
            this.loginUser.id +
            "/" +
            this.event.user_event.id +
            "/" +
            this.activeModule.key +
            "/" +
            this.activeUploadItem.title +
            "/" +
            res[0].file_name,
        };

        this.http.post("user_tasks", task_data).subscribe(
          (res) => {
            //this.activeUploadItem.submittedTask = res.data;
            if (!this.userTaks[this.activeModule.key])
              this.userTaks[this.activeModule.key] = {};
            this.userTaks[this.activeModule.key][this.activeUploadItem.title] =
              res.data;
            this.userTaks = { ...this.userTaks };
          },
          (err) => {}
        );
      }
    });
  }
}
