<div
  class="h-screen mx-auto my-auto lg:w-32vw xs:w-full md:w-full"
  *ngIf="showView"
>
  <div
    class="flex flex-column p-12 justify-center h-full xs:py-6"
    [ngClass]="{ 'im-login-center': isItComonent }"
  >
    <im-auth-avatar type="email" emailId="#authEmail"></im-auth-avatar>

    <div class="mb-8">
      <h3 class="mb-1">Welcome,</h3>
      <p>
        Congratulations on taking the first step towards wholistic learning.
        Let's get you started...
      </p>
    </div>

    <form>
      <mat-form-field class="w-full" appearance="outline" floatLabel="always">
        <mat-label>EMAIL ADDRESS</mat-label>
        <input
          matInput
          placeholder="Ex: myemail@domain.com"
          [formControl]="emailFormControl"
          color="#fff"
          type="email"
          maxlength="254"
          id="authEmail"
          #emailAddressInput
          (keyup.enter)="next()"
        />
        <mat-icon matSuffix class="font-lg">alternate_email</mat-icon>
        <mat-error *ngIf="emailFormControl.hasError('required')" class="mb-2">
          Email is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('email')" class="mb-2">
          Enater a valid email address
        </mat-error>
      </mat-form-field>
      <div class="action-items mt-10">
        <button
          mat-stroked-button
          color="primary"
          (click)="next()"
          [disabled]="processing"
          class="w-full im-button"
          type="button"
        >
          <div class="flex justify-between items-center">
            <span>{{ processing ? "Processing..." : "NEXT" }}</span>
            <mat-icon>east</mat-icon>
          </div>
        </button>
      </div>
    </form>
  </div>
</div>
