<div class="container pl-6 pt-6 h-screen overflow-y-auto">
  <div class="row mb-2">
    <div class="col-xs-6">
      <h3>{{ batch.name }} - Attendance & Participation</h3>
    </div>
  </div>
  <div class="row" id="attendance-and-participation">
    <div class="calendar">
      <div class="table-responsive">
        <table class="table" style="width: 200%">
          <tr>
            <!-- number of days -->
            <th colspan="66">
              <div class="table-header">
                <a class="prev-month"></a>
                <span class="current-month">October 1st - October 26th</span>
                <a class="next-month"></a>
              </div>
            </th>
          </tr>

          <tr>
            <th>&nbsp;</th>
            <th colspan="5">
              <div class="table-small-header v-align">Week1</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week2</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week3</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week4</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week5</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week6</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week7</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week8</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week9</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week10</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week11</div>
            </th>
            <th colspan="5">
              <div class="table-small-header v-align">Week12</div>
            </th>
          </tr>
          <tr>
            <th class="bold">Student Name</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>8</th>
            <th>9</th>
            <th>10</th>
            <th>11</th>
            <th>12</th>
            <th>15</th>
            <th>16</th>
            <th>17</th>
            <th>18</th>
            <th>19</th>
            <th>22</th>
            <th>23</th>
            <th>24</th>
            <th>25</th>
            <th>26</th>
            <th>27</th>
            <th>28</th>
            <th>29</th>
            <th>30</th>
            <th>31</th>
            <th>32</th>
            <th>33</th>
            <th>34</th>
            <th>35</th>
            <th>36</th>
            <th>37</th>
            <th>38</th>
            <th>39</th>
            <th>40</th>
            <th>41</th>
            <th>42</th>
            <th>43</th>
            <th>44</th>
            <th>45</th>
            <th>46</th>

            <th>47</th>
            <th>48</th>
            <th>49</th>
            <th>50</th>
            <th>51</th>

            <th>52</th>
            <th>53</th>
            <th>54</th>
            <th>55</th>
            <th>56</th>

            <th>57</th>
            <th>58</th>
            <th>59</th>
            <th>60</th>
            <th>61</th>

            <th>62</th>
            <th>63</th>
            <th>64</th>
            <th>65</th>
            <th>66</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
