<div
  class="md:grid has-12-columns has-1-rows justify-items-stretch h-screen overflow-y-auto course-detail-page-wrapper relative"
  *ngIf="!processing">
  <div class="main column-start-1 row-span-1 pb-12" style="background: rgb(248, 250, 253)"
    [ngClass]="{'column-end-7': (course.user_course || loginUser?.user_type == 'trainer'), 'column-end-8': (!course.user_course && loginUser?.user_type != 'trainer')}">
    <div class="w-full cover-image bg-no-repeat course-header" [style.background-image]="course.cover_image_pb">
      <div class="play" *ngIf="course.properties.courseIntroVideo">
        <button mat-icon-button><img src="assets/images/play.gif" /></button>
      </div>
      <!-- <div class="flex top-0 justify-end course-level-wrapper">
            <div class="flex course-level flex-column cursor-pointer" [ngClass]="{'active': (course.properties.level==1)}" routerLink="../foundation-for-ai">
              <span>LEVEL</span><span class="level-1">1</span>
            </div>
            <div class="flex course-level flex-column cursor-pointer" [ngClass]="{'active': (course.properties.level==2)}" routerLink="../data-science">
              <span>LEVEL</span><span class="level-2">2</span>
            </div>
            <div class="flex course-level flex-column cursor-pointer" [ngClass]="{'active': (course.properties.level==3)}" routerLink="../artificial-intelligence">
              <span>LEVEL</span><span class="level-3">3</span>
            </div>
          </div> -->
      <!-- <div class="content flex top-0 justify-end course-level flex-column">
              <span>LEVEL</span><span class="level-{{course.properties.level}}">{{course.properties.level}}</span>
          </div> -->
      <div class="content flex justify-content-space-between align-items-center">
        <button mat-icon-button class="icon" aria-label="Course Intro" class="course_intro" (click)="courseIntro()">
          <mat-icon>play_circle_outline</mat-icon>
        </button>
        <div class="">
          <h1>{{ course.name }}</h1>
          <div class="flex mt-1 course-more-info">
            <span class="font-xss">{{ course.properties.duration }} {{
              course.properties.durationUnit }}</span>
            <span class="dot-separator">.</span>
            <span class="font-xss">{{ course.properties.sessions }} Sessions</span>
            <span class="dot-separator">.</span>
            <span class="font-xss">{{ course.properties.projectSessions }} Projects</span>
          </div>
        </div>
        <ng-container *ngIf="!course.user_course">
          <ng-container *ngIf="!platform.IOS">
            <button mat-stroked-button class="letter-spacing-15 text-uppercase flex buy-now" (click)="buyNow(course)">
              Buy Now
            </button>
          </ng-container>
        </ng-container>
        <!-- <ng-container *ngIf="course.user_course">
          <div class="w-1/4">
            <h5 class="mb-2">Course Progress:</h5>
            <mat-progress-bar
              class=""
              color="course{{ course.id }}"
              mode="determinate"
              value="0"
            ></mat-progress-bar>
          </div>
        </ng-container> -->
      </div>
    </div>
    <div class="course-content">
      <ng-container *ngIf="!course.user_course && !noTabs">
        <mat-tab-group>
          <mat-tab label="About">
            <div class="px-4 pt-4 course-about-section">
              <div id="editorjs"></div>
            </div>
          </mat-tab>
          <mat-tab label="Curriculum">
            <div class="px-4 pt-4">
              <div class="chapters-section">
                <ng-container *ngFor="let module of courseModules">
                  <div class="mt-4" style="background-color: #fff">
                    <mat-toolbar class="course{{ course.id }}">
                      <div class="flex items-center">
                        <div class="module-icon" *ngIf="module.icon_p">
                          <img [src]="module.icon_p" width="" height="" class="border-radius-full" />
                        </div>
                        <span class="font-sm letter-spacing-10">
                          {{ module.name }}</span>
                      </div>
                      <!-- <span class="im-spacer"></span> -->
                      <!-- <button mat-icon-button class="icon" aria-label="Module icon" *ngIf="module.icon_p">
                          <img [src]="module.icon_p" width="24" height="24"/>
                        </button> -->
                    </mat-toolbar>
                    <div class="topics py-4 border border-width-1 border-color-whitesmoke">
                      <p class="px-4 pb-2 font-light color-secondary" *ngIf="module.short_description">
                        {{ module.short_description }}
                      </p>
                      <ul>
                        <li *ngFor="let chapter of module.chapter" class="flex">
                          <div *ngIf="
                              !chapter?.properties?.is_it_free &&
                              !course.user_course
                            " class="flex items-center">
                            <ng-container *ngIf="
                                course.access &&
                                  course.access?.chap &&
                                  course.access.chap.includes(chapter.id);
                                else lockedChapter
                              ">
                              <button mat-icon-button class="icon lock-icon" aria-label="Chapter is Unlocked">
                                <mat-icon>play_circle_outline</mat-icon>
                              </button>
                              <a routerLink="/course/{{ course.slug }}/{{
                                  module.slug
                                }}/{{ chapter.slug }}">
                                {{ chapter.title }}</a>
                            </ng-container>
                            <ng-template #lockedChapter>
                              <button mat-icon-button class="icon lock-icon" aria-label="Chapter is Locked"
                                (click)="openLockStatus()">
                                <mat-icon>lock_outline</mat-icon>
                              </button>
                              {{ chapter.title }}
                            </ng-template>
                          </div>

                          <span class="im-spacer"></span>
                        </li>

                        <li *ngFor="let chapter of module.chapter" class="flex">
                          <div *ngIf="
                              chapter?.properties?.is_it_free ||
                              course?.user_course
                            " class="flex items-center">
                            <button mat-icon-button class="icon lock-icon" aria-label="Chapter is Unlocked">
                              <mat-icon>play_circle_outline</mat-icon>
                            </button>
                            <a routerLink="/course/{{ course.slug }}/{{
                                module.slug
                              }}/{{ chapter.slug }}">
                              {{ chapter.title }}</a>
                          </div>
                          <span class="im-spacer"></span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
      <!-- <ng-container *ngIf="course.user_course"> -->
      <div class="px-4 pt-4">
        <p style="line-height: 1.8em">
          {{ course.description || course.short_description }}
        </p>
        <div class="chapters-section">
          <ng-container *ngFor="let module of courseModules">
            <div class="mt-4" style="background-color: #fff">
              <mat-toolbar class="course{{ course.id }}">
                <div class="flex items-center">
                  <div class="module-icon" *ngIf="module.icon_p">
                    <img [src]="module.icon_p" width="" height="" class="border-radius-full" />
                  </div>
                  <span class="font-sm letter-spacing-10">
                    {{ module.name }}</span>
                </div>
                <!-- <span class="im-spacer"></span> -->
                <!-- <button mat-icon-button class="icon" aria-label="Module icon" *ngIf="module.icon_p">
                      <img [src]="module.icon_p" width="24" height="24"/>
                    </button> -->
              </mat-toolbar>
              <div class="topics py-4 border border-width-1 border-color-whitesmoke">
                <p class="px-4 pb-2 font-light color-secondary" *ngIf="module.short_description">
                  {{ module.short_description }}
                </p>
                <ul>
                  <li *ngFor="let chapter of module.chapter" class="flex">
                    <div *ngIf="
                        !chapter?.properties?.is_it_free && !course.user_course
                      " class="flex items-center">
                      <ng-container *ngIf="
                          course.access &&
                            course.access?.chap &&
                            course.access.chap.includes(chapter.id);
                          else lockedChapter
                        ">
                        <button mat-icon-button class="icon lock-icon" aria-label="Chapter is Unlocked">
                          <mat-icon>play_circle_outline</mat-icon>
                        </button>
                        <a routerLink="/course/{{ course.slug }}/{{
                            module.slug
                          }}/{{ chapter.slug }}">
                          {{ chapter.title }}</a>
                      </ng-container>
                      <ng-template #lockedChapter>
                        <button mat-icon-button class="icon lock-icon" aria-label="Chapter is Locked"
                          (click)="openLockStatus()">
                          <mat-icon>lock_outline</mat-icon>
                        </button>
                        {{ chapter.title }}
                      </ng-template>
                    </div>
                    <div *ngIf="
                        chapter?.properties?.is_it_free || course?.user_course
                      " class="flex items-center">
                      <button mat-icon-button class="icon lock-icon" aria-label="Chapter is Unlocked">
                        <mat-icon>play_circle_outline</mat-icon>
                      </button>
                      <a routerLink="/course/{{ course.slug }}/{{
                          module.slug
                        }}/{{ chapter.slug }}">
                        {{ chapter.title }}</a>
                    </div>
                    <span class="im-spacer"></span>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- </ng-container> -->
    </div>
  </div>

  <div class="buy-section row-span-1 column-end-12 my-2 mr-4 ml-4 xxl:ml-4 xxl:fixed"
    [ngClass]="{'column-start-8': (course.user_course || loginUser?.user_type == 'trainer'), 'column-start-9': (!course.user_course && loginUser?.user_type != 'trainer')}"
    [ngStyle]="(!course.user_course && loginUser?.user_type != 'trainer') && {'width': 'calc(((100vw - 59px) / 12) * 3.5)'}">
    <ng-container *ngIf="course.user_course || loginUser?.user_type == 'trainer'">
      <h2 class="xxl:mt-2 text-uppercase letter-spacing-15 font-semi-bold">
        Explore
      </h2>
      <p class="color-secondary">We train and prepare you for your career.</p>
      <div class="flex flex-wrap justify-between mr-4 xs:mr-0">
        <div class="alert mt-4 cursor-pointer border" style="width: 48.5%" [ngStyle]="{'border-color': item?.bcolor}"
          *ngFor="let item of course.properties?.user_course; let i =index" [routerLink]="urlManipulation(item.url)">
          <div class="alert-title flex justify-between items-center">
            {{item.title}}
            <div [ngStyle]="{'color': item?.bcolor}" class="relative link-icon">
              <svg _ngcontent-ng-c1649267442="" xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                fill="currentColor">
                <path _ngcontent-ng-c1649267442=""
                  d="M.41 12.649C-1.78 5.166 5.166-1.781 12.65.41l4.579 1.342c1.81.53 3.734.53 5.544 0L27.352.41C34.833-1.78 41.781 5.166 39.59 12.65l-1.342 4.579a9.864 9.864 0 0 0 0 5.544l1.342 4.58c2.191 7.482-4.756 14.43-12.239 12.238l-4.578-1.342a9.864 9.864 0 0 0-5.546 0L12.65 39.59C5.166 41.78-1.781 34.834.41 27.35l1.342-4.578c.53-1.81.53-3.735 0-5.546L.41 12.65Z">
                </path>
              </svg>
              <mat-icon class="absolute right-5 top-5" style="color: #272727">arrow_outward</mat-icon>
            </div>
          </div>
          <div class="alert-subtitle color-secondary">{{item.short}}</div>
          <!-- <button
            class="search-buttons"
            [routerLink]="urlManipulation(item.url)"
            [ngStyle]="{'background-color': item?.buttonBColor, 'color': item?.buttonTColor}"
          >
            Explore Now
          </button> -->
        </div>
      </div>

      <!-- <div class="co-ordinator">
        <div class="card">
          <div class="additional mat-course{{course.id}}-button">
            <div class="user-card">
              <img src="assets/images/2222.png" />
            </div>
            <div class="more-info">
              <h2>Chandramouli Logisa</h2>
              <div class="stats">
                <div>
                  <div class="title">Email</div>
                  <div class="value"><a href="mailto:chandu@ceduratech.com">chandu@ceduratech.com</a></div>
                </div>

                <div>
                  <div class="title">Phone</div>
                  <div class="value infinity"><a href="tel:+91-8977944954">+91-8977944954</a></div>
                </div>
              </div>
            </div>
          </div>
          <div class="general">
            <h2>Chandramouli Logisa</h2>
            <p class="font-xs mt-1 color-secondary">Dynamic and aspiring data scientist with experiencing in guiding
              students towards their learning goals.</p>
            <span class="more">Mouse over the card for more info</span>
          </div>
        </div>
      </div> -->

      <!-- <mat-card class="ask-query mt-6 mat-elevation-z0" style="background: #e8f2ff;">
        <mat-card-header>
          <mat-card-title class="font-base mb-1">Ask your Question</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p class="color-secondary font-xss">
            Dynamic and aspiring data scientist with experiencing in guiding students towards their learning goals.
          </p>
          <div class="mt-3 flex">
            <form class="w-full">
              <mat-form-field appearance="outline" class="w-full" color="white">
                <textarea matInput placeholder="" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"></textarea>
              </mat-form-field>
              <div class="float-left">
                <button mat-raised-button color="primary">Submit</button>
              </div>
            </form>
          </div>
        </mat-card-content>
      </mat-card> -->
    </ng-container>

    <ng-container *ngIf="!course.user_course && loginUser?.user_type != 'trainer'">
      <div class="md:grid has-1-columns has-3-rows column-gap-20">
        <ng-container *ngIf="course.properties.key_features">
          <mat-card class="mat-card border-radius-lg course-border mb-3 xxl:mb-4 mat-elevation-z3">
            <mat-card-title class="font-lg mb-0 course-highlights-item key_features">
              <span class="ml-6">Key Features</span>
            </mat-card-title>
            <mat-card-content>
              <ul class="color-primary pl-8 pt-3 line-height-15rem font-xs">
                <li *ngFor="let item of course.properties.key_features" class="list-unstyled flex align-items mb-2">
                  <mat-icon class="mat-icon material-icons-round material-icons mr-2">task_alt</mat-icon>{{ item }}
                </li>
              </ul>
            </mat-card-content>
          </mat-card>
        </ng-container>
        <ng-container *ngIf="course.properties.brochure">
          <div class="download_brochure flex w-full items-center justify-content-center">
            <a class="btn"><b>DOWNLOAD BROCHURE</b>
              <div></div>
            </a>

            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
              <defs>
                <filter id="goo">
                  <feGaussianBlur in="SourceGraphic" stdDeviation="12" result="blur" />
                  <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                    result="goo" />
                  <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                </filter>
              </defs>
            </svg>
            <svg class="svg" viewBox="0 0 400 400">
              <defs>
                <filter id="duotone-filter-post-one">
                  <feColorMatrix type="matrix"
                    values="0.14453125 0 0 0 0.33203125 0.71875 0 0 0 0.27734375 -0.34765625 0 0 0 0.73046875 0 0 0 1 0">
                  </feColorMatrix>
                </filter>
              </defs>
            </svg>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>