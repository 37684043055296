import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@core/services/guards/auth-guard.service';
import { RoleGuardService } from '@core/services/guards/role-guard.service';
import { BatchComponent } from './batch/batch.component';
import { DetailsComponent } from './details/details.component';
import { CreateComponent } from './create/create.component';


export const batchRoutes: Routes = [
    {
        path: 'batches', component: BatchComponent, canActivate: [AuthGuard, RoleGuardService], children: [
            { path: ':id', component: DetailsComponent },
            { path: 'create', component: CreateComponent }
        ],
        data: {
            expectedRole : 'trainer'
        }
    }
];


