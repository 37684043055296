import { Component, OnInit, Inject } from "@angular/core";
import { HttpService } from "@core/services/http.service";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import moment from "moment";
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { environment } from "@environment/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "@core/services/auth.service";

@Component({
  selector: "im-batch",
  templateUrl: "./batch.component.html",
  styleUrls: ["./batch.component.scss"],
})
export class BatchComponent implements OnInit {
  originalCourses: any = [];

  processing: boolean = true;

  activeBatch: any;

  activeBatchIndex: number = -1;

  batches: any;

  editBatchGroup: FormGroup;

  initiateEditFlag: boolean = false;

  initiateattendanceFlag: boolean = false;

  currentDate = moment().format("YYYY-MM-DD");

  enrolledUsers = [];

  fetchingStudents: boolean = false;

  activeStudent: any;

  activeStudentIndex: number = -1;

  Object: any = Object;

  activeStudentFeeDetails = [];

  activeStudentFeeGroup: FormGroup;

  courseAssignments: any;

  studentSearchKEy = "";

  loginUser: any;

  mainTabSelection = 0;

  constructor(
    private http: HttpService,
    private fb: FormBuilder,
    private _bottomSheet: MatBottomSheet,
    protected sanitizer: DomSanitizer,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
    this.getOriginalCourses();
    this.getBatches();
    this.fetchEnrolledUsers();
  }

  fetchEnrolledUsers() {
    this.http
      .get(
        "user_courses?fields=id,user_id,course_id,status&order_by=id&order=desc"
      )
      .subscribe((res) => {
        this.enrolledUsers = res.data;
      });
  }

  getOriginalCourses() {
    this.http.get("course?fields=id,name,slug").subscribe((res) => {
      this.originalCourses = res.data;
      this.processing = false;
    });
  }

  openBatch(batch, index) {
    this.activeStudent = "";
    this.activeBatchIndex = index;
    this.activeBatch = batch;
    this.fetchBatchStudents();
  }
  openStudent(student, index) {
    this.courseAssignments = null;
    this.activeStudentFeeDetails = [];
    this.activeBatch = "";
    this.activeStudent = student;
    this.activeStudentIndex = index;
    this.getUserInfo();
    this.activeStudentFeeGroup = this.fb.group({
      id: [""],
      details: this.fb.array([]),
    });
    this.getAssignments();
  }

  batchStudentTab($event) {
    this.mainTabSelection = $event.index;
    console.log($event);
    this.activeBatch = "";
    this.activeStudent = "";
  }

  getBatches() {
    this.http.get("batches").subscribe((res) => {
      this.batches = res.data;
      this.processing = false;
    });
  }

  getAssignments() {
    this.http
      .getExternal(
        environment.storageUrl +
          "assignments/" +
          this.activeStudent.mcourse.slug +
          ".json?t=" +
          new Date().getTime()
      )
      .subscribe((res) => {
        this.courseAssignments = res;
        res.categories.forEach((catItem, index) => {
          let temp = 0;
          res[catItem.key].forEach((rcaItem) => {
            temp += rcaItem.items.length;
          });
          this.courseAssignments.categories[index].totalAssignments = temp;
        });
        this.activeStudent.userTaks = {};
        this.http
          .get(
            "user_tasks?filter=ref eq 'Course/Assignment' and status eq 2 and refId eq " +
              this.activeStudent.mcourse.id +
              "&ua=" +
              this.activeStudent.user.id
          )
          .subscribe((res) => {
            res.data.forEach((element) => {
              let tempKey = element.title.split(".")[0];
              if (!this.activeStudent.userTaks[element.module])
                this.activeStudent.userTaks[element.module] = {};
              this.activeStudent.userTaks[element.module][tempKey] = element;
            });
            this.activeStudent = { ...this.activeStudent };
          });
      });
  }

  updateBatch(drawer) {
    console.log(this.editBatchGroup);
    if (!this.editBatchGroup.valid) return;
    let data = this.editBatchGroup.value;
    console.log(data);
    if (data.batch_start_date)
      data.batch_start_date = moment(data.batch_start_date).format(
        "YYYY-MM-DD"
      );
    if (data.batch_time)
      data.batch_time = moment(data.batch_time).format("HH:mm");
    if (data.batch_end_date)
      data.batch_end_date = moment(data.batch_end_date).format("YYYY-MM-DD");
    this.http.post("batches", data).subscribe((res) => {
      this.editBatchGroup.reset();
      this.activeBatch = res.data;
      this.batches[this.activeBatchIndex] = res.data;
      drawer.toggle();
      this.initiateEditFlag = false;
    });
  }

  initAttendance(drawer) {
    this.initiateEditFlag = false;
    this.initiateattendanceFlag = true;
    drawer.toggle();
  }

  initEdit(drawer) {
    this.initiateattendanceFlag = false;
    let time;
    if (this.activeBatch.batch_time) {
      time = moment(this.currentDate + " " + this.activeBatch.batch_time);
    } else {
      time = null;
    }
    this.editBatchGroup = this.fb.group({
      id: [this.activeBatch.id],
      name: [this.activeBatch.name, Validators.required],
      refId: [this.activeBatch.refId.toString(), Validators.required],
      batch_start_date: [
        this.activeBatch.batch_start_date + " " + "00:00:00",
        Validators.required,
      ],
      batch_time: [time, Validators.required],
      batch_end_date: [this.activeBatch.batch_end_date + " " + "00:00:00"],
      event_link: [this.activeBatch.event_link],
    });
    setTimeout(() => {
      this.initiateEditFlag = true;
      drawer.toggle();
    }, -1);
  }

  fetchBatchStudents() {
    this.fetchingStudents = true;
    this.http
      .get("batch_users?filter=batch_id eq " + this.activeBatch.id)
      .subscribe((res) => {
        this.activeBatch.students = res.data;
        this.fetchingStudents = false;
      });
  }

  initBatchCreation() {
    const bottomsheet = this._bottomSheet.open(BottomSheetBatchCreateSheet, {
      panelClass: "create-batch-bottom-sheet",
      data: { courses: this.originalCourses },
    });
    bottomsheet.afterDismissed().subscribe((data: any) => {
      if (data && data.refresh) {
        this.getBatches();
      }
    });
  }

  initUserAssign(): void {
    const bottomsheet = this._bottomSheet.open(BottomSheetStudentSelectSheet, {
      data: { users: this.enrolledUsers, batch: this.activeBatch },
    });
    bottomsheet.afterDismissed().subscribe((data: any) => {
      if (data && data.refresh) {
        this.fetchBatchStudents();
      }
    });
  }

  initiateCreateUser() {
    const bottomsheet = this._bottomSheet.open(BottomSheetCreateUserSheet, {
      data: { courses: this.originalCourses },
    });
    bottomsheet.afterDismissed().subscribe((data: any) => {
      if (data && data.refresh) {
        this.fetchEnrolledUsers();
      }
    });
  }

  removeUser(student) {
    if (
      window.confirm(
        "Are you sure you want to remove the " +
          student.user.name +
          " from " +
          this.activeBatch.name +
          "?"
      )
    ) {
      this.http
        .post("delete/batch_users", { id: student.id })
        .subscribe((res) => {
          this.fetchBatchStudents();
        });
    }
  }

  courseAccessStatusChangeStudentSpecific(student) {
    let label = "Re-activate";
    let status = 1;
    if (student.status == 1) {
      label = "De-Activate";
      status = 3;
    }
    if (
      window.confirm(
        "Are you sure you want to " +
          label +
          " the access to  " +
          student.user.name +
          "?"
      )
    ) {
      this.http
        .post("user_courses/", {
          status: status,
          id: student.id,
        })
        .subscribe((res) => {
          if (res.success == true) {
            this.fetchEnrolledUsers();
            this.activeStudent = { ...this.activeStudent, ...res.data };
          }
        });
    }
  }

  courseAccessStatusChange(student) {
    let label = "Re-activate";
    let status = 1;
    if (student.user_course.status == 1) {
      label = "De-Activate";
      status = 3;
    }
    if (
      window.confirm(
        "Are you sure you want to " +
          label +
          " the access to  " +
          student.user.name +
          "?"
      )
    ) {
      this.http
        .post("user_courses/", {
          status: status,
          id: student.user_course.id,
        })
        .subscribe((res) => {
          this.fetchBatchStudents();
        });
    }
  }

  deleteBatch() {
    if (
      window.confirm(
        "Are you sure you want to delete " + this.activeBatch.name + "?"
      )
    ) {
      this.http
        .post("delete/batches", { id: this.activeBatch.id })
        .subscribe((res) => {
          this.activeBatch = "";
          this.getBatches();
        });
    }
  }

  getUserInfo() {
    this.http
      .getExternal("https://powerbi.cedlearn.com/", {
        dashboard: 5,
        user_id: this.activeStudent.user_id,
      })
      .subscribe((res) => {
        this.activeStudent.performance_url =
          this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
      });
    this.http.get("user/" + this.activeStudent.user_id).subscribe((res) => {
      this.activeStudent.user_info = res.data;
    });
  }

  get formTermDetails() {
    return this.activeStudentFeeGroup.controls["details"] as FormArray;
  }

  addNewTerm() {
    const termForm = this.fb.group({
      amount: ["", Validators.required],
      term_date: ["", Validators.required],
    });
    this.formTermDetails.push(termForm);

    this.activeStudentFeeDetails.push({
      amount: 0,
      date: "",
    });
  }

  removeTerm(index) {
    this.activeStudentFeeDetails.splice(index, 1);
  }

  saveFeeDetails() {}
}

@Component({
  selector: "bottom-sheet-student-select",
  templateUrl: "bottom-sheet-student-select.html",
  styleUrls: ["./batch.component.scss"],
})
export class BottomSheetStudentSelectSheet {
  stateCtrl = new FormControl();
  filteredUsers: Observable<any[]>;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetStudentSelectSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private http: HttpService
  ) {
    this.filteredUsers = this.stateCtrl.valueChanges.pipe(
      startWith(""),
      map((state) =>
        state ? this._filterStates(state) : this.data.users.slice()
      )
    );
  }

  private _filterStates(value: string): any[] {
    if (!value) return this.data.users.slice();
    const filterValue = value.toLowerCase();
    return this.data.users.filter(
      (user) =>
        user.user.name.toLowerCase().indexOf(filterValue) === 0 ||
        user.user.email.toLowerCase().indexOf(filterValue) === 0 ||
        (user.user.mobile_number &&
          user.user.mobile_number
            .toString()
            .toLowerCase()
            .indexOf(filterValue) === 0)
    );
  }

  displayFn(user?: any): string | undefined {
    return user ? user.user.name + " (" + user.user.email + ")" : undefined;
  }

  assign() {
    if (!this.stateCtrl.value) return;
    this.http
      .post("batch_users", {
        batch_id: this.data.batch.id,
        user_id: this.stateCtrl.value.user.id,
        status: 1,
        access_to_recorded_sessions: 1,
        user_course_id: this.stateCtrl.value.id,
      })
      .subscribe((res) => {
        this._bottomSheetRef.dismiss({ refresh: true });
      });
  }
}

@Component({
  selector: "bottom-sheet-trainer-select",
  templateUrl: "bottom-sheet-student-select.html",
  styleUrls: ["./batch.component.scss"],
})
export class BottomSheetTrainerSelectSheet {
  stateCtrl = new FormControl();
  filteredUsers: Observable<any[]>;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetTrainerSelectSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private http: HttpService
  ) {
    this.filteredUsers = this.stateCtrl.valueChanges.pipe(
      startWith(""),
      map((state) =>
        state ? this._filterStates(state) : this.data.users.slice()
      )
    );
  }

  private _filterStates(value: string): any[] {
    if (!value) return this.data.users.slice();
    const filterValue = value.toLowerCase();
    return this.data.users.filter(
      (user) =>
        user.user.name.toLowerCase().indexOf(filterValue) === 0 ||
        user.user.email.toLowerCase().indexOf(filterValue) === 0 ||
        (user.user.mobile_number &&
          user.user.mobile_number
            .toString()
            .toLowerCase()
            .indexOf(filterValue) === 0)
    );
  }

  displayFn(user?: any): string | undefined {
    return user ? user.user.name + " (" + user.user.email + ")" : undefined;
  }

  assign() {
    if (!this.stateCtrl.value) return;
    this.http
      .post("batch_users", {
        batch_id: this.data.batch.id,
        user_id: this.stateCtrl.value.user.id,
        status: 1,
        access_to_recorded_sessions: 1,
        user_course_id: this.stateCtrl.value.id,
      })
      .subscribe((res) => {
        this._bottomSheetRef.dismiss({ refresh: true });
      });
  }
}

@Component({
  selector: "batch-create",
  templateUrl: "batch-create.html",
  styleUrls: ["./batch.component.scss"],
})
export class BottomSheetBatchCreateSheet {
  createBatchGroup: FormGroup;

  originalCourses: any = [];

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetBatchCreateSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private http: HttpService,
    private fb: FormBuilder
  ) {
    this.createBatchGroup = this.fb.group({
      name: ["", Validators.required],
      refId: ["", Validators.required],
    });
    this.originalCourses = this.data.courses;
  }

  createBatch() {
    if (!this.createBatchGroup.valid) return;
    this.http.post("batches", this.createBatchGroup.value).subscribe((res) => {
      this.createBatchGroup.reset();
      this._bottomSheetRef.dismiss({ refresh: true });
    });
  }
}

@Component({
  selector: "user-create",
  templateUrl: "create-user.html",
  styleUrls: ["./batch.component.scss"],
})
export class BottomSheetCreateUserSheet {
  createUserGroup: FormGroup;

  originalCourses: any = [];

  packages: any = [];

  activePackage: any;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetCreateUserSheet>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data,
    private http: HttpService,
    private fb: FormBuilder
  ) {
    this.originalCourses = this.data.courses;
  }

  createUser() {
    this.createUserGroup = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      mobile_number: ["", Validators.required],
      course: ["", Validators.required],
      package: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.createUser();
    this.createUserGroup.controls["course"].valueChanges.subscribe(
      (res: any) => {
        if (res) this.getPackages(res);
      }
    );
  }

  getPackages(course_id) {
    let filter =
      "refId eq " + course_id + " and ref eq 'course' and status eq 1";
    filter += " and upgrade_package eq 0";
    this.http.get("packages?filter=" + filter).subscribe((res) => {
      this.packages = res.data;
    });
  }

  createNewUser() {
    if (!this.createUserGroup.valid) return;
    let data: any = this.createUserGroup.value;
    data["email_otp"] = this.createUserGroup.value.package;
    data["status"] = 1;
    this.http.post("f_user", data).subscribe((res) => {
      this.createUserGroup.reset();
      this._bottomSheetRef.dismiss({ refresh: true });
    });
  }
}
