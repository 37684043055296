import { Routes } from "@angular/router";
import { CourseComponent } from "./course.component";
import { AuthGuardService as AuthGuard } from "@core/services/guards/auth-guard.service";
import { ChapterComponent } from "./chapter/chapter.component";
import { ListComponent } from "./list/list.component";
import { AllComponent } from "./list/all/all.component";
import { MyComponent } from "./list/my/my.component";
import { CompletedComponent } from "./list/completed/completed.component";
import { CertificatesComponent } from "./list/certificates/certificates.component";
import { FreeComponent } from "./list/free/free.component";
import { RecordedComponent } from "./recorded/recorded.component";
import { RCourseComponent } from "./recorded/r-course/r-course.component";
import { RBatchCourseComponent } from "./recorded/r-course-batch/r-batch-course.component";

import { RCourseListComponent } from "./recorded/r-course-list/r-course-list.component";
import { AssessmentsComponent } from "./assessments/assessments.component";
import { ProjectsComponent } from "./projects/projects.component";
import { PreparationComponent } from "./preparation/preparation.component";
import { FinalAssessmentComponent } from "./final-assessment/final-assessment.component";
import { AssignmentsComponent } from "./assignments/assignments.component";
import { JupiterNotebooksComponent } from "./jupiter-notebooks/jupiter-notebooks.component";
import { ToolsAccessComponent } from "./tools-access/tools-access.component";

export const courseRoutes: Routes = [
  {
    path: "recorded-classes",
    component: RecordedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: RCourseListComponent,
      },
      {
        path: ":slug",
        component: RCourseComponent,
      },
      {
        path: ":slug/:batchId",
        component: RBatchCourseComponent,
      },
    ],
  },
  {
    path: "tools-access/:slug",
    component: ToolsAccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "assessments/:slug",
    component: AssessmentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "projects/:slug",
    component: ProjectsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "preparation/:slug",
    component: PreparationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "assignments/:slug",
    component: AssignmentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "jupiter-notebooks/:slug",
    component: JupiterNotebooksComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "final-assessment/:slug",
    component: FinalAssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "courses",
    component: ListComponent,
    children: [
      { path: "", component: AllComponent },
      { path: "free", component: FreeComponent },
      { path: "my", component: MyComponent, canActivate: [AuthGuard] },
      {
        path: "completed",
        component: CompletedComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "certificates",
        component: CertificatesComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  { path: "course/:slug", component: CourseComponent },
  {
    path: "course/:slug/:moduleSlug/:chapterSlug",
    component: ChapterComponent,
    canActivate: [AuthGuard],
  },
];
