import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpService } from "../../../core/services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@core/services/auth.service";
import { environment } from "@environment/environment";
import { Location } from "@angular/common";

@Component({
  selector: "im-assignments",
  templateUrl: "./assignments.component.html",
  styleUrls: ["./assignments.component.scss"],
})
export class AssignmentsComponent implements OnInit, OnDestroy {
  courseSlug: string;
  course: any;
  processing: boolean = false;

  loginUser: any;

  assignmentsData: any;

  activeItem: any;

  navLinks = [];

  activeTab: any;

  activeCategory: any;

  activeUploadItem: any;

  userTaks: any = {};

  dataSets: any;

  drawer: any;

  constructor(
    private location: Location,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpService
  ) {
    this.route.params.subscribe((params) => {
      if (params.slug) {
        this.courseSlug = params.slug;
        this.getCourse();
      } else {
        if (this.drawer) {
          this.drawer.close();
        }
      }
    });
  }

  ngOnInit() {
    this.loginUser = this.auth.loginUser;
  }

  ngOnDestroy(): void {
    if (this.drawer) {
      this.drawer.close();
    }
  }

  getDataSets(item: any, drawer: any) {
    this.drawer = drawer;
    this.http
      .getExternal(item.link + "?t=" + new Date().getTime())
      .subscribe((res) => {
        this.dataSets = res;
      });
  }

  back(): void {
    //this.location.back()
    this.router.navigate(["course", this.courseSlug]);
  }

  getCourse() {
    const that = this;
    this.processing = true;
    this.http.get("na/course/" + this.courseSlug).subscribe(
      (res) => {
        this.course = res.data;
        if (!this.course.user_course && this.loginUser.user_type == "student") {
          this.router.navigate(["/course/" + this.courseSlug]);
        } else {
          this.http
            .getExternal(
              environment.storageUrl +
                "assignments/" +
                this.courseSlug +
                ".json?t=" +
                new Date().getTime()
            )
            .subscribe((res) => {
              this.assignmentsData = res;
              this.navLinks = res.categories;
              if (this.route.snapshot.queryParamMap.get("m")) {
                let tempFM = this.navLinks.find(
                  (it) => it.key == this.route.snapshot.queryParamMap.get("m")
                );
                if (tempFM) {
                  this.getActiveData(tempFM);
                } else {
                  this.getActiveData(this.navLinks[0]);
                }
              } else {
                this.getActiveData(this.navLinks[0]);
              }
            });
        }
        //this.processing = false;
      },
      (err) => {
        this.processing = false;
      }
    );
  }

  getActiveData(link: any) {
    this.activeTab = link;
    this.http
      .get(
        "user_tasks?filter=ref eq 'Course/Assignment' and status eq 2 and refId eq " +
          this.course.id +
          " and module eq '" +
          this.activeTab.label +
          "'"
      )
      .subscribe((res) => {
        res.data.forEach((element) => {
          let tempKey = element.title.split(".")[0];
          if (!this.userTaks[this.activeTab.label])
            this.userTaks[this.activeTab.label] = {};
          this.userTaks[this.activeTab.label][tempKey] = element;
        });
        this.userTaks = { ...this.userTaks };
        console.log(this.userTaks);
      });
    this.activeCategory = this.assignmentsData[link.key];
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { m: this.activeTab.key },
    });
  }

  takeAction(action: any) {
    if (action.type == "external") {
      window.open(action.link, "_blank").focus();
    }
    if (action.type == "download") {
      this.downloadFile(action.link);
    }
  }

  drawerCloseEvent($event) {
    this.activeItem = null;
    this.dataSets = null;
  }

  downloadFile(url, filename = "") {
    if (filename.length === 0) filename = url.split("/").pop();
    const req = new XMLHttpRequest();
    req.open("GET", url, true);
    req.responseType = "blob";
    req.onload = function () {
      const blob = new Blob([req.response], {
        type: "application/pdf",
      });

      const isIE = false || !!(<any>document).documentMode;
      if (isIE) {
        (<any>window).navigator.msSaveBlob(blob, filename);
      } else {
        const windowUrl = window.URL || (<any>window).webkitURL;
        const href = windowUrl.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("download", filename);
        a.setAttribute("href", href);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };
    req.send();
  }

  uploadDocument(item, assignmentFile) {
    this.activeUploadItem = item;
    assignmentFile.click();
  }

  uploadSource(e) {
    console.log(e.target.files);
    if (e.target.files.length > 0) {
      this.upload(e.target.files[0]);
    }
    e.target.value = "";

    // for (var i = 0; i < e.target.files.length; i++) {
    //   //this.sourceList.push({name: e.target.files[i].name});
    //   this.upload(e.target.files[i], (this.sourceList.length-1));
    // }
  }

  upload(source) {
    let that = this;
    let ajaxData = new FormData();
    ajaxData.append("file[0]", source);
    var reader = new FileReader();

    reader.readAsDataURL(source);
    ajaxData.append(
      "upload_path",
      "/user/assignments/" +
        this.loginUser.id +
        "/" +
        this.course.id +
        "/" +
        this.activeTab.label +
        "/" +
        this.activeUploadItem.title
    );
    ajaxData.append("custom_attrs", "[]");
    ajaxData.append("random_names", "false");
    this.http.upload("media/upload", ajaxData).subscribe((res: any) => {
      if (res.length == 1 && res[0].success) {
        let task_data = {
          title:
            this.activeUploadItem.title +
            ". " +
            this.activeUploadItem.topic_title,
          ref: "Course/Assignment",
          status: 2,
          user_id: this.loginUser.id,
          refId: this.course.id,
          module: this.activeTab.label,
          upload_attacment:
            "/user/assignments/" +
            this.loginUser.id +
            "/" +
            this.course.id +
            "/" +
            this.activeTab.label +
            "/" +
            this.activeUploadItem.title +
            "/" +
            res[0].file_name,
        };

        this.http.post("user_tasks", task_data).subscribe(
          (res) => {
            //this.activeUploadItem.submittedTask = res.data;
            if (!this.userTaks[this.activeTab.label])
              this.userTaks[this.activeTab.label] = {};
            this.userTaks[this.activeTab.label][this.activeUploadItem.title] =
              res.data;
            this.userTaks = { ...this.userTaks };
          },
          (err) => {}
        );
      }
    });
  }
}
