<div class="overflow-y-auto display-block" style="height: calc(100vh - 103px)">
  <div class="flex">
    <div
      class="left w-3/4 shadow-2 relative job-content-area"
      style="border: 1px solid; border-radius: 8px; height: calc(100vh - 103px)"
    >
      <ng-container *ngIf="pdfSrc">
        <ng-container *ngIf="activeSource">
          <button
            mat-icon-button
            color="warn"
            class="absolute right-2-rem top-1-rem cursor-pointer"
            (click)="closeResources()"
            style="z-index: 2"
          >
            <mat-icon>close</mat-icon>
          </button>
        </ng-container>
        <pdf-viewer
          [src]="pdfSrc"
          [render-text]="true"
          [original-size]="false"
          style="width: 400px; height: 500px"
        ></pdf-viewer>
      </ng-container>
      <ng-container *ngIf="!pdfSrc">
        <div class="empty-state flex flex-column h-full p-8">
          <mat-icon class="border-radius-full">document_scanner</mat-icon>
          <h3 class="mt-2">No Resume Available.</h3>
          <p class="mt-2 color-secondary line-height-2rem">
            Sorry, it seems that there is no resume available at the moment.
            <br />
            If you haven't uploaded your resume yet, please consider doing so to
            enhance your profile
            <br />
            No worries follow below three simple steps to get your self market
            ready
          </p>
          <ul class="color-secondary line-height-2rem">
            <li>
              Read about “How to prepare ATS complied CV and Self introduction”
            </li>
            <li>Download ATS compiled formats for reference</li>
            <li>Prepare your cv (ATS Complied) and upload here</li>
          </ul>
          <p class="color-secondary line-height-2rem">
            <b>Please Note:</b> We will consider your CV from here while
            applying for jobs on behalf of you or referring your Cv to our
            consultants. <br />Please make sure that your CV always up to date.
          </p>
        </div>
      </ng-container>
    </div>
    <div class="right w-1/4 pl-4 flex justify-between flex-column">
      <div class="mt-2">
        <div class="flex flex-wrap flex-column pl-2 pr-4">
          <ng-container *ngFor="let resource of resources">
            <a
              class="cursor-pointer border p-4 mb-4 border-radius-lg"
              (click)="sourceActivated(resource)"
            >
              <span class="font-semi-bold">{{ resource.name }}</span>
              <p class="line-height-15rem color-secondary font-xs">
                {{ resource.short }}
              </p>
            </a>
          </ng-container>
        </div>
        <div
          class="download_brochure flex w-full items-center justify-content-center"
        >
          <a class="btn"
            ><b>DOWNLOAD SAMPLES</b>
            <div></div>
          </a>

          <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation="12"
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                  result="goo"
                />
                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
              </filter>
            </defs>
          </svg>
          <svg class="svg" viewBox="0 0 400 400">
            <defs>
              <filter id="duotone-filter-post-one">
                <feColorMatrix
                  type="matrix"
                  values="0.14453125 0 0 0 0.33203125 0.71875 0 0 0 0.27734375 -0.34765625 0 0 0 0.73046875 0 0 0 1 0"
                ></feColorMatrix>
              </filter>
            </defs>
          </svg>
        </div>
      </div>
      <div class="upload-wrapper-section ml-2 mr-4 mb-2">
        <input
          type="file"
          name="files"
          id="file"
          style="display: none"
          #submitFile
          (change)="uploadSource($event)"
        />
        <h2 class="modal-title mt-2 font-lg">Upload Your Resume</h2>
        <p class="modal-description font-xs">
          Thank you for your interest! Please upload your resume using the
          button below:
        </p>
        <button class="upload-area" (click)="uploadDocument(submitFile)">
          <span class="upload-area-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="340.531"
              height="419.116"
              viewBox="0 0 340.531 419.116"
            >
              <g id="files-new" clip-path="url(#clip-files-new)">
                <path
                  id="Union_2"
                  data-name="Union 2"
                  d="M-2904.708-8.885A39.292,39.292,0,0,1-2944-48.177V-388.708A39.292,39.292,0,0,1-2904.708-428h209.558a13.1,13.1,0,0,1,9.3,3.8l78.584,78.584a13.1,13.1,0,0,1,3.8,9.3V-48.177a39.292,39.292,0,0,1-39.292,39.292Zm-13.1-379.823V-48.177a13.1,13.1,0,0,0,13.1,13.1h261.947a13.1,13.1,0,0,0,13.1-13.1V-323.221h-52.39a26.2,26.2,0,0,1-26.194-26.195v-52.39h-196.46A13.1,13.1,0,0,0-2917.805-388.708Zm146.5,241.621a14.269,14.269,0,0,1-7.883-12.758v-19.113h-68.841c-7.869,0-7.87-47.619,0-47.619h68.842v-18.8a14.271,14.271,0,0,1,7.882-12.758,14.239,14.239,0,0,1,14.925,1.354l57.019,42.764c.242.185.328.485.555.671a13.9,13.9,0,0,1,2.751,3.292,14.57,14.57,0,0,1,.984,1.454,14.114,14.114,0,0,1,1.411,5.987,14.006,14.006,0,0,1-1.411,5.973,14.653,14.653,0,0,1-.984,1.468,13.9,13.9,0,0,1-2.751,3.293c-.228.2-.313.485-.555.671l-57.019,42.764a14.26,14.26,0,0,1-8.558,2.847A14.326,14.326,0,0,1-2771.3-147.087Z"
                  transform="translate(2944 428)"
                  fill="var(--c-action-primary)"
                />
              </g>
            </svg>
          </span>
          <!-- <span class="upload-area-title"
                >Drag file(s) here to upload.</span
              > -->
          <span class="upload-area-title">
            You can select a file by <br /><strong>clicking here</strong>
          </span>
        </button>
        <small class="mt-1">Accepted file format: PDF</small>
      </div>
    </div>
  </div>
</div>
