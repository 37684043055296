<div class="im-bar-projects" *ngIf="course">
  <div class="flex justify-between items-center">
    <div class="flex items-center ml-6">
      <button
        mat-icon-button
        class="mr-3"
        (click)="back()"
        style="border: 1px solid; width: 32px; height: 32px; line-height: 32px"
      >
        <mat-icon>keyboard_backspace</mat-icon>
      </button>
      <h2 class="">{{ course?.name }}</h2>
    </div>
  </div>
  <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of navLinks"
      class="letter-spacing-15"
      [active]="activeTab?.key == link?.key"
      (click)="getActiveData(link)"
    >
      {{ link.label }}
    </a>
  </nav>
</div>

<div class="overflow-y-auto display-block" style="height: calc(100vh - 103px)">
  <input
    type="file"
    name="files"
    id="file"
    style="display: none"
    #assignmentFile
    (change)="uploadSource($event)"
  />
  <div class="projects-items-section flex flex-column mx-8 mt-8">
    <ng-container *ngFor="let itemg of activeItem?.items">
      <h2 class="my-2" style="font-size: 1.2em">{{ itemg.label }}</h2>
      <p>{{ itemg.short_description }}</p>
      <div class="flex flex-wrap">
        <ng-container *ngFor="let item of itemg.items">
          <div class="item relative">
            <mat-card class="mat-card border-radius-lg">
              <mat-card-content>
                <div class="absolute right-10 top-5" *ngIf="item.videolink">
                  <button mat-icon-button (click)="openVideo(item.videolink)">
                    <mat-icon style="font-size: 32px; width: 32px; height: 32px"
                      >play_circle_outline</mat-icon
                    >
                  </button>
                </div>
                <div
                  class="icon-info"
                  [ngClass]="{
                    'project-completed':
                      userTaks &&
                      userTaks[activeTab.label] &&
                      userTaks[activeTab.label][item.id]
                  }"
                >
                  <div class="border-radius-full">
                    <img [src]="item.icon" width="32" height="32" />
                  </div>
                </div>
                <h3>{{ item.name }}</h3>
                <p
                  class="mt-1 line-clamp-3 color-secondary font-xs"
                  style="height: 4.2em"
                >
                  {{ item.short_description }}
                </p>
                <ng-container *ngIf="item.type == 'download'">
                  <div
                    class="flex justify-between absolute bottom-10"
                    style="width: 90%"
                  >
                    <ng-container
                      *ngIf="
                        userTaks &&
                        userTaks[activeTab.label] &&
                        userTaks[activeTab.label][item.id]
                      "
                    >
                      <!-- <button
                    mat-button
                    class="mt-3"
                    (click)="
                      downloadFile(
                        userTaks[activeTab.label][item.title]
                          .upload_attacment
                      )
                    "
                    color="primary"
                  >
                    <mat-icon>file_download</mat-icon> Submitted
                  </button> -->
                      <mat-chip-list aria-label="model tags">
                        <mat-chip disableRipple color="primary" class="p-0">{{
                          userTaks[activeTab.label][item.id].created_at
                            | date : "medium"
                        }}</mat-chip>
                      </mat-chip-list>
                      <ng-container *ngIf="item.solution">
                        <button
                          mat-button
                          class=""
                          (click)="downloadFile(item.solution)"
                          color="primary"
                        >
                          <mat-icon>file_download</mat-icon> Solution
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        !(
                          userTaks &&
                          userTaks[activeTab.label] &&
                          userTaks[activeTab.label][item.id]
                        )
                      "
                    >
                      <button
                        mat-fab
                        class="mat-elevation-z1 upload-button"
                        (click)="uploadDocument(item, assignmentFile)"
                      >
                        <mat-icon>file_upload</mat-icon> Upload
                      </button>
                    </ng-container>
                    <button
                      mat-mini-fab
                      class="mat-elevation-z1"
                      (click)="takeAction(item)"
                    >
                      <mat-icon>file_download</mat-icon>
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="item.type == 'external'">
                  <button
                    mat-mini-fab
                    class="mt-3 float-right mat-elevation-z1"
                    (click)="takeAction(item)"
                  >
                    <mat-icon>east</mat-icon>
                  </button>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
