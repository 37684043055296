<ng-container *ngIf="!processing && !notEnrolled">
  <div class="grid has-11-columns full-event-section overflow-y-auto h-screen">
    <div
      class="column-start-1 row-span-1 column-end-8 left"
      style="max-width: calc(100vw - 26vw - 60px)"
    >
      <div
        class="pl-4 pr-4 pt-2 pb-2 flex items-center justify-between event-header"
      >
        <div class="flex items-center">
          <button mat-icon-button class="mr-2" (click)="back()">
            <mat-icon>keyboard_backspace</mat-icon>
          </button>
          <h3 style="letter-spacing: 1.2px">{{ event?.name }}</h3>
        </div>
        <ng-container *ngIf="allowUsertoJoinOnline">
          <ng-container *ngIf="!startEnableJoin">
            <button
              mat-raised-button
              class="letter-spacing-15 text-uppercase mr-2 border-radius-none"
              style="opacity: 1; color: #000"
              type="button"
              disabled
            >
              <span class="flex items=center justify-center"
                >JOIN SESSION
                <span
                  class="font-xs ml-4"
                  style="color: rgba(0, 0, 0, 0.6); text-transform: initial"
                  >(Will be enable 10 mins before Start time)</span
                ></span
              >
            </button>
          </ng-container>
          <ng-container *ngIf="startEnableJoin">
            <button
              mat-raised-button
              color="primary"
              class="letter-spacing-15 text-uppercase mr-4 border-radius-none"
              (click)="join()"
              type="button"
            >
              JOIN SESSION NOW
            </button>
          </ng-container>
        </ng-container>
      </div>
      <div [ngSwitch]="tabIndex" style="margin-top: 56px">
        <div *ngSwitchCase="0">
          <ul
            class="pl-8 pr-0 pt-0 pb-0 flex"
            style="background-color: aliceblue; height: 60px"
          >
            <li class="flex align-items-center w-1/3">
              <mat-icon class="mr-4">calendar_today</mat-icon>
              <div class="flex flex-column">
                <p class="mb-1 font-semi-bold">
                  {{ event.event_start_date | date : "mediumDate" }}
                </p>
                <p class="font-xs">Date</p>
              </div>
            </li>
            <li class="flex align-items-center w-1/3">
              <mat-icon class="mr-4">schedule</mat-icon>
              <div class="flex flex-column">
                <p class="mb-1 font-semi-bold">
                  {{
                    event.event_start_date + " " + event.event_start_time
                      | date : "shortTime"
                  }}
                  -
                  {{
                    event.event_start_date + " " + event.event_end_time
                      | date : "shortTime"
                  }}
                </p>
                <p class="font-xs">Time</p>
              </div>
            </li>
            <li class="flex align-items-center w-1/3">
              <mat-icon class="mr-4">timer</mat-icon>
              <div class="flex flex-column">
                <p class="mb-1 font-semi-bold">{{ event.duration }}</p>
                <p class="font-xs">Duration</p>
              </div>
            </li>
          </ul>
          <div class="intro-section im-content ml-2 mt-4 mb-4">
            <div id="editorjs"></div>
          </div>
        </div>

        <!-- <div *ngSwitchCase="1" style="position: relative">
          <div class="ml-8 mt-4" *ngIf="!surveySubmitted">
            <h2 class="mb-1">Let us know more about you!</h2>
            <p style="color: rgba(0, 0, 0, 0.54)">
              Glad you are done with the first step that is getting introduced
              to the program. It is time for us to know about you so that we
              could personalize this program to fit your learning goals. Below
              given are 5 general questions for you to answer. This is our way
              of understanding more about our participants and making the
              program relevant.
            </p>
            <br />
            <p style="color: #f39c12">
              This is a mandatory stage to unlock the next state. Hence, please
              go ahead and answer the questions.
            </p>
          </div>
          <div
            class="ml-8 mt-4"
            style="
              display: block;
              height: calc(100vh - 58.8px - 163px);
              position: relative;
            "
          >
            <app-questionnaire
              [event]="event"
              (surveySubmitStatus)="surveySubmitStatus($event)"
            >
            </app-questionnaire>
          </div>
        </div> -->
        <div *ngSwitchCase="1" style="position: relative">
          <div class="ml-8 pt-4">
            <h2 class="mb-1">Resources</h2>
            <p style="color: rgba(0, 0, 0, 0.54)">
              Participating in a hackathon can be an exciting and rewarding
              experience. Here are some resources that can help you prepare for
              a hackathon:
            </p>
            <br />
            <p style="color: #f39c12; font-size: 1.2em">
              Remember, hackathons are not just about winning but also about
              learning, collaborating, and having fun. Good luck with your
              hackathon!
            </p>
          </div>
          <div
            class="ml-8 mt-8 grid has-3-columns column-gap-30 mr-8 row-gap-30"
          >
            <div *ngFor="let resource_file of resource_files; let i = index">
              <div class="book-card download-card">
                <div class="content-wrapper">
                  <div class="py-4 px-2 w-full">
                    <div class="book-name flex items-center">
                      <img
                        [src]="resource_file.icon"
                        alt=""
                        width="32"
                        height="32"
                        class="mr-4"
                      />
                      <span>{{ resource_file.title }}</span>
                    </div>
                    <button
                      mat-mini-fab
                      class="mb-3 float-right mat-elevation-z1"
                      (click)="downloadFile(resource_file.link)"
                    >
                      <mat-icon>file_download</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="column-start-2"></div> -->
          </div>
        </div>
        <div *ngSwitchCase="2" style="position: relative">
          <div class="ml-8 pt-4">
            <h2 class="mb-1">Submission</h2>
            <p style="color: rgba(0, 0, 0, 0.54)">
              When participating in a hackathon, submitting your project
              correctly and on time is crucial. Here are some general steps to
              follow for submitting your project in a hackathon:
            </p>
            <br />
            <ul style="line-height: 32px">
              <li>
                Follow submission guidelines: Review the hackathon's submission
                guidelines carefully and adhere to any specific requirements.
              </li>
              <li>
                Package your submission: Gather all the required files,
                including your documentation, code, and visualizations, and
                package them appropriately.
              </li>
              <li>
                Submit before the deadline: Make sure to submit your data
                analytics project before the designated deadline. Account for
                any time zone differences and allow ample time for the
                submission process.
              </li>
            </ul>
          </div>
          <div class="ml-8 mt-8 grid has-2-columns column-gap-30 mr-8">
            <div class="column-start-1 upload-wrapper-section">
              <input
                type="file"
                name="files"
                id="file"
                style="display: none"
                #submitFile
                (change)="uploadSource($event)"
              />
              <h2 class="modal-title">Upload a file</h2>
              <p class="modal-description">Attach the file below</p>
              <button
                class="upload-area"
                (click)="uploadDocument(submitFile)"
                [disabled]="userTaks?.hackthon_submission['Final Source']"
              >
                <span class="upload-area-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="340.531"
                    height="419.116"
                    viewBox="0 0 340.531 419.116"
                  >
                    <g id="files-new" clip-path="url(#clip-files-new)">
                      <path
                        id="Union_2"
                        data-name="Union 2"
                        d="M-2904.708-8.885A39.292,39.292,0,0,1-2944-48.177V-388.708A39.292,39.292,0,0,1-2904.708-428h209.558a13.1,13.1,0,0,1,9.3,3.8l78.584,78.584a13.1,13.1,0,0,1,3.8,9.3V-48.177a39.292,39.292,0,0,1-39.292,39.292Zm-13.1-379.823V-48.177a13.1,13.1,0,0,0,13.1,13.1h261.947a13.1,13.1,0,0,0,13.1-13.1V-323.221h-52.39a26.2,26.2,0,0,1-26.194-26.195v-52.39h-196.46A13.1,13.1,0,0,0-2917.805-388.708Zm146.5,241.621a14.269,14.269,0,0,1-7.883-12.758v-19.113h-68.841c-7.869,0-7.87-47.619,0-47.619h68.842v-18.8a14.271,14.271,0,0,1,7.882-12.758,14.239,14.239,0,0,1,14.925,1.354l57.019,42.764c.242.185.328.485.555.671a13.9,13.9,0,0,1,2.751,3.292,14.57,14.57,0,0,1,.984,1.454,14.114,14.114,0,0,1,1.411,5.987,14.006,14.006,0,0,1-1.411,5.973,14.653,14.653,0,0,1-.984,1.468,13.9,13.9,0,0,1-2.751,3.293c-.228.2-.313.485-.555.671l-57.019,42.764a14.26,14.26,0,0,1-8.558,2.847A14.326,14.326,0,0,1-2771.3-147.087Z"
                        transform="translate(2944 428)"
                        fill="var(--c-action-primary)"
                      />
                    </g>
                  </svg>
                </span>
                <!-- <span class="upload-area-title"
                  >Drag file(s) here to upload.</span
                > -->
                <span class="upload-area-title">
                  You can select a file by <br /><strong>clicking here</strong>
                </span>
              </button>
            </div>
            <div class="column-start-2 upload-wrapper-section">
              <ng-container
                *ngIf="
                  userTaks?.hackthon_submission &&
                  userTaks?.hackthon_submission['Final Source']
                "
              >
                <h2 class="modal-title">Submitted Files</h2>
                <p class="modal-description">
                  Uploaded file can be download by using the below link
                </p>
                <div class="flex flex-column mt-3">
                  <mat-list>
                    <mat-list-item
                      class="cursor-pointer uploaded-list-item mb-3"
                      (click)="
                        downloadFile(
                          userTaks?.hackthon_submission['Final Source']
                            .upload_attacment
                        )
                      "
                    >
                      <mat-icon mat-list-icon>download</mat-icon>
                      <h4 mat-line>
                        {{
                          userTaks?.hackthon_submission[
                            "Final Source"
                          ].upload_attacment.split("/")[
                            userTaks?.hackthon_submission[
                              "Final Source"
                            ].upload_attacment.split("/").length - 1
                          ]
                        }}
                      </h4>
                      <p mat-line style="color: #6a6b76">
                        {{
                          userTaks?.hackthon_submission["Final Source"]
                            .created_at | date : "medium"
                        }}
                      </p>
                    </mat-list-item>
                  </mat-list>
                  <!-- <button mat-flat-button color="primary" class="w-1/2">
                    Download
                  </button>
                  <p class="mt-1">
                    {{
                      userTaks?.hackthon_submission["Final Source"].created_at
                        | date : "medium"
                    }}
                  </p> -->
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- <div
          *ngSwitchCase="3"
          style="position: relative; height: calc(100vh - 74px)"
        >
          <div class="ml-8 mt-4">
            <h2 class="mb-1">Day 1 - Introduction to Python</h2>
            <p style="color: rgba(0, 0, 0, 0.54)"></p>
          </div>
          <div class="ml-0 mt-4 flex">
            <div class="w-1/2">
              <ul class="color-primary pl-8 pt-3 line-height-15rem font-base">
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Introduction & Installation
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Why Python
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Fundamentals of Python
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Keywords & Identifiers
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Commenting
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Variables
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Input & Output Operations
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Data Types
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Sequences (String, List, Tuple, Set, Dictionary)
                </li>
              </ul>
            </div>
            <div class="w-1/2">
              <ul class="mr-12 pr-12 float-right">
                <li class="flex mb-8 align-items-center">
                  <mat-icon class="mr-4" color="primary"
                    >calendar_today</mat-icon
                  >
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">
                      {{ event.event_start_date | date : "mediumDate" }}
                    </p>
                    <p class="font-xs">Date</p>
                  </div>
                </li>
                <li class="flex mb-8 align-items-center">
                  <mat-icon class="mr-4" color="primary">schedule</mat-icon>
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">
                      {{
                        event.event_start_date +
                          "
                                            " +
                          event.event_start_time | date : "shortTime"
                      }}
                      -
                      {{
                        event.event_start_date + " " + event.event_end_time
                          | date : "shortTime"
                      }}
                      IST
                    </p>
                    <p class="font-xs">Time</p>
                  </div>
                </li>
                <li class="flex mb-4 align-items-center">
                  <mat-icon class="mr-4" color="primary">timer</mat-icon>
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">{{ event.duration }}</p>
                    <p class="font-xs">Duration</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="mr-8 mt-6">
            <ng-container *ngIf="joinStatus[0]">
              <ng-container *ngIf="startEnableJoin">
                <button
                  mat-raised-button
                  color="primary"
                  class="letter-spacing-15 text-uppercase mt-2 absolute bottom-0 border-radius-none"
                  style="height: 3.6rem; width: calc(100% + 3em) !important"
                  (click)="join()"
                  type="button"
                >
                  JOIN SESSION NOW
                </button>
              </ng-container>
              <ng-container *ngIf="!startEnableJoin">
                <button
                  mat-raised-button
                  class="letter-spacing-15 text-uppercase mt-2 absolute bottom-0 border-radius-none"
                  style="
                    height: 3.6rem;
                    opacity: 1;
                    width: calc(100% + 3em) !important;
                    color: #000;
                  "
                  type="button"
                  disabled
                >
                  <span class="flex items=center justify-center"
                    >JOIN SESSION
                    <span
                      class="font-xs ml-4"
                      style="color: rgba(0, 0, 0, 0.6); text-transform: initial"
                      >(Will be enable 10 mins before Start time)</span
                    ></span
                  >
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!joinStatus[0]">
              <button
                mat-raised-button
                class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none"
                style="height: 3.6rem; opacity: 1; color: #000"
                disabled
                type="button"
              >
                CLOSED
              </button>
            </ng-container>
          </div>
        </div>
        <div
          *ngSwitchCase="4"
          style="position: relative; height: calc(100vh - 74px)"
        >
          <div class="ml-8 mt-4">
            <h2 class="mb-1">Day 2 - Warming up for Python</h2>
            <p style="color: rgba(0, 0, 0, 0.54)"></p>
          </div>
          <div class="ml-0 mt-4 flex">
            <div class="w-1/2">
              <ul class="color-primary pl-8 pt-3 line-height-15rem font-base">
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Operators
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  What is an Operator?
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Arithmetic Operators
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Assignment Operators
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Comparison Operators
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Logical Operators
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Membership & Identify Operators
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Conditional Statements(If, If-Else, Nested - If)
                </li>
              </ul>
            </div>
            <div class="w-1/2">
              <ul class="mr-12 pr-12 float-right">
                <li class="flex mb-8 align-items-center">
                  <mat-icon class="mr-4" color="primary"
                    >calendar_today</mat-icon
                  >
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">
                      {{ days[1] | date : "mediumDate" }}
                    </p>
                    <p class="font-xs">Date</p>
                  </div>
                </li>
                <li class="flex mb-8 align-items-center">
                  <mat-icon class="mr-4" color="primary">schedule</mat-icon>
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">
                      {{
                        event.event_start_date +
                          "
                                            " +
                          event.event_start_time | date : "shortTime"
                      }}
                      -
                      {{
                        event.event_start_date + " " + event.event_end_time
                          | date : "shortTime"
                      }}
                      IST
                    </p>
                    <p class="font-xs">Time</p>
                  </div>
                </li>
                <li class="flex mb-4 align-items-center">
                  <mat-icon class="mr-4" color="primary">timer</mat-icon>
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">{{ event.duration }}</p>
                    <p class="font-xs">Duration</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="mr-8 mt-6">
            <ng-container *ngIf="joinStatus[1]">
              <ng-container *ngIf="startEnableJoin">
                <button
                  mat-raised-button
                  color="primary"
                  class="letter-spacing-15 text-uppercase mt-2 absolute bottom-0 border-radius-none"
                  style="height: 3.6rem; width: calc(100% + 3em) !important"
                  (click)="join()"
                  type="button"
                >
                  JOIN SESSION NOW
                </button>
              </ng-container>
              <ng-container *ngIf="!startEnableJoin">
                <button
                  mat-raised-button
                  class="letter-spacing-15 text-uppercase mt-2 absolute bottom-0 border-radius-none"
                  style="
                    height: 3.6rem;
                    opacity: 1;
                    width: calc(100% + 3em) !important;
                    color: #000;
                  "
                  type="button"
                  disabled
                >
                  <span class="flex items=center justify-center"
                    >JOIN SESSION
                    <span
                      class="font-xs ml-4"
                      style="color: rgba(0, 0, 0, 0.6); text-transform: initial"
                      >(Will be enable 10 mins before Start time)</span
                    ></span
                  >
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!joinStatus[1]">
              <button
                mat-raised-button
                class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none"
                style="height: 3.6rem; opacity: 1; color: #000"
                disabled
                type="button"
              >
                CLOSED
              </button>
            </ng-container>
          </div>
        </div>
        <div
          *ngSwitchCase="5"
          style="position: relative; height: calc(100vh - 74px)"
        >
          <div class="ml-8 mt-4">
            <h2 class="mb-1">Day 3 - Advanced Concepts</h2>
            <p style="color: rgba(0, 0, 0, 0.54)"></p>
          </div>
          <div class="ml-0 mt-4 flex">
            <div class="w-1/2">
              <ul class="color-primary pl-8 pt-3 line-height-15rem font-base">
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Control Loops (For, While, Range, Continue, Break and Pass)
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Introduction to Functions
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Default & User Defined Functions
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Local & Global Variables
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Lambda, Regular Expression,
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Nested Functions
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Web Scraping Basics
                </li>
                <li class="list-unstyled flex align-items mb-4">
                  <mat-icon
                    class="mat-icon material-icons-round material-icons mr-2"
                    >task_alt
                  </mat-icon>
                  Introduction to Machine Learning
                </li>
              </ul>
            </div>
            <div class="w-1/2">
              <ul class="mr-12 pr-12 float-right">
                <li class="flex mb-8 align-items-center">
                  <mat-icon class="mr-4" color="primary"
                    >calendar_today</mat-icon
                  >
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">
                      {{ days[2] | date : "mediumDate" }}
                    </p>
                    <p class="font-xs">Date</p>
                  </div>
                </li>
                <li class="flex mb-8 align-items-center">
                  <mat-icon class="mr-4" color="primary">schedule</mat-icon>
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">
                      {{
                        event.event_start_date +
                          "
                                            " +
                          event.event_start_time | date : "shortTime"
                      }}
                      -
                      {{
                        event.event_start_date + " " + event.event_end_time
                          | date : "shortTime"
                      }}
                      IST
                    </p>
                    <p class="font-xs">Time</p>
                  </div>
                </li>
                <li class="flex mb-4 align-items-center">
                  <mat-icon class="mr-4" color="primary">timer</mat-icon>
                  <div class="flex flex-column">
                    <p class="mb-1 font-semi-bold">{{ event.duration }}</p>
                    <p class="font-xs">Duration</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="mr-8 mt-6">
            <ng-container *ngIf="joinStatus[2]">
              <ng-container *ngIf="startEnableJoin">
                <button
                  mat-raised-button
                  color="primary"
                  class="letter-spacing-15 text-uppercase mt-2 absolute bottom-0 border-radius-none"
                  style="height: 3.6rem; width: calc(100% + 3em) !important"
                  (click)="join()"
                  type="button"
                >
                  JOIN SESSION NOW
                </button>
              </ng-container>
              <ng-container *ngIf="!startEnableJoin">
                <button
                  mat-raised-button
                  class="letter-spacing-15 text-uppercase mt-2 absolute bottom-0 border-radius-none"
                  style="
                    height: 3.6rem;
                    opacity: 1;
                    width: calc(100% + 3em) !important;
                    color: #000;
                  "
                  type="button"
                  disabled
                >
                  <span class="flex items=center justify-center"
                    >JOIN SESSION
                    <span
                      class="font-xs ml-4"
                      style="color: rgba(0, 0, 0, 0.6); text-transform: initial"
                      >(Will be enable 10 mins before Start time)</span
                    ></span
                  >
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!joinStatus[2]">
              <button
                mat-raised-button
                class="letter-spacing-15 text-uppercase mt-2 w-full absolute bottom-0 border-radius-none"
                style="height: 3.6rem; opacity: 1; color: #000"
                disabled
                type="button"
              >
                CLOSED
              </button>
            </ng-container>
          </div>
        </div>
        <div *ngSwitchCase="6" style="position: relative">
          <im-event-certificate [event]="event"></im-event-certificate>
        </div> -->
      </div>
    </div>
    <div
      class="column-start-9 row-span-1 column-end-12 right-side-nav ml-12 fixed h-screen overflow-hidden mr-2"
      style="right: 0; max-width: 26%; background-color: #fafafa"
    >
      <mat-vertical-stepper
        linear="false"
        [selectedIndex]="selectedIndex"
        #stepper
        (selectionChange)="tabChange($event)"
        disableRipple="false"
        style="background-color: #fafafa"
      >
        <!-- Icon overrides. -->
        <ng-template matStepperIcon="edit" let-index="index">
          <mat-icon>{{ maticons[index] }}</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="videos">
          <mat-icon>play_circle_outline</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="intro">
          <mat-icon>lightbulb</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="certification">
          <mat-icon>card_membership</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="assessment">
          <mat-icon>assessment</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="event_available">
          <mat-icon>event_available</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="event">
          <mat-icon>event</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="event_not_available">
          <mat-icon>event_busy</mat-icon>
        </ng-template>

        <ng-container
          *ngFor="let type of modules[typeOfEvent].types; let i = index"
        >
          <mat-step
            [state]="type.icon"
            [completed]="
              userTaks?.hackthon_submission &&
              userTaks?.hackthon_submission['Final Source']
            "
          >
            <ng-template matStepLabel>
              <p
                class="active-step"
                [ngClass]="{
                  'completed-step':
                    userTaks?.hackthon_submission &&
                    userTaks?.hackthon_submission['Final Source']
                }"
              >
                STEP {{ i + 1 }}: {{ type.title }}
              </p>
              <p
                class="mr-12 font-xs"
                style="color: rgba(0, 0, 0, 0.54); white-space: initial"
              >
                {{ type.short_description }}
              </p>
            </ng-template>
          </mat-step>
        </ng-container>

        <!-- <mat-step state="usersurvey" [completed]="surveySubmitted">
          <ng-template matStepLabel>
            <p
              class="active-step"
              [ngClass]="{ 'completed-step': surveySubmitted }"
            >
              STEP 2: Know more about you
            </p>
            <p
              class="mr-12 font-xs"
              style="color: rgba(0, 0, 0, 0.54); white-space: initial"
            >
              A small effort to personalize this program to fit your learning
              goals.
            </p>
          </ng-template>
        </mat-step> -->
        <!-- <mat-step
          state="videos"
          [completed]="event.user_event.properties?.event_program?.step1 == 1"
        >
          <ng-template matStepLabel>
            <p
              class="active-step"
              [ngClass]="{
                'completed-step':
                  event.user_event.properties?.event_program?.step1 == 1
              }"
            >
              STEP 2: Resources
            </p>
            <p
              class="mr-12 font-xs"
              style="color: rgba(0, 0, 0, 0.54); white-space: initial"
            >
              Start your journey
            </p>
          </ng-template>
        </mat-step>

        <mat-step
          state="assessment"
          [completed]="event.user_event.properties?.event_program?.step2 == 1"
        >
          <ng-template matStepLabel>
            <p
              class="active-step"
              [ngClass]="{
                'completed-step':
                  event.user_event.properties?.event_program?.step2 == 1
              }"
            >
              STEP 3: Submission
            </p>
            <p
              class="mr-12 font-xs"
              style="color: rgba(0, 0, 0, 0.54); white-space: initial"
            >
              Submitting your project correctly and on time is crucial
            </p>
          </ng-template>
        </mat-step> -->

        <!-- <mat-step state="event_available" [aria-labelledby]="(days[0].diff(currentDay) <= 0)?'':'disabled_Resi'"
                    [completed]="event.user_event.properties?.event_program?.day1 == 1">
                    <ng-template matStepLabel>
                        <p
                            [ngClass]="{'active-step':(days[0].diff(this.currentDay) <= 0),'completed-step':(event.user_event.properties?.event_program?.day1 == 1) }">
                            STEP 4: Day 1 - Introduction to Python </p>
                        <p class="mr-12 font-xs" style="color: rgba(0, 0, 0, 0.54);white-space: initial;">Fundamentals,
                            Keywords & Identifiers, Variables, Input & Output Operations, Data Types Etc.</p>
                    </ng-template>
                </mat-step>

                <mat-step state="event" [aria-labelledby]="(days[1].diff(currentDay) <= 0)?'':'disabled_Resi'"
                    [completed]="event.user_event.properties?.event_program?.day2 == 1">
                    <ng-template matStepLabel>
                        <p
                            [ngClass]="{'active-step':( days[1].diff(this.currentDay) <= 0), 'completed-step': (event.user_event.properties?.event_program?.day2 == 1)}">
                            STEP 5: Day 2 - Warming up for Python </p>
                        <p class="mr-12 font-xs" style="color: rgba(0, 0, 0, 0.54);white-space: initial;">Arithmetic,
                            Assignment, Comparison, Logical. Membership & Identify Operators and Conditional Statements.
                        </p>
                    </ng-template>
                </mat-step>

                <mat-step state="event" [aria-labelledby]="(days[2].diff(currentDay) <= 0)?'':'disabled_Resi'"
                    [completed]="event.user_event.properties?.event_program?.day3 == 1">
                    <ng-template matStepLabel>
                        <p
                            [ngClass]="{'active-step':(days[2].diff(this.currentDay) <= 0), 'completed-step': (event.user_event.properties?.event_program?.day3 == 1)}">
                            STEP 6: Day 3 - Advanced Concepts</p>
                        <p class="mr-12 font-xs" style="color: rgba(0, 0, 0, 0.54);white-space: initial;">Control loops,
                            Introduction to functions, Lambda, Regular expressions. Nested functions, Web scraping
                            basics, Introduction to Machine Learning</p>
                    </ng-template>
                </mat-step> -->

        <!-- <mat-step
          state="certification"
          [aria-labelledby]="
            event.user_event.properties?.event_program?.day3 == 1
              ? ''
              : 'disabled_Resi'
          "
        >
          <ng-template matStepLabel>
            <p
              [ngClass]="{
                'active-step':
                  event.user_event.properties?.event_program?.day3 == 1
              }"
            >
              STEP 7: Certification
            </p>
            <p
              class="mr-12 font-xs"
              style="color: rgba(0, 0, 0, 0.54); white-space: initial"
            >
              Please click link to download your certificate
            </p>
          </ng-template>
        </mat-step> -->
      </mat-vertical-stepper>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!processing && notEnrolled">
  <div class="flex items-center justify-center h-screen flex-column">
    <mat-icon style="font-size: 48px; height: 48px; width: 48px" color="warn"
      >error_outline</mat-icon
    >
    <h2>Enrollments are closed. Please try for the another event programs.</h2>
    <button
      mat-raised-button
      color="primary"
      class="mt-2"
      routerLink="/schedule"
    >
      Explore
    </button>
  </div>
</ng-container>
