import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpService } from '../../core/services/http.service';
import moment from 'moment';
import { NoteService } from './note.service';
import { environment } from '@environment/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  notes: any = [];

  activeNote: any;

  editor: any;

  activeId: any;

  moment = moment;

  url: any;

  constructor(protected sanitizer: DomSanitizer, private noteService: NoteService, public dialog: MatDialog, public http: HttpService, private route: ActivatedRoute, private router: Router) {
    this.noteService.activeNoteId.subscribe((res)=>{
      this.activeId = res;
    });
    this.noteService.updateNoteInfo.subscribe((res)=>{
    });
    this.noteService.refreshNotes.subscribe((res)=>{
        this.getList();
    });
  }

  ngOnInit() {
      this.getList();
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.playerUrl+'?autoplay=false&autopause=true&muted=false&loop=false&url=https%3A%2F%2Fvimeo.com%2F600844841&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true');
  }

  getList() {
    this.http.get('user_note?fields=id,name,modified_at&order_by=modified_at&order=desc').subscribe((res)=>{
      this.notes=res.data;
    });
  }

  goToNote(id) {
      this.activeId = id;
      this.router.navigate([id], {relativeTo: this.route});
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(NoteCreate, {
      width: '40vw',
      data: { name: ''}
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.new(result);
        }
    });
  }

  new(name) {
    this.http.post('user_note', {name: name}).subscribe((res)=>{
        this.activeNote = res.data;
        this.notes.unshift(this.activeNote);
        this.goToNote(this.activeNote.id);
    });
  }


}


@Component({
  selector: 'note-create',
  templateUrl: 'create.html',
})
export class NoteCreate {

  constructor(
    public dialogRef: MatDialogRef<NoteCreate>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
